import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import HamburgerMenuIconSvg from "../../assets/HamburgerMenuIconSvg";
import { APP_COLORS } from "../../constants/styles";
import { setOpenSearchModal } from "../../redux/authSlice";
import { useGetLink } from "../../utils/useOpenLink";
import { CountryLanguagePicker } from "../CountryLanguagePicker";
import MegaMenu from "../MegaMenu/MegaMenu";
import MegaMenuMobile from "../MegaMenu/MegaMenuMobile";
import "./Header2.css";
import CustomLink from "../../utils/customLink";
import SearchDialog from "../InstantSearch/SearchDialog";
import { LoginPanel } from "../LoginPanel";
import clsx from "clsx";
import useCustomTranslation from "../../utils/useCustomTranslation";

function HeaderComponent2() {
    const dispatch = useDispatch();

    const { getLink } = useGetLink();

    const { t } = useCustomTranslation();

    const {
        standardMenuHeader2: standard = {},
        subHeader: sub = {},
        sections = [],
    } = useSelector((state) => state.layout.header, shallowEqual) || {};
    const standardMenuHeader = standard.contents || [];

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const [isMenuHidden, setIsMenuHidden] = useState(false);

    const isMinimal = useSelector((state) => state.layout.isMinimal);

    useEffect(() => {
        const header = document.querySelector("header.header-main");

        if (header && header.classList.contains("header2")) {
            document.body.classList.add("header-2");
        } else {
            document.body.classList.remove("header-2");
        }
    }, []); // Run once on mount

    const handleMenuHideChange = (isVisible) => {
        setIsMenuHidden(!isVisible);
    };

    const renderTopLinks = () => {
        return (
            <div className="w-full flex flex-row justify-between gap-x-6 container text-xs items-center pt-3 md:pt-0 px-[0.75em]">
                <div className="block md:invisible flex-grow">
                    <Link to={"/"}>
                        <img
                            className=" cursor-pointer h-10 object-contain"
                            src={standard?.section_image?.src}
                        />
                    </Link>
                </div>

                {/* Show these links separately in desktop only. For mobile they appear with other nav links */}
                <div className="hidden md:flex md:flex-row md:gap-x-6">
                    {standardMenuHeader.map((value) =>
                        value.contents.map((v, i) => (
                            <CustomLink
                                to={getLink(v.content_reference)}
                                type={v.type}
                                key={v?.heading || `${value?.heading}-${i}` || "duplicate"}
                            >
                                <div className="cursor-pointer">{v.heading}</div>
                            </CustomLink>
                        )),
                    )}
                </div>

                <CountryLanguagePicker displayType={"header"} />

                <button
                    className="navbar-toggler block md:hidden"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#topMenu"
                    aria-controls="topMenu"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={() => setMobileMenuOpen(true)}
                >
                    <HamburgerMenuIconSvg color={standard.colors?.primary_color} />
                </button>
            </div>
        );
    };

    const renderHeader = () => {
        return (
            <div id="header" className="w-full z-20 relative bg-white pb-2 md:pb-6">
                {renderTopLinks()}

                {/* desktop */}
                <div className="container hidden md:block p-0">
                    <div className="flex flex-row justify-between container text-accent_color3 items-end p-0">
                        <Link to={"/"}>
                            <img
                                className="cursor-pointer h-16"
                                src={standard?.section_image?.src}
                            />
                        </Link>
                        <ul className="flex flex-row gap-x-6 justify-center items-center">
                            <Link to="https://tv.ismaili" target="_blank">
                                <li className="cursor-pointer font-bold text-lg">
                                    <i className="pi pi-caret-right relative px-2 top-[2px] text-lg"></i>
                                    {t("constantString.ismaili_tv")}
                                </li>
                            </Link>
                            <li
                                className="cursor-pointer"
                                onClick={() => dispatch(setOpenSearchModal(true))}
                            >
                                <i className="pi pi-search relative text-lg"></i>
                            </li>
                            <div className="searchMain userMain">
                                {<LoginPanel colorObj={standard.colors} />}
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        );
    };

    if (!(standardMenuHeader || []).length) return null;

    if (isMinimal) return null;

    return (
        <div
            id="header-wrapper"
            style={APP_COLORS(standard.colors)}
            className={clsx(
                "fixed top-0 left-0 right-0 z-10 text-start",
                isMenuHidden && "md:h-[127px] shadow-md",
            )}
        >
            {renderHeader()}

            {mobileMenuOpen && (
                <MegaMenuMobile
                    logo={standard?.section_image?.src}
                    style={APP_COLORS(sub.colors)}
                    standardMenuHeader={standardMenuHeader}
                    subMenuHeader={sub.contents}
                    setMobileMenuOpen={setMobileMenuOpen}
                    sections={sections}
                />
            )}
            <MegaMenu
                style={APP_COLORS(sub.colors)}
                subMenuHeader={sub.contents}
                sections={sections}
                onMenuHideChange={handleMenuHideChange}
            />

            <SearchDialog colors={standard?.colors} />
        </div>
    );
}

export default HeaderComponent2;
