import { useEffect, useRef, useState } from "react";
import { useClickOutside } from "../../utils/useClickOutside";
import useCustomTranslation from "../../utils/useCustomTranslation";

export const DropDownItem = ({
    label,
    selectedValue,
    listItems,
    onItemSelected,
    placeholder = "",
}) => {
    const dropdownRef = useRef(null);
    const inputRef = useRef(null);
    const [filteredItems, setFilteredItems] = useState(listItems);
    const [listVisible, setListVisible] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState(selectedValue || "");

    const { t } = useCustomTranslation();

    useClickOutside(dropdownRef, () => {
        setListVisible(false);
    });

    useEffect(() => {
        if (searchKeyword === selectedValue) {
            setFilteredItems(listItems);
        } else {
            const filteredList = listItems.filter((item) =>
                item.display.toLowerCase().includes(searchKeyword.toLowerCase()),
            );
            setFilteredItems(filteredList);
        }
    }, [searchKeyword, listItems]);

    const handleSelectItem = (item) => {
        onItemSelected(item.value);
        setSearchKeyword(item.display);
        setListVisible(false);
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchKeyword(value);

        if (value === "") {
            setSearchKeyword("");
        }
    };

    const handleClick = () => {
        setListVisible((prev) => !prev);
        if (inputRef.current) {
            inputRef.current.select();
        }
    };

    return (
        <div ref={dropdownRef} className="relative">
            <div className="text-accent_color3_default text-xs">{label}</div>
            <div className="flex flex-row border-solid border-b border-b-accent_color3_default py-2 mb-8 justify-between items-center">
                <div className="text-accent_color1_default text-base w-full" onClick={handleClick}>
                    <input
                        ref={inputRef}
                        type="text"
                        value={searchKeyword}
                        onChange={handleInputChange}
                        className="w-full border-none focus:outline-none"
                        placeholder={placeholder || t("placeHolder.search")}
                    />
                </div>
                <i
                    className="pi pi-angle-down text-primary_color_default cursor-pointer"
                    onClick={() => setListVisible((s) => !s)}
                ></i>
            </div>

            <div className="relative z-50">
                {listVisible && (
                    <div className="absolute top-0 left-0 right-0 bg-white shadow-lg -mt-8 max-h-60 overflow-y-auto text-base">
                        {filteredItems.map((item) => (
                            <div
                                key={item.display}
                                onClick={() => handleSelectItem(item)}
                                className="text-base text-accent_color1_default px-4 py-2 flex flex-row items-start cursor-pointer hover:bg-primary_color_default hover:text-white"
                            >
                                <div>{item.display}</div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};
