import React, { useEffect, useState } from "react";
import "./Accordion.css";
import { Accordion, AccordionTab } from "primereact/accordion";
import parse from "html-react-parser";

function AccordionWithTab({ contents: contentToRender = [], colors: colorObj }) {
    const [activeIndex, setActiveIndex] = useState(0);

    const [selectedTag, setSelectedTag] = useState([]);

    const [selectedBody, setSelectedBody] = useState();

    useEffect(() => {
        if (contentToRender && contentToRender.length > 0) {
            setSelectedTag(contentToRender[0].contents);
        }
    }, [contentToRender, activeIndex]);

    useEffect(() => {
        if (selectedTag && selectedTag.length > 0) {
            setSelectedBody(selectedTag[0].body);
        }
    }, [selectedTag]);

    return (
        <>
            <section className="accordionWrapper py-50">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Accordion multiple>
                                {contentToRender &&
                                    contentToRender.map((items, index) => (
                                        <AccordionTab
                                            header={items.heading}
                                            key={index}
                                            style={{
                                                "--primary_color": colorObj?.primary_color,
                                                "--accent_color4": colorObj?.accent_color4,
                                                "--accent_color2": colorObj?.accent_color2,
                                                "--accent_color1": colorObj?.accent_color1,
                                            }}
                                        >
                                            <div className="accordiontabWrapper mb-4">
                                                <ul
                                                    className="nav nav-pills accordiontabHldr"
                                                    id="pills-tab"
                                                >
                                                    {selectedTag &&
                                                        selectedTag.map((item, findex) => (
                                                            <li
                                                                className="nav-item cursor-pointer"
                                                                onClick={() => {
                                                                    setActiveIndex(findex);
                                                                    setSelectedBody(item.body);
                                                                }}
                                                                key={item.heading}
                                                            >
                                                                <button
                                                                    className={`nav-link ${
                                                                        activeIndex === findex
                                                                            ? "active"
                                                                            : ""
                                                                    }`}
                                                                    id="tab1"
                                                                    data-bs-toggle="pill"
                                                                    data-bs-target="#accordion1"
                                                                    type="button"
                                                                    role="tab"
                                                                    aria-controls="accordion1"
                                                                    aria-selected="true"
                                                                >
                                                                    {item.heading}
                                                                </button>
                                                            </li>
                                                        ))}
                                                </ul>
                                            </div>
                                            {parse(selectedBody || "")}
                                        </AccordionTab>
                                    ))}
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AccordionWithTab;
