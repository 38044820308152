import React from "react";
import parse from "html-react-parser";
import "./H5P.css";

function PlayH5p({ contents, heading, body }) {
    return (
        <>
            <div className="container px-10 py-10 H5Pwrapper">
                <div className="heading">{heading}</div>
                <div className="body">{parse(body)}</div>

                <iframe
                    src={contents[0]?.content_reference}
                    style={{
                        width: "100%",
                        height: "100vh",
                        border: "none",
                        overflow: "hidden",
                    }}
                />
            </div>
        </>
    );
}

export default PlayH5p;
