import { Outlet } from "react-router-dom";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { WindowContextProvider } from "./components/WindowResize/resize";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { checkToken } from "./redux/authSlice";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/grid";
import AuthModal from "./components/AuthModal";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import "react-photo-view/dist/react-photo-view.css";
import MetaTags from "./utils/metaTagsFunction";
import { HelmetProvider } from "react-helmet-async";
import { getAuthToken } from "./utils/oauthHandler";
import useGetConfigs from "./utils/useGetConfigs";
import HeaderComponent2 from "./components/Header/Header2";

const App = () => {
    const dispatch = useDispatch();

    const isLoaded = useGetConfigs();

    // check for a locally saved token and validate if it's active
    useEffect(() => {
        const token = getAuthToken();
        const details = localStorage.getItem("oauthServersDetails");
        const oauthDetails = JSON.parse(details);
        if (token !== null) {
            dispatch(checkToken(oauthDetails));
        }
    }, [dispatch]);

    if (!isLoaded) return null;

    return (
        <div className="flex flex-col h-full">
            <WindowContextProvider>
                <HelmetProvider>
                    <MetaTags />
                    <HeaderComponent2 />
                    <Outlet />
                </HelmetProvider>
                <AuthModal />
            </WindowContextProvider>
        </div>
    );
};

export default App;
