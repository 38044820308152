import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchArticle } from "../../redux/layoutSlice";
import { WindowContext } from "../../components/WindowResize/resize";

function Article4({ contents }) {
    const { articleId: articleIdFromParams } = useParams();
    const id = contents?.[0]?.id || articleIdFromParams;

    const dispatch = useDispatch();
    const content = useSelector((state) => state.layout.content);

    const [isLoading, setIsLoading] = useState(!!id);
    const [didDispatch, setDidDispatch] = useState(false);

    useEffect(() => {
        if (isLoading && !didDispatch) {
            dispatch(fetchArticle({ id }));
            setDidDispatch(true);
        }
        if (isLoading && content[id]) {
            setIsLoading(false);
        }
    }, [isLoading, content, didDispatch, id, dispatch]);

    const { body: _body } = content[id] || {};

    const { isMobile } = useContext(WindowContext);

    useEffect(() => {
        const iframe = document.getElementById("shorthand-iframe");

        if (iframe && _body) {
            const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

            iframeDocument.open();
            iframeDocument.write(`
                <!DOCTYPE html>
                <html lang="en">
                    <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <title>Shorthand Embed</title>
                        <style>
                            body {
                                margin: 0;
                                padding: 0;
                                overflow: hidden;
                            }
                            .scroll-container {
                                height: 100vh;
                                width: 100%;
                                position: relative;
                            }
                            .scroll-container::-webkit-scrollbar {
                                display: none;
                            }
                        </style>
                    </head>
                    <body>
                        <div class="scroll-container">${_body}</div>
                    </body>
                </html>
            `);
            iframeDocument.close();

            iframe.onload = () => {
                const iframeWindow = iframe.contentWindow;
                const scrollContainer =
                    iframe.contentWindow.document.querySelector(".scroll-container");
                const header = document.getElementById("header-wrapper");
                const content = document.getElementById("content");

                const resetStyles = () => {
                    if (header) {
                        header.style.opacity = "1";
                        header.style.visibility = "visible";
                        content.classList.remove("default-padding", "mobile-padding");
                    }
                };

                if (scrollContainer) {
                    const handleScroll = () => {
                        const scrollTop = iframeWindow.scrollY || iframeWindow.pageYOffset;
                        if (scrollTop > 0) {
                            header.style.opacity = "0";
                            header.style.visibility = "hidden";
                            content.classList.remove("default-padding", "mobile-padding");
                        } else {
                            header.style.opacity = "1";
                            header.style.visibility = "visible";
                            content.classList.add(isMobile ? "mobile-padding" : "default-padding");
                        }
                    };

                    iframeWindow.addEventListener("scroll", handleScroll);

                    // Scroll iframe when hovering on the header and scrolling the wheel
                    const handleWheel = (event) => {
                        event.preventDefault();
                        iframeWindow.scrollBy(0, event.deltaY);
                    };

                    header.addEventListener("wheel", handleWheel);

                    return () => {
                        iframeWindow.removeEventListener("scroll", handleScroll);
                        header.removeEventListener("wheel", handleWheel);
                        resetStyles(); // Reset styles when cleaning up
                    };
                }
            };
        }
    }, [_body, isMobile]);

    useEffect(() => {
        const header = document.getElementById("header-wrapper");
        const content = document.getElementById("content");
        if (header && content) {
            header.style.opacity = "1";
            header.style.visibility = "visible";
            header.style.transform = "translateY(0)";
            content.classList.remove("default-padding", "mobile-padding");
            content.classList.add(isMobile ? "mobile-padding" : "default-padding");
        }
    }, [isMobile]);

    return (
        <div style={{ width: "100%" }}>
            <iframe
                id="shorthand-iframe"
                style={{
                    width: "100%",
                    height: "100vh",
                    border: "none",
                    overflow: "hidden",
                }}
                title="Shorthand Article"
            />
        </div>
    );
}

export default Article4;
