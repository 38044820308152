/* eslint-disable no-unused-vars */
import { useSelector } from "react-redux";
import { encodeObject } from "./objEncoder";
import { logoutURL } from "../redux/authSlice";

const scopes = ["offline_access", "openid"];
const responseType = "code";

const ACCESS_TOKEN = "token";
const ID_TOKEN = "id_token";

export const getBaseUrl = () => {
    const url = window.location.href;
    const path = window.location.pathname;
    const urlParts = url.match(/(http[s]?:\/\/[^/]*)\/?/) || []; // match the base url with trailing slash removed, return in [1]

    return { base: `${urlParts[1]}/` || null, path };
};

const getAuthorizeHref = ({ authEndpoint, clientId, state: stateObj, codeChallenge }) => {
    const state = encodeObject(stateObj);
    const url = getBaseUrl().base;

    return `${authEndpoint}?client_id=${clientId}&scope=${scopes.join(
        "%20",
    )}&response_type=${responseType}&state=${state}&redirect_uri=${url}&code_challenge=${codeChallenge}&code_challenge_method=S256`;
};

const getLogoutHref = (logoutURL) => {
    const url = getBaseUrl().base.slice(0, -1);
    const idToken = getIdToken();

    return `${logoutURL}?id_token_hint=${idToken}&post_logout_redirect_uri=${url}`;
};

const storeVarFromLocalStorage = (name, value) => window.localStorage.setItem(name, value);
const getVarFromLocalStorage = (name) => window.localStorage.getItem(name);
const removeVarFromLocalStorage = (name) => window.localStorage.removeItem(name);

export const storeAuthToken = (token) => storeVarFromLocalStorage(ACCESS_TOKEN, token);
export const getAuthToken = () => getVarFromLocalStorage(ACCESS_TOKEN);
export const removeAuthToken = () => removeVarFromLocalStorage(ACCESS_TOKEN);

export const storeIdToken = (token) => storeVarFromLocalStorage(ID_TOKEN, token);
export const getIdToken = () => getVarFromLocalStorage(ID_TOKEN);
export const removeIdToken = () => removeVarFromLocalStorage(ID_TOKEN);

export const handleOauth = ({ authEndpoint, clientId, state, codeChallenge }) => {
    window.open(
        getAuthorizeHref({
            authEndpoint,
            clientId,
            state,
            codeChallenge,
        }),
        "_self",
    );
};

export const handleLogout = (logoutURL) => {
    window.open(getLogoutHref(logoutURL), "_self");
    removeAuthToken();
    removeIdToken();
};
