import React, { useEffect, useRef } from "react";
import "../Header/Header.css";
import { shallowEqual, useSelector } from "react-redux";
import {
    jurisdictionName,
    oauthData,
    openLoginDropdown,
    selectCountryLanguageCodes,
    selectIsAuthed,
    selectUserInfo,
} from "../../redux/authSlice";
import { handleLogout } from "../../utils/oauthHandler";
import useCustomTranslation from "../../utils/useCustomTranslation";
import { useClickOutside } from "../../utils/useClickOutside";

export const LoginPopup = ({
    list,
    onSelect,
    colorObj,
    toggleSelectLogin,
    setToggleSelectLogin,
}) => {
    const isAuthed = useSelector(selectIsAuthed);

    const { t } = useCustomTranslation();

    const userInfo = useSelector(selectUserInfo);

    const dropdownRef = useRef(null);

    const oauthDetails = useSelector(oauthData);

    const displayJurisdictionName = useSelector(jurisdictionName);

    const openLoginOption = useSelector(openLoginDropdown);

    const { countryCode: countryCodeFromState } = useSelector(
        selectCountryLanguageCodes,
        shallowEqual,
    );

    useClickOutside(dropdownRef, () => {
        setToggleSelectLogin(false);
    });

    useEffect(() => {
        if (openLoginOption) {
            setToggleSelectLogin(true);
        }
    }, [openLoginOption]);

    const getselectedCountryObj = (countryName) => {
        const index = list.findIndex((e) => e.country_code.toLowerCase() === countryName);
        const selectedCoutryItem = list[index];
        onSelect(selectedCoutryItem);
    };

    return (
        <>
            {toggleSelectLogin && (
                <div className="contPopup1" ref={dropdownRef}>
                    <div className="contMain1">
                        <ul>
                            {!isAuthed ? (
                                <li>
                                    <div
                                        onClick={() => getselectedCountryObj(countryCodeFromState)}
                                    >
                                        <label className="form-check-label cursor-pointer country pb-2 pt-2 flex justify-center items-center">
                                            <i
                                                className="pi pi-sign-in me-2"
                                                aria-hidden="true"
                                            ></i>
                                            {t("loginText")} {displayJurisdictionName}
                                            <i
                                                className="pi pi-angle-right ml-auto"
                                                aria-hidden="true"
                                            ></i>
                                        </label>
                                    </div>
                                </li>
                            ) : (
                                <>
                                    <li
                                        className="divider-line text-center"
                                        style={{ "--primary_color": colorObj?.primary_color }}
                                    >
                                        {userInfo.name}
                                    </li>
                                    <li>
                                        <div
                                            onClick={() =>
                                                handleLogout(oauthDetails?.logoutEndpoint)
                                            }
                                        >
                                            <label className="form-check-label cursor-pointer country pb-2 pt-2">
                                                <i
                                                    className="pi pi-sign-out me-2"
                                                    aria-hidden="true"
                                                ></i>
                                                {t("logoutText")}
                                            </label>
                                        </div>
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                </div>
            )}
        </>
    );
};
