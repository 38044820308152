import React, { useEffect, useState } from "react";
import { Galleria } from "primereact/galleria";
import { getPosterImage } from "../../utils/posterImageFunction";
import "./SpotlightCarousel.css";
import { getEmbeddedVideoUrl } from "../../utils/getEmbeddedVideoUrl";

function SpotlightCarousel({ contents: contentsArray }) {
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            let videosArr = [];
            if (contentsArray?.length > 0) {
                for await (const iterator of contentsArray) {
                    const image = await getPosterImage(iterator.content_reference);

                    let videoSrc = getEmbeddedVideoUrl(iterator.content_reference);

                    videosArr.push({
                        ...iterator,
                        itemVideoSrc: videoSrc,
                        thumbnailImageSrc: image,
                    });
                }
            }
            setVideos(videosArr);
        };
        fetchData();
    }, [contentsArray]);

    const itemTemplate = (item) => (
        <div className="video-container">
            <iframe
                src={item.itemVideoSrc}
                title={item.alt}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>
    );

    const thumbnailTemplate = (item) => (
        <div className="thumbnail-container">
            <img src={item.thumbnailImageSrc} alt="Thumbnail" />
        </div>
    );

    return (
        <div className="container py-30">
            <Galleria
                value={videos}
                numVisible={5}
                item={itemTemplate}
                showItemNavigators={videos.length > 1}
                thumbnail={videos.length > 1 ? thumbnailTemplate : null}
                circular
                autoPlay={false}
                showThumbnails={videos.length > 1}
            />
        </div>
    );
}

export default SpotlightCarousel;
