import React from "react";
import CustomLink from "../../utils/customLink";

function DesktopLinkGroup({ index, value, openLink }) {
    return (
        <div key={index}>
            <h6 className="text-primary_color text-[11px] uppercase mb-3 text-start">
                {value.heading}
            </h6>

            <div className="w-14 h-[1px] bg-primary_color mb-3" />

            <ul className="flex flex-col flex-wrap max-h-48 gap-x-8 text-start">
                {value.contents &&
                    value.contents.map((v) => (
                        <CustomLink
                            to={openLink(v.content_reference)}
                            type={v.type}
                            key={v.heading}
                        >
                            <li className="cursor-pointer text-primary_color text-[12px] py-2">
                                {v.heading}
                            </li>
                        </CustomLink>
                    ))}
            </ul>
        </div>
    );
}

export default DesktopLinkGroup;
