import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const useGetPageParam = () => {
    const { search } = useLocation();

    const [pageParam, setPageParam] = useState({
        page: 0,
        page_size: 12,
    });

    useEffect(() => {
        const queryParams = new URLSearchParams(search);

        const newParamsReq = {
            page: 0,
            page_size: 12,
        };

        queryParams.forEach((value, key) => {
            if (key === "page") {
                newParamsReq.page = +value === 0 ? 1 : +value - 1;
            } else {
                newParamsReq[key] = value;
            }
        });
        setPageParam(newParamsReq);
    }, [search]);

    return [pageParam, setPageParam];
};
