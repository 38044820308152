import React from "react";
import "./Footer.css";
import { useGetLink } from "../../utils/useOpenLink";
import SocialLinks from "../SocialLinks";
import useCustomTranslation from "../../utils/useCustomTranslation";
import CustomLink from "../../utils/customLink";

function FooterComponent1({ contents: contentsToRender = [] }) {
    const { getLink } = useGetLink();

    const { t } = useCustomTranslation();

    return (
        <>
            <div className="footer1">
                <div className="logoAppWrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="logoAppHldr">
                                    <div className="logo">
                                        <img src="assets/images/footer_logo.svg" />
                                    </div>
                                    <div className="CommunityHldr">{t("footerText.aboutTxt")}</div>
                                    <div className="txtAppIcon">
                                        <div className="txtMian">
                                            {t("footerText.experience")}
                                            <br />
                                            <b>{t("footerText.ismailiApp")}</b>
                                            <br />
                                            {t("footerText.anyDevice")}
                                        </div>
                                        <div className="iconMain">
                                            <a href="#">
                                                <img src="assets/images/appStore-icon.svg" />
                                            </a>
                                            <a href="#">
                                                <img
                                                    className="mb-0"
                                                    src="assets/images/googlePlay-icon.svg"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <SocialLinks />
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-12 linkMain">
                            <div className="row">
                                {contentsToRender.map((value) => (
                                    <div
                                        className="col-lg-2 col-md-4 col-sm-6 col-12"
                                        key={value.heading}
                                    >
                                        <CustomLink to={getLink(value.contentKey)}>
                                            <h6>
                                                <span>{value.heading}</span>
                                            </h6>
                                        </CustomLink>
                                        <ul>
                                            {value.contents &&
                                                value.contents.map((v) => (
                                                    <li key={v.heading}>
                                                        <CustomLink to={getLink(value.contentKey)}>
                                                            <span className="cursor-pointer">
                                                                {v.heading}
                                                            </span>
                                                        </CustomLink>
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="copyRight">
                    <div className="mb-2">{t("footerText.copyRight")}</div>
                    <div className="terms">
                        <a className="mr-4" href="#">
                            {t("footerText.privacyPolicy")}
                        </a>{" "}
                        <a href="#">{t("footerText.TermsAndCondition")}</a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FooterComponent1;
