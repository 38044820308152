import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCountriesLanguageArray, selectIsAuthed, selectIsLogin } from "../../redux/authSlice";
import { getBaseUrl, handleOauth } from "../../utils/oauthHandler";
import { generatePkceCodePair } from "../../utils/oauthHelpers";
import { fetchMessages } from "../../redux/messagesSlice";
import { useNavigate } from "react-router-dom";
import { LoginPopup } from "../LoginPopup";

export const LoginPanel = ({ colorObj }) => {
    const dispatch = useDispatch();

    const countriesLanguageData = useSelector(selectCountriesLanguageArray);

    const navigate = useNavigate();

    const [toggleSelectLogin, setToggleSelectLogin] = useState(false);

    const isAuthed = useSelector(selectIsAuthed);

    const unreadMsgCount = useSelector((state) => state.messages.unreadMsgCount);
    const isLoginState = useSelector(selectIsLogin);

    useEffect(() => {
        if (isAuthed) {
            dispatch(fetchMessages());
        }
    }, [isAuthed, dispatch]);

    const handleCountryDropDownSelect = async (item) => {
        const selectedCountry = countriesLanguageData.find((c) => c.country === item.country);
        if (selectedCountry?.oauthServers?.authEndpoint) {
            const codeChallenge = await generatePkceCodePair();
            localStorage.setItem(
                "oauthServersDetails",
                JSON.stringify(selectedCountry?.oauthServers),
            );
            localStorage.setItem("message_api", JSON.stringify(selectedCountry?.message_api));
            handleOauth({
                authEndpoint: selectedCountry?.oauthServers?.authEndpoint,
                clientId: selectedCountry?.oauthServers?.clientId,
                state: { link: getBaseUrl().path },
                codeChallenge,
            });
        }
    };

    return (
        <>
            {isLoginState && (
                <>
                    <div className="profileMain flex">
                        {isAuthed && (
                            <div className="login-main me-4">
                                <div className="unreadmessage-count-main">{unreadMsgCount}</div>
                                <i
                                    className="pi pi-bell cursor-pointer"
                                    onClick={() => navigate("/messages")}
                                ></i>
                            </div>
                        )}
                        <div className="login-main">
                            <i
                                className="pi pi-user cursor-pointer"
                                onClick={() => setToggleSelectLogin((s) => !s)}
                            ></i>
                        </div>
                    </div>
                </>
            )}
            <LoginPopup
                list={countriesLanguageData}
                onSelect={handleCountryDropDownSelect}
                colorObj={colorObj}
                toggleSelectLogin={toggleSelectLogin}
                setToggleSelectLogin={setToggleSelectLogin}
            />
        </>
    );
};
