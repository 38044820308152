import React from "react";
import ImageWithObjectFit from "../ImageWrapperComponent";
import { useGetLink } from "../../utils/useOpenLink";
import { Link } from "react-router-dom";
import { useGetDateToDisplay } from "../../utils/dateFunctions";
import useGetArticleMeta from "../../utils/useGetArticleMeta";
import PlayIconSvg from "../../assets/PlayIconSvg";
import { ZERO_WIDTH_CHAR } from "../../constants/displayConstants";
import { shallowEqual, useSelector } from "react-redux";
import { selectCountryLanguageCodes } from "../../redux/authSlice";

export const TrendingNowCard = ({ content, colorsObj }) => {
    const { image, date, title, path, posterImage, breadcrumb, link, type, language } = content;

    const { getLink } = useGetLink();

    const getDateToDisplay = useGetDateToDisplay();

    const { getTheme, getSection } = useGetArticleMeta();
    const theme = getTheme(breadcrumb);
    const section = getSection(breadcrumb);
    const { languageCode } = useSelector(selectCountryLanguageCodes, shallowEqual);

    return (
        <>
            <div className="card h-full">
                <div className="cardImg">
                    {!!link && (
                        <div
                            className="playIconHldr"
                            style={{
                                "--accent_color4": colorsObj.accent_color4,
                            }}
                        >
                            <PlayIconSvg />
                        </div>
                    )}
                    <Link to={getLink(path, type, language)}>
                        <ImageWithObjectFit
                            className="cursor-pointer"
                            src={image?.src || posterImage}
                        />
                    </Link>
                </div>
                <div className="card-body flex flex-col text-start">
                    <div
                        className="tag-heading min-h-[20px]"
                        style={{ color: colorsObj.primary_color }}
                    >
                        {theme || ZERO_WIDTH_CHAR}
                    </div>
                    <Link to={getLink(path, type, language)}>
                        <h3
                            className="hover:underline mb-6 cursor-pointer"
                            style={{ color: colorsObj.accent_color1 }}
                        >
                            {title}
                        </h3>
                    </Link>
                    <div className="authorDateMain mt-auto">
                        <span
                            className="author ltr:mr-1 rtl:ml-1 capitalize"
                            style={{ color: colorsObj.accent_color1 }}
                        >
                            {section}
                        </span>
                        <span className="date" style={{ color: colorsObj.accent_color3 }}>
                            {section && date ? " • " : ""}
                            {getDateToDisplay(date, languageCode)}
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};
