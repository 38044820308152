export const verifyPhoneNumber = (phoneNumber, countryData, required) => {
    if (!phoneNumber || phoneNumber.trim() === "") {
        return !required; //  If required and empty, return false
    }

    // Remove spaces and non-numeric characters except "+"
    const cleanedPhoneNumber = phoneNumber.replace(/\s+/g, "");

    // Extract only digits (ignoring + sign)
    const phoneDigits = cleanedPhoneNumber.replace(/\D/g, "");

    // Remove country code from validation length check
    const nationalNumber = phoneDigits.startsWith(countryData.dialCode)
        ? phoneDigits.slice(countryData.dialCode.length)
        : phoneDigits;

    // Validate length (8 to 15 digits)
    const phoneRegex = /^\d{8,15}$/;

    return phoneRegex.test(nationalNumber); // Returns true if valid, false otherwise
};
