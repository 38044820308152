import React from "react";
import "./PageNotFound2.css";
import { useGetLink } from "../../utils/useOpenLink";
import { useDispatch } from "react-redux";
import { setOpenSearchModal } from "../../redux/authSlice";
import useCustomTranslation from "../../utils/useCustomTranslation";
import parse from "html-react-parser";
import CustomLink from "../../utils/customLink";
import { Helmet } from "react-helmet-async";

function PageNotFound2({
    section_image,
    heading,
    body,
    block_buttons,
    type,
    newPath,
    colors: colorObj,
}) {
    const { getLink } = useGetLink();
    const baseUrl = window.location.origin;

    const { t } = useCustomTranslation();

    const dispatch = useDispatch();

    return (
        <>
            {/* Below is used to do a redirect that is acceptable by Googlebot */}
            {type ? (
                <Helmet>
                    <meta
                        httpEquiv="refresh"
                        content={`${type === 301 ? 2 : 0}; url=${baseUrl}/${getLink(newPath)}`}
                    />
                </Helmet>
            ) : null}

            <section className="pagenot-wrapper">
                <div className="container">
                    <div className="row flex justify-center">
                        <div className="col-lg-8 col-md-8 col-sm-10 col-10">
                            <div className="pagenot-hldr">
                                <div
                                    className="numberTxt"
                                    style={{
                                        "--accent_color3": colorObj?.accent_color3,
                                    }}
                                >
                                    <img src={section_image?.src} />
                                </div>
                                <div className="notFound-txt">
                                    <h2 style={{ color: colorObj?.accent_color1 }}>{heading}</h2>
                                    <p style={{ color: colorObj?.accent_color1 }}>
                                        {body && parse(body)}
                                    </p>
                                    {!type ? (
                                        <div className="searchMain">
                                            <p style={{ color: colorObj?.accent_color1 }}>
                                                {t("placeHolder.trySearch")}
                                            </p>
                                            <div className="searchHldr">
                                                <i
                                                    className="fa fa-search"
                                                    style={{ color: colorObj?.primary_color }}
                                                ></i>
                                                <input
                                                    placeholder={t("constantString.enterKeyWord")}
                                                    onClick={() =>
                                                        dispatch(setOpenSearchModal(true))
                                                    }
                                                />
                                            </div>
                                            {block_buttons.map((v) => (
                                                <CustomLink to={getLink(v.action)} key={v.label}>
                                                    <span
                                                        className="btn"
                                                        style={{
                                                            color: colorObj?.accent_color4,
                                                            background: colorObj?.primary_color,
                                                        }}
                                                    >
                                                        {v.label}
                                                    </span>
                                                </CustomLink>
                                            ))}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PageNotFound2;
