import React from "react";
import "./ArticleHeading.css";
import parse from "html-react-parser";

function ArticleHeading({ heading, body, colors: colorObj }) {
    return (
        <>
            <div className="articleHeading py-50">
                <div className="container">
                    <div className="row">
                        {heading !== "" && (
                            <div className="col-12">
                                <h3 className="heading" style={{ color: colorObj?.primary_color }}>
                                    {heading}
                                </h3>
                            </div>
                        )}

                        {body !== "" && (
                            <div className="col-12 mt-4">
                                <p
                                    className="description"
                                    style={{ color: colorObj?.accent_color1 }}
                                >
                                    {body && parse(body)}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ArticleHeading;
