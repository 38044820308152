export const getEmbeddedVideoUrl = (url) => {
    try {
        const urlObj = new URL(url);

        if (url.includes("youtube.com") || url.includes("youtu.be")) {
            // Extract YouTube Video ID
            const videoId = urlObj.searchParams.get("v") || url.split("youtu.be/")[1];
            if (videoId) {
                return `https://www.youtube.com/embed/${videoId}`;
            }
        } else if (url.includes("vimeo.com")) {
            // Extract Vimeo Video ID
            const videoId = url.split("/").pop();
            if (videoId) {
                return `https://player.vimeo.com/video/${videoId}`;
            }
        }
    } catch (error) {
        console.error("Error processing video URL:", error);
    }
    return url; // Return original URL if processing fails
};
