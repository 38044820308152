import React, { useContext, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { popularity } from "../../redux/instantSearchSlice";
import { useHits, useStats } from "react-instantsearch";
import { WindowContext } from "../WindowResize/resize";
import useCustomTranslation from "../../utils/useCustomTranslation";
import { useGetLink } from "../../utils/useOpenLink";
import { Link } from "react-router-dom";
import { useGetDateToDisplay } from "../../utils/dateFunctions";
import { selectCountryLanguageCodes } from "../../redux/authSlice";

function InstantSearchArticleDetails({ props, onShowMore, color, gridView, onShowLess }) {
    const { items: hits } = useHits(props);
    const { nbHits } = useStats(props);
    const { getLink } = useGetLink();

    const getDateToDisplay = useGetDateToDisplay();

    const dispatch = useDispatch();
    const { t } = useCustomTranslation();
    const { isMobile } = useContext(WindowContext);

    const [isExpanded, setIsExpanded] = useState(false);

    const { languageCode } = useSelector(selectCountryLanguageCodes, shallowEqual);

    const handleClick = (doc_id) => {
        if (doc_id) {
            dispatch(popularity(doc_id));
        }
    };

    const handleShowMore = () => {
        setIsExpanded(true);
        onShowMore(); // Call the passed onShowMore function
    };

    const handleShowLess = () => {
        setIsExpanded(false);
        onShowLess(); // Call the passed onShowLess function
    };

    const translateHits = (h) => {
        const translatedData = (h.language || []).find((l) => l.lang_code === languageCode);
        const translatedTitle = translatedData?.title || h.title;
        const translatedSummary = translatedData?.summary || h.summary;

        return { ...h, translatedTitle, translatedSummary };
    };

    return (
        <>
            <div
                className={`${
                    gridView && !isMobile ? "article-list article-grid" : "article-list"
                }`}
            >
                {hits.map(translateHits).map((hit) => (
                    <div className="article-card" key={hit.id || hit.title}>
                        <div className="article-img">
                            <Link to={getLink(hit.path, hit.type, hit.language)}>
                                <img src={hit.image?.src} onClick={() => handleClick(hit.doc_id)} />
                            </Link>
                        </div>
                        <div className="article-body">
                            <div className="title-main">
                                <h5>{hit.microsite?.split(",")[0]}</h5>
                                <Link to={getLink(hit.path, hit.type, hit.language)}>
                                    <h2
                                        className="hover:underline cursor-pointer"
                                        onClick={() => handleClick(hit.doc_id)}
                                    >
                                        {hit.translatedTitle}
                                    </h2>
                                </Link>
                            </div>
                            <div className="flex mt-auto">
                                {hit.tags && (
                                    <div
                                        className="article-tags mr-1"
                                        style={{ color: color.accent_color1 }}
                                    >
                                        {hit.tags?.split(",")[0]}
                                    </div>
                                )}
                                <div className="date" style={{ color: color?.accent_color3 }}>
                                    {hit.tags && hit.date ? " | " : ""}
                                    {getDateToDisplay(hit.date)}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {nbHits > 0 && nbHits > 12 && (
                <>
                    {!isExpanded ? (
                        <div
                            className={`${gridView ? "gridshowMore" : "showMore"}`}
                            onClick={handleShowMore}
                        >
                            <span className="cursor-pointer">
                                {t("btnText.ShowMore")}
                                <i className="pi pi-arrow-down ml-2"></i>
                            </span>
                        </div>
                    ) : (
                        <div
                            className={`${gridView ? "gridshowLess" : "showLess"}`}
                            onClick={handleShowLess}
                        >
                            <span className="cursor-pointer">
                                {t("btnText.ShowLess")}
                                <i className="pi pi-arrow-up ml-2"></i>
                            </span>
                        </div>
                    )}
                </>
            )}
        </>
    );
}

export default InstantSearchArticleDetails;
