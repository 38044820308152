/* eslint-disable no-unused-vars */
import moment from "moment";
import React from "react";
import parse from "html-react-parser";
import ImageWithObjectFit from "../ImageWrapperComponent";
import { useGetLink } from "../../utils/useOpenLink";
import { decodeHtmlEntities } from "../../utils/urlUtils";
import useCustomTranslation from "../../utils/useCustomTranslation";
import CustomLink from "../../utils/customLink";
import PlayIconSvg from "../../assets/PlayIconSvg";

function TitleCard({ content, colors }) {
    const { getLink } = useGetLink();

    const {
        image,
        microsite,
        title,
        author,
        date,
        summary,
        path,
        posterImage,
        type,
        link,
        language,
    } = content;

    const { t } = useCustomTranslation();

    return (
        <>
            <div className="boxMain card" style={{ background: colors?.background_color }}>
                <CustomLink to={getLink(path, type, language)} key={type}>
                    <div
                        className="TitleMain hover:underline"
                        style={{ color: colors?.accent_color1 }}
                    >
                        {title}
                    </div>
                </CustomLink>
                <div className="row tileCardTxt">
                    <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                        <CustomLink to={getLink(path, type, language)} key={type}>
                            <div className="ImgMain">
                                {!!link && (
                                    <div
                                        className="playIconHldr"
                                        style={{
                                            "--accent_color4": colors.accent_color4,
                                        }}
                                    >
                                        <PlayIconSvg />
                                    </div>
                                )}
                                <ImageWithObjectFit
                                    className="cursor-pointer"
                                    src={image?.src || posterImage}
                                />
                            </div>
                        </CustomLink>
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-12 col-12">
                        <div className="BodyTxt">
                            <div className="date" style={{ color: colors?.primary_color }}>
                                {microsite !== "" ? decodeHtmlEntities(microsite) : ""}
                                {microsite && author ? " | " : ""}
                                {author}
                                {author && date ? " | " : ""}
                                {date ? moment(date).format(`MMM DD${","} YYYY`) : ""}
                            </div>
                            <p style={{ color: colors?.accent_color1 }}>
                                {summary ? parse(summary) : ""}
                            </p>
                        </div>
                    </div>
                </div>
                <CustomLink to={getLink(path, type, language)} key={type}>
                    <div className="ViewResource cursor-pointer">
                        <div className="col-12">
                            <span className="btn btn-link" style={{ color: colors?.primary_color }}>
                                {t("btnText.ViewResource")}
                            </span>
                            <i className="float-end">
                                <i
                                    className="pi pi-arrow-right viewIconArrowIcon"
                                    style={{
                                        "--primary_color": colors?.primary_color,
                                        "--accent_color4": colors?.accent_color4,
                                    }}
                                ></i>
                            </i>
                        </div>
                    </div>
                </CustomLink>
            </div>
        </>
    );
}

export default TitleCard;
