import React from "react";

const ShareboxTwitter = () => {
    return (
        <svg
            width="24"
            height="29"
            viewBox="0 0 23 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.6 7.25H19.054L13.694 13.392L20 21.75H15.063L11.196 16.68L6.771 21.75H4.316L10.049 15.18L4 7.25H9.063L12.558 11.883L16.6 7.25ZM15.74 20.278H17.1L8.323 8.645H6.865L15.74 20.278Z"
                fill="#828282"
            />
        </svg>
    );
};

export default ShareboxTwitter;
