import React from "react";

const ShareboxInstagram = () => {
    return (
        <svg
            width="24"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M25.3093 10.0347C25.2971 9.11956 25.1258 8.2135 24.803 7.35706C24.5231 6.63466 24.0955 5.97859 23.5477 5.43077C22.9999 4.88296 22.3438 4.45543 21.6214 4.17552C20.776 3.85816 19.8829 3.68656 18.98 3.66802C17.8176 3.61606 17.4491 3.60156 14.4983 3.60156C11.5476 3.60156 11.1694 3.60156 10.0154 3.66802C9.11297 3.68669 8.22024 3.85829 7.37519 4.17552C6.65268 4.45524 5.99651 4.88269 5.44866 5.43054C4.90082 5.97838 4.47336 6.63455 4.19365 7.35706C3.87565 8.20185 3.70442 9.09478 3.68736 9.99727C3.6354 11.1609 3.61969 11.5294 3.61969 14.4802C3.61969 17.4309 3.61969 17.8079 3.68736 18.9631C3.70548 19.8669 3.87586 20.7587 4.19365 21.6057C4.47383 22.328 4.90161 22.9839 5.44963 23.5315C5.99766 24.0791 6.65389 24.5064 7.3764 24.7861C8.21913 25.1162 9.11201 25.3001 10.0166 25.3298C11.1802 25.3818 11.5488 25.3975 14.4995 25.3975C17.4503 25.3975 17.8285 25.3975 18.9824 25.3298C19.8853 25.312 20.7785 25.1408 21.6239 24.8235C22.3461 24.5433 23.002 24.1156 23.5497 23.5679C24.0975 23.0201 24.5252 22.3642 24.8054 21.642C25.1232 20.7961 25.2936 19.9044 25.3117 18.9994C25.3636 17.8369 25.3794 17.4684 25.3794 14.5164C25.3769 11.5657 25.3769 11.1911 25.3093 10.0347ZM14.4911 20.0603C11.405 20.0603 8.90494 17.5602 8.90494 14.4741C8.90494 11.3881 11.405 8.88802 14.4911 8.88802C15.9726 8.88802 17.3934 9.47656 18.4411 10.5242C19.4887 11.5718 20.0772 12.9926 20.0772 14.4741C20.0772 15.9557 19.4887 17.3765 18.4411 18.4241C17.3934 19.4717 15.9726 20.0603 14.4911 20.0603ZM20.2995 9.98398C19.5781 9.98398 18.9969 9.40156 18.9969 8.6814C18.9969 8.51042 19.0306 8.34111 19.096 8.18315C19.1615 8.02519 19.2574 7.88166 19.3783 7.76076C19.4992 7.63986 19.6427 7.54395 19.8007 7.47852C19.9586 7.41309 20.1279 7.37942 20.2989 7.37942C20.4699 7.37942 20.6392 7.41309 20.7972 7.47852C20.9551 7.54395 21.0987 7.63986 21.2196 7.76076C21.3405 7.88166 21.4364 8.02519 21.5018 8.18315C21.5672 8.34111 21.6009 8.51042 21.6009 8.6814C21.6009 9.40156 21.0185 9.98398 20.2995 9.98398Z"
                fill="#828282"
            />
            <path
                d="M14.4934 18.1049C16.4974 18.1049 18.122 16.4803 18.122 14.4763C18.122 12.4722 16.4974 10.8477 14.4934 10.8477C12.4893 10.8477 10.8647 12.4722 10.8647 14.4763C10.8647 16.4803 12.4893 18.1049 14.4934 18.1049Z"
                fill="#828282"
            />
        </svg>
    );
};

export default ShareboxInstagram;
