import { default as React, useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    selectCountriesLanguageArray,
    selectCountryLanguageCodes,
    setCountryInfo,
    setLanguageInfo,
    setRegionInfo,
} from "../../redux/authSlice";
import clsx from "clsx";
import {
    DEFAULT_COUNTRY_CODE,
    getValidCountryLanguageCode,
} from "../../utils/countryLanguageHelpers";
import { useClickOutside } from "../../utils/useClickOutside";
import { useReloadWithCountryLanguage } from "../../utils/useOpenLink";
import Modal from "../Modal";
import { DropDownItem } from "./DropDownItem";
import { FooterDisplay } from "./FooterDisplay";
import { HeaderDisplay } from "./HeaderDisplay";
import { useCountryLanguageLinkExtractor } from "../../utils/useCountryLanguageLinkExtractor";

export const CountryLanguagePicker = ({ displayType }) => {
    const countriesLanguageData = useSelector(
        selectCountriesLanguageArray,
        (prev, next) => prev.length === next.length,
    );

    // const isAuthed = useSelector(selectIsAuthed);
    // const { idToken } = useSelector(selectUserInfo) || {};

    const [availableLanguages, setAvailableLanguages] = useState([]);
    const [availableRegions, setAvailableRegions] = useState([]);

    const dispatch = useDispatch();

    const { reloadPage } = useReloadWithCountryLanguage();
    const link = useCountryLanguageLinkExtractor();

    const {
        countryCode: countryCodeFromState,
        languageCode: languageCodeFromState,
        regionId: regionIdFromState,
    } = useSelector(selectCountryLanguageCodes, shallowEqual);

    const [selectedCountry, setSelectedCountry] = useState(null);

    const [selectedLanguage, setSelectedLanguage] = useState(null);

    const [selectedRegion, setSelectedRegion] = useState(null);

    useEffect(() => {
        const selCountry =
            countriesLanguageData.find(
                (d) => d.country_code?.toLowerCase() === countryCodeFromState,
            ) || null;
        const selLanguage =
            selCountry?.language.find((l) => l.code === languageCodeFromState) || null;
        const selRegion = selCountry?.regions.find((r) => r.id === regionIdFromState) || null;

        setSelectedCountry(selCountry);
        setSelectedLanguage(selLanguage);
        setAvailableLanguages(selCountry?.language || []);
        setSelectedRegion(selRegion);
        setAvailableRegions(selCountry?.regions || []);
    }, [countryCodeFromState, languageCodeFromState, regionIdFromState]);

    const countryData = countriesLanguageData.find(
        (d) => d.country_code?.toLowerCase() === countryCodeFromState,
    );
    const countryName = countryData?.country || DEFAULT_COUNTRY_CODE;

    const languageName =
        (countryData?.language || []).find((l) => l.code === languageCodeFromState)?.label || "";

    const regionName = selectedRegion?.name || "";

    const dropdownRef = useRef(null);

    const [showModal, setShowModal] = useState(false);

    useClickOutside(dropdownRef, () => {
        setShowModal(false);
    });

    const handleSelectCountry = (value) => {
        setSelectedCountry(value);
        if (value.language) {
            if (JSON.stringify(value.language) !== JSON.stringify(availableLanguages)) {
                setAvailableLanguages(value.language);
                const { languageCode: defaultLanguageCode } = getValidCountryLanguageCode(
                    countriesLanguageData,
                    value.country_code,
                );
                const langIndex =
                    (value?.language || []).findIndex((l) => l.code === defaultLanguageCode) || 0;

                setSelectedLanguage(value.language[langIndex]);
            }
        } else {
            if (availableLanguages.length !== 0) {
                setAvailableLanguages([]);
                setSelectedLanguage(null);
            }
        }

        // check region data
        if (value.regions.length) {
            if (JSON.stringify(value.region) !== JSON.stringify(availableRegions)) {
                setAvailableRegions(value.regions);
                setSelectedRegion(null);
            }
        } else {
            if (availableRegions.length !== 0) {
                setAvailableRegions([]);
                setSelectedRegion(null);
            }
        }
    };

    const getDisplay = () => {
        const displayProps = {
            countryCode: countryCodeFromState,
            languageCode: languageCodeFromState,
            countryName,
            languageName,
            regionName,
            setShowModal,
        };
        switch (displayType) {
            case "footer":
                return <FooterDisplay {...displayProps} />;
            default:
                return <HeaderDisplay {...displayProps} />;
        }
    };

    return (
        <div
            className="relative flex flex-row items-center justify-center gap-x-4"
            ref={dropdownRef}
        >
            {getDisplay()}
            {showModal ? (
                <Modal
                    key={selectedCountry?.country_code}
                    setShowModal={setShowModal}
                    className={clsx(
                        "md:right-0 md:mt-8",
                        displayType == "footer" ? "md:bottom-20" : "top-0",
                    )}
                >
                    <div className="hidden text-end -mr-2">
                        <i
                            className="md:hidden pi pi-times cursor-pointer text-3xl self-end"
                            onClick={() => setShowModal(false)}
                        ></i>
                    </div>
                    <DropDownItem
                        key={selectedCountry?.country_code}
                        label={"Select country"}
                        withSearch
                        selectedValue={selectedCountry?.country}
                        listItems={countriesLanguageData.map((i) => ({
                            value: i,
                            display: i.country,
                        }))}
                        onItemSelected={handleSelectCountry}
                    />
                    {availableLanguages.length > 1 ? (
                        <DropDownItem
                            key={selectedCountry?.country_code}
                            label={"Select language"}
                            selectedValue={selectedLanguage?.label}
                            listItems={availableLanguages.map((i) => ({
                                value: i,
                                display: i.label,
                            }))}
                            onItemSelected={(value) => {
                                setSelectedLanguage(value);
                            }}
                        />
                    ) : null}
                    {availableRegions.length > 1 ? (
                        <DropDownItem
                            key={selectedCountry?.country_code}
                            label={"Select region"}
                            placeholder={"Select..."}
                            selectedValue={selectedRegion?.name}
                            listItems={availableRegions.map((r) => ({
                                value: r,
                                display: r.name,
                            }))}
                            onItemSelected={(value) => {
                                setSelectedRegion(value);
                            }}
                        />
                    ) : null}
                    <div
                        className="cursor-pointer border text-accent_color1_default border-accent_color3_default text-center p-2 rounded-2xl hover:bg-primary_color_default hover:text-white"
                        onClick={async () => {
                            const selctedCountryCode = selectedCountry.country_code.toLowerCase();
                            const selectedLanguageCode = selectedLanguage.code.toLowerCase();
                            const selectedRegionId = selectedRegion?.id || null;
                            if (
                                selectedLanguageCode !== languageCodeFromState &&
                                selctedCountryCode === countryCodeFromState
                            ) {
                                // only language was updated, so it's ok to reload the current page
                                dispatch(
                                    setLanguageInfo({
                                        code: selectedLanguageCode,
                                        direction: selectedLanguage.direction,
                                    }),
                                );
                                reloadPage({
                                    countryCode: selctedCountryCode,
                                    languageCode: selectedLanguageCode,
                                    id: link,
                                });
                            } else if (
                                selctedCountryCode !== countryCodeFromState ||
                                selectedRegionId !== regionIdFromState
                            ) {
                                // country or region was updated, so we need to reload to the root / page
                                dispatch(setCountryInfo({ code: selctedCountryCode }));
                                dispatch(
                                    setLanguageInfo({
                                        code: selectedLanguageCode,
                                        direction: selectedLanguage.direction,
                                    }),
                                );
                                dispatch(
                                    setRegionInfo({
                                        id: selectedRegionId,
                                    }),
                                );
                                reloadPage({
                                    countryCode: selctedCountryCode,
                                    languageCode: selectedLanguageCode,
                                });
                            }

                            setShowModal(false);
                        }}
                    >
                        Confirm
                    </div>
                </Modal>
            ) : null}
        </div>
    );
};
