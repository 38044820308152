import React, { useCallback, useEffect, useState } from "react";
import "./ParagraphHighlight.css";
import { useSelector } from "react-redux";
import { parseWithRemoveBrTagsFromBody } from "../../utils/articleHelpers";
import { ARTICLE_DETAILS, BLOCK_DETAILS } from "../../constants/strings";

function ParagraphHighlight({
    contents: contentToRender = [],
    heading,
    body,
    colors: colorObj,
    block_source,
}) {
    const [programmeHighlightDetails, setProgrammeHighlightDetails] = useState({
        heading: "",
        body: "",
    });

    const [contentReferenceData, setContentReferenceData] = useState([]);

    const contentFromStore = useSelector((state) => state.layout.content);

    useEffect(() => {
        if (block_source === BLOCK_DETAILS) {
            setProgrammeHighlightDetails({
                ...programmeHighlightDetails,
                body: body,
                heading: heading,
            });
        }

        if (block_source === ARTICLE_DETAILS) {
            setProgrammeHighlightDetails({
                ...programmeHighlightDetails,
                body: contentReferenceData[0]?.summary,
                heading: contentReferenceData[0]?.title,
                image: contentReferenceData[0]?.image,
            });
        }
    }, [contentReferenceData]);

    const setCurrentContent = useCallback(
        async (contentKey) => {
            if (contentFromStore[contentKey]) {
                const respKeys = contentFromStore[contentKey];
                const resp = respKeys.map((k) => contentFromStore[k]);

                if (resp.length) {
                    setContentReferenceData(resp);
                }
            }
        },
        [contentFromStore],
    );

    useEffect(() => {
        if (contentToRender.length > 0) {
            setCurrentContent(contentToRender[0].contentKey);
        }
    }, []);

    return (
        <>
            <div
                className="paragraphHighlight py-100"
                style={{
                    background: colorObj?.background_color,
                    "--primary_color": colorObj?.primary_color,
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="heading">
                                <div
                                    className="line"
                                    style={{ background: colorObj?.primary_color }}
                                ></div>
                                {programmeHighlightDetails?.heading !== "" && (
                                    <h3 style={{ color: colorObj?.accent_color1 }}>
                                        {programmeHighlightDetails?.heading}
                                    </h3>
                                )}

                                {programmeHighlightDetails?.body !== "" && (
                                    <div
                                        className="body"
                                        style={{ color: colorObj?.accent_color1 }}
                                    >
                                        {parseWithRemoveBrTagsFromBody(
                                            programmeHighlightDetails.body || "",
                                            colorObj?.primary_color,
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ParagraphHighlight;
