export const defaultDirectoryId = "A352";

export const defaultToken = "123456";

export const API_MAIL_LIST_TYPE = {
    GET_MAIL_LIST: "get_mail_list",
    UPDATE_CONSENT_DATA: "update_consent_data",
    UPDATE_MAIL_STATUS: "update_mail_status",
    UPDATE_MAIL_SUBSCRIPTION: "update_mail_subscription",
};
