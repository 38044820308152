import React from "react";
import { Link } from "react-router-dom";
import "./BreadCrumbs.css";
import HomeSvg from "../../assets/HomeSvg";
import { APP_COLORS } from "../../constants/styles";

function BreadCrumbs({ data, colorObj }) {
    return (
        <div style={APP_COLORS(colorObj)}>
            <ol className="breadcrumb">
                <li className="breadcrumb-item cursor-pointer margin-top">
                    <Link to="/">{<HomeSvg />}</Link>
                </li>
                {(data || []).map((d) => (
                    <>
                        <div className="icon-fa-fa">
                            <i className="pi pi-angle-right"></i>
                        </div>
                        <li className="breadcrumb-item cursor-pointer capitalize">
                            <Link to={d.link}>{d.label}</Link>
                        </li>
                    </>
                ))}
            </ol>
        </div>
    );
}

export default BreadCrumbs;
