import React from "react";
import "./MediaFooter.css";
import { useGetLink } from "../../utils/useOpenLink";
import useCustomTranslation from "../../utils/useCustomTranslation";
import { Link } from "react-router-dom";
import CustomLink from "../../utils/customLink";

function MediaFooter({ contents: contentsToRender = [] }) {
    const { getLink } = useGetLink();

    const { t } = useCustomTranslation();

    return (
        <>
            <div className="mediaFooter">
                <div className="container">
                    <div className="row flex items-center">
                        <div className="col-md-6 col-12">
                            <div className="col-12 flex justify-center">
                                <div className="mediaLogoAppHldr">
                                    <Link to={"/"}>
                                        <div className="logo">
                                            <img
                                                className="cursor-pointer"
                                                src={
                                                    location.origin +
                                                    "/assets/images/media-footer-logo.svg"
                                                }
                                            />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-12">
                            <div className="mediaEmail my-4">
                                <i className="fa fa-envelope-o mr-2" aria-hidden="true"></i>
                                {t("mediaFooterString.media_email")}
                                <a className="ml-2" href={`mailto:${t("mediaFooterString.email")}`}>
                                    {t("mediaFooterString.email")}
                                </a>
                            </div>

                            <div className="row">
                                {contentsToRender.map((value) => (
                                    <div
                                        className="col-lg-4 col-md-4 col-sm-12 col-12 linkMain"
                                        key={value.heading}
                                    >
                                        <CustomLink
                                            to={getLink(value.contentKey)}
                                            type={value.type}
                                        >
                                            <h6>{value.heading}</h6>
                                        </CustomLink>
                                        {value.contents && (
                                            <>
                                                <ul>
                                                    {value.contents.map((v) => (
                                                        <CustomLink
                                                            to={getLink(v.content_reference)}
                                                            type={v.type}
                                                            key={v.heading}
                                                        >
                                                            <li>
                                                                <span>{v.heading}</span>
                                                            </li>
                                                        </CustomLink>
                                                    ))}
                                                </ul>
                                            </>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="copyright">
                                &#169; {new Date().getFullYear()}{" "}
                                {t("mediaFooterString.copy_right")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MediaFooter;
