import React, { useContext, useEffect, useState } from "react";
import "./MailingList.css";
import { Button } from "../Button";
import Eyesvg from "../../assets/Eyesvg";
import { useDispatch, useSelector } from "react-redux";
import { Paginator } from "primereact/paginator";
import { InputSwitch } from "primereact/inputswitch";
import {
    fetchMailiListData,
    updateConsentData,
    updateMailStatus,
    updateSubscriptionsData,
} from "../../redux/mailingListSlice";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import Loader from "../Loader/loader";
import parse from "html-react-parser";
import useBodyScrollLock from "../../utils/bodyScrollLock";
import useToast from "../../utils/useToast";
import { EMAIL_PATTERN } from "../../utils/pattern";
import { Checkbox } from "primereact/checkbox";
import useCustomTranslation from "../../utils/useCustomTranslation";
import { InputText } from "primereact/inputtext";
import { WindowContext } from "../WindowResize/resize";

function MailingList({ colors: colorObj }) {
    const dispatch = useDispatch();

    const [didDispatch, setDidDispatch] = useState(false);

    const { toastRef, showToast } = useToast();

    const [openMailingDialog, setOpenMailingDialog] = useState(false);

    const contents = useSelector((state) => state.mailing.userData);

    const consentData = useSelector((state) => state.mailing.consentData);

    const [selectedCategory, setSelectedCategory] = useState("General");

    const isLoading = useSelector((state) => state.mailing.isLoading);

    const mailiData = useSelector((state) => state.mailing.mailiData);

    const description = useSelector((state) => state.mailing.description);

    const { isMobile } = useContext(WindowContext);

    const [userData, setUserData] = useState({});

    const [consentDetails, setConsentDetails] = useState({});

    const [actualMailingDetails, setActualMailingDetails] = useState([]);

    const [bindMailingDetails, setBindMailingDetails] = useState([]);

    const [categoryArr, setCategoryArr] = useState([]);

    const [totalPageCount, setTotalPageCount] = useState(0);

    const [latestEditionLink, setLatestEditionLink] = useState("");

    const [emailError, setEmailError] = useState({ empty: "", invalid: "" });

    const { t } = useCustomTranslation();

    const [filterReq, setFilterReq] = useState({
        pageSize: 5,
        pageNumber: 0,
    });

    useBodyScrollLock(openMailingDialog);

    useEffect(() => {
        if (isLoading && !didDispatch) {
            dispatch(fetchMailiListData());
            setDidDispatch(true);
        }
    }, [isLoading, didDispatch, mailiData]);

    useEffect(() => {
        const mailingListData = mailiData;
        const currentSelectedCategory = selectedCategory || "General";
        updateCategoryArr(mailingListData, currentSelectedCategory);
    }, [mailiData, selectedCategory]);

    useEffect(() => {
        const userDetails = contents;
        setUserData(userDetails);

        const consentDetails = consentData;
        setConsentDetails(consentDetails);
    }, [contents, consentData]);

    useEffect(() => {
        const mailingListData = mailiData;
        let categoryArr = [];

        const filter = mailingListData.filter((e) => e.category);

        for (const iterator of filter) {
            categoryArr.push({
                category: iterator.category,
                isSelected: categoryArr.length === 0,
            });
        }
        setCategoryArr(categoryArr);
    }, [mailiData]);

    useEffect(() => {
        setActualMailingDetails([]);
        setTotalPageCount(0);

        const mailingListData = mailiData;
        let mailingListArr = [];
        let count = 0;

        const filter = mailingListData.filter((e) => e.category === selectedCategory);

        for (const iterator of filter) {
            mailingListArr = mailingListArr.concat(iterator.mail_list);
            count += iterator.mail_list.length;
        }

        setTotalPageCount(count);
        setActualMailingDetails(mailingListArr);
    }, [mailiData, selectedCategory]);

    useEffect(() => {
        if (actualMailingDetails.length > 0) {
            pagination();
        }
    }, [actualMailingDetails]);

    useEffect(() => {
        pagination();
    }, [filterReq]);

    const selectCategory = (i, selectedCategory) => {
        const updatedArr = categoryArr.map((item, index) => ({
            ...item,
            isSelected: i === index,
        }));

        setCategoryArr(updatedArr);
        setSelectedCategory(selectedCategory);
    };

    const pagination = () => {
        const pageSize = filterReq.pageSize;
        const page = filterReq.pageNumber + 1;
        const data = actualMailingDetails.slice(pageSize * (page - 1), pageSize * page);
        setBindMailingDetails(data);
    };

    const onPageChange = (event) => {
        setFilterReq({ ...filterReq, pageSize: event.rows, pageNumber: event.page });
    };

    const handleChecked = (checked, field) => {
        setConsentDetails((prevDetails) => ({
            ...prevDetails,
            [field]: checked ? true : false,
        }));
    };

    const handleSwitch = (checked, mail_list) => {
        const reqObj = {
            id: userData.id,
            list_id: mail_list.id,
            status: checked ? 1 : 0,
        };

        dispatch(updateSubscriptionsData(reqObj))
            .then((result) => {
                if (result.payload.success) {
                    showToast("success", t("constantString.success"), result.payload.message);
                    dispatch(fetchMailiListData()).then(() => {
                        updateCategoryArr(mailiData, selectedCategory);
                    });
                }
            })
            .catch(() => {
                showToast("error", t("constantString.error"), t("validationString.errorMessage"));
            });
    };

    const clickUpdate = () => {
        const reqObj = {
            consent_nc_marketing: consentDetails.consent_nc_marketing ? 0 : 1,
            consent_affiliate_share: consentDetails.consent_affiliate_share ? 0 : 1,
        };
        dispatch(updateConsentData(reqObj))
            .then((result) => {
                if (result.payload.success) {
                    showToast("success", t("constantString.success"), result.payload.message);
                    dispatch(fetchMailiListData());
                }
            })
            .catch(() => {
                showToast("error", t("constantString.error"), t("validationString.errorMessage"));
            });
    };

    const handleOnChange = (value) => {
        setUserData((prevDetails) => ({
            ...prevDetails,
            email: value,
        }));

        if (!value) {
            setEmailError({ empty: t("validationString.email"), invalid: "" });
        } else if (!validateEmail(value)) {
            setEmailError({ empty: "", invalid: t("validationString.validEmail") });
        } else {
            setEmailError({ empty: "", invalid: "" });
        }
    };

    const updateMail = () => {
        if (!userData.email) {
            setEmailError({ empty: t("validationString.email"), invalid: "" });
            return;
        }

        if (!validateEmail(userData.email)) {
            setEmailError({ empty: "", invalid: t("validationString.validEmail") });
            return;
        }

        setEmailError({ empty: "", invalid: "" });

        const reqObj = {
            email: userData.email,
        };
        dispatch(updateMailStatus(reqObj))
            .then((result) => {
                if (result.payload.success) {
                    showToast("success", t("constantString.success"), result.payload.message);
                    dispatch(fetchMailiListData());
                }
            })
            .catch(() => {
                showToast("error", t("constantString.error"), t("validationString.errorMessage"));
            });
    };

    const updateCategoryArr = (mailingListData, selectedCategory) => {
        const newCategoryArr = mailingListData
            .filter((e) => e.category)
            .map((item) => ({
                category: item.category,
                isSelected: item.category === selectedCategory,
            }));

        setCategoryArr(newCategoryArr);
    };

    const validateEmail = (email) => {
        return EMAIL_PATTERN.test(email);
    };

    return (
        <>
            <Loader isLoading={isLoading} />
            <Toast ref={toastRef} />
            <div className="mailingSection" style={{ background: colorObj?.background_color }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="heading">
                                <h3 className="mb-2" style={{ color: colorObj?.accent_color1 }}>
                                    {t("constantString.heading")}
                                </h3>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="boxWrapper">
                                                    <div className="card-text">
                                                        <h3
                                                            style={{
                                                                color: colorObj?.accent_color1,
                                                            }}
                                                        >
                                                            {t("constantString.subHeading")}
                                                        </h3>
                                                        <div className="flex">
                                                            <div className="mt-1">
                                                                <span
                                                                    style={{
                                                                        color: colorObj?.accent_color1,
                                                                    }}
                                                                >
                                                                    {t("constantString.status")}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <Button
                                                                    className="btn"
                                                                    style={{
                                                                        color: colorObj?.primary_color,
                                                                        background:
                                                                            colorObj?.accent_color4,
                                                                    }}
                                                                >
                                                                    {userData &&
                                                                        userData.user_status}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        <div className="mt-4">
                                                            <hr
                                                                style={{
                                                                    borderTop: `1px solid ${colorObj?.accent_color1}`,
                                                                }}
                                                            />
                                                        </div>
                                                        <label
                                                            className="block text-gray-700 text-lg my-2"
                                                            style={{
                                                                color: colorObj?.accent_color3,
                                                            }}
                                                        >
                                                            {t("placeHolder.emaili")}
                                                        </label>
                                                        <InputText
                                                            type="email"
                                                            id="exampleInputEmail1"
                                                            className="w-full"
                                                            aria-describedby="emailHelp"
                                                            onChange={(e) =>
                                                                handleOnChange(e.target.value)
                                                            }
                                                            style={{
                                                                "--accent_color3":
                                                                    colorObj?.accent_color3,
                                                                "--accent_color1":
                                                                    colorObj?.accent_color1,
                                                            }}
                                                            value={userData && userData.email}
                                                        />
                                                        {emailError.empty && (
                                                            <small className="text-red-500">
                                                                {emailError.empty}
                                                            </small>
                                                        )}
                                                        {emailError.invalid && (
                                                            <small className="text-red-500">
                                                                {emailError.invalid}
                                                            </small>
                                                        )}
                                                        <div className="mt-4 mb-4">
                                                            <Button
                                                                className="button"
                                                                onClick={() => updateMail()}
                                                                style={{
                                                                    color: colorObj?.accent_color4,
                                                                    background:
                                                                        colorObj?.primary_color,
                                                                }}
                                                            >
                                                                {t("btnText.btnUpdate")}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="boxWrapper">
                                                    <div className="card-text">
                                                        <h3
                                                            style={{
                                                                color: colorObj?.accent_color1,
                                                            }}
                                                        >
                                                            {t("constantString.consentToContact")}
                                                        </h3>
                                                        <div className="flex statusHldr">
                                                            <div className="mr-4">
                                                                <span>
                                                                    {t(
                                                                        "constantString.currentStatus",
                                                                    )}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span
                                                                    className="sub-heading"
                                                                    style={{
                                                                        color: colorObj?.primary_color,
                                                                    }}
                                                                >
                                                                    {consentDetails &&
                                                                        consentDetails.consent_status}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="mt-4">
                                                            <hr />
                                                        </div>
                                                        <div
                                                            className="bodyText"
                                                            style={{
                                                                "--accent_color1":
                                                                    colorObj?.accent_color1,
                                                            }}
                                                        >
                                                            <p>{t("constantString.text1")}</p>
                                                            <div className="flex">
                                                                <div className="check">
                                                                    <Checkbox
                                                                        onChange={(e) =>
                                                                            handleChecked(
                                                                                e.target.checked,
                                                                                "consent_nc_marketing",
                                                                            )
                                                                        }
                                                                        checked={
                                                                            consentDetails &&
                                                                            consentDetails.consent_nc_marketing
                                                                        }
                                                                        style={{
                                                                            "--primary_color":
                                                                                colorObj?.primary_color,
                                                                            "--accent_color3":
                                                                                colorObj?.accent_color3,
                                                                            "--accent_color4":
                                                                                colorObj?.accent_color4,
                                                                        }}
                                                                    />
                                                                </div>
                                                                <p className="padding">
                                                                    {parse(
                                                                        t(
                                                                            "constantString.checkBox",
                                                                        ),
                                                                    )}
                                                                </p>
                                                            </div>
                                                            <p>{t("constantString.text2")}</p>
                                                            <div className="flex">
                                                                <div className="check">
                                                                    <Checkbox
                                                                        onChange={(e) =>
                                                                            handleChecked(
                                                                                e.target.checked,
                                                                                "consent_affiliate_share",
                                                                            )
                                                                        }
                                                                        checked={
                                                                            consentDetails &&
                                                                            consentDetails.consent_affiliate_share
                                                                        }
                                                                        style={{
                                                                            "--primary_color":
                                                                                colorObj?.primary_color,
                                                                            "--accent_color3":
                                                                                colorObj?.accent_color3,
                                                                            "--accent_color4":
                                                                                colorObj?.accent_color4,
                                                                        }}
                                                                    />
                                                                </div>
                                                                <p className="padding">
                                                                    {parse(
                                                                        t(
                                                                            "constantString.checkBox",
                                                                        ),
                                                                    )}
                                                                </p>
                                                            </div>
                                                            <div className="mt-2 mb-4">
                                                                <Button
                                                                    className="button"
                                                                    onClick={() => clickUpdate()}
                                                                    style={{
                                                                        color: colorObj?.accent_color4,
                                                                        background:
                                                                            colorObj?.primary_color,
                                                                    }}
                                                                >
                                                                    {t("btnText.btnUpdate")}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {bindMailingDetails.length > 0 && (
                                    <div className="card mt-12">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="mailing-card-padding">
                                                    <div
                                                        className="nav nav-tabs flex"
                                                        id="nav-tab"
                                                        role="tablist"
                                                    >
                                                        {categoryArr.map((item, index) => (
                                                            <>
                                                                <button
                                                                    className={`nav-link ${
                                                                        item.isSelected
                                                                            ? "active"
                                                                            : ""
                                                                    }`}
                                                                    id="desktop-tab"
                                                                    data-bs-toggle={"tab1"}
                                                                    style={{
                                                                        "--primary_color":
                                                                            colorObj?.primary_color,
                                                                    }}
                                                                    data-bs-target="#desktop"
                                                                    type="button"
                                                                    role="tab"
                                                                    aria-controls="desktop"
                                                                    aria-selected={`${
                                                                        item.isSelected
                                                                            ? true
                                                                            : false
                                                                    }`}
                                                                    onClick={() =>
                                                                        selectCategory(
                                                                            index,
                                                                            item.category,
                                                                        )
                                                                    }
                                                                >
                                                                    {item.category}
                                                                </button>
                                                            </>
                                                        ))}
                                                    </div>
                                                    {description && (
                                                        <div className="body">{description}</div>
                                                    )}

                                                    <div className="table-auto" id={"tab1"}>
                                                        <table className="commonTable packageTable">
                                                            <tbody>
                                                                {bindMailingDetails.map((v) => (
                                                                    <tr key={v.name}>
                                                                        <td
                                                                            style={{
                                                                                width: "60px",
                                                                            }}
                                                                        >
                                                                            <InputSwitch
                                                                                checked={
                                                                                    v.subscribed
                                                                                }
                                                                                onChange={(e) =>
                                                                                    handleSwitch(
                                                                                        e.target
                                                                                            .value,
                                                                                        v,
                                                                                    )
                                                                                }
                                                                                style={{
                                                                                    "--primary_color":
                                                                                        colorObj?.primary_color,
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td
                                                                            style={{
                                                                                width: "100%",
                                                                            }}
                                                                        >
                                                                            <span className="tableText">
                                                                                {v.name}
                                                                            </span>
                                                                        </td>

                                                                        <td
                                                                            style={{
                                                                                width: "280px",
                                                                            }}
                                                                            className="cursor-pointer"
                                                                        >
                                                                            {v.latestEdition !==
                                                                                "" && (
                                                                                <div
                                                                                    onClick={() => {
                                                                                        setLatestEditionLink(
                                                                                            v.latestEdition,
                                                                                        );
                                                                                        setOpenMailingDialog(
                                                                                            true,
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <Eyesvg />
                                                                                </div>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    {bindMailingDetails.length > 0 && (
                                                        <div
                                                            className="table-pagination"
                                                            style={{
                                                                "--primary_color":
                                                                    colorObj?.primary_color,
                                                                "--accent_color4":
                                                                    colorObj?.accent_color4,
                                                            }}
                                                        >
                                                            <Paginator
                                                                first={
                                                                    filterReq.pageNumber *
                                                                    filterReq.pageSize
                                                                }
                                                                rows={filterReq.pageSize}
                                                                totalRecords={totalPageCount}
                                                                onPageChange={onPageChange}
                                                                pageLinkSize={isMobile ? 1 : 5}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog
                visible={openMailingDialog}
                onHide={() => {
                    if (!openMailingDialog) return;
                    setOpenMailingDialog(false);
                }}
                className="lg:w-1/2 md:w-3/4 sm:w-11/12"
                draggable={false}
                showHeader={false}
            >
                <div
                    className="modal-header cursor-pointer mt-4 mb-4"
                    onClick={() => setOpenMailingDialog(false)}
                >
                    <div className="closeIconMain ms-auto mr-3"></div>
                </div>
                <div className="loginModal">
                    <div className="modal-body">
                        <iframe src={latestEditionLink} className="iframe"></iframe>
                    </div>
                </div>
            </Dialog>
        </>
    );
}

export default MailingList;
