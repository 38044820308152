import { TabPanel, TabView } from "primereact/tabview";
import React, { useEffect, useState } from "react";
import "./FeatureTabs.css";
import { PlaceHolder } from "../../assets";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import { useGetLink } from "../../utils/useOpenLink";
import useCustomTranslation from "../../utils/useCustomTranslation";
import CustomLink from "../../utils/customLink";
import { ARTICLE_DETAILS } from "../../constants/strings";

function FeatureTabs({ contents: contentsArray = [], block_source, colors: colorObj }) {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [iconListDetails, setIconListDetails] = useState([]);
    const content = useSelector((state) => state.layout.content);
    const { t } = useCustomTranslation();
    const { getLink } = useGetLink();

    useEffect(() => {
        if (block_source === ARTICLE_DETAILS) {
            if (contentsArray.length > 0) {
                const iterator = contentsArray[selectedIndex];
                if (iterator && content[iterator.contentKey]) {
                    const respKeys = content[iterator.contentKey];
                    if (respKeys && Array.isArray(respKeys)) {
                        const resp = respKeys.map((k) => content[k]).slice(0, 1);
                        setIconListDetails(resp);
                    }
                }
            }
        }
    }, [content, contentsArray, selectedIndex]);

    return (
        <div className="FeturesTabComponent py-50">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 ">
                        <TabView
                            activeIndex={selectedIndex}
                            onTabChange={(e) => setSelectedIndex(e.index)}
                            style={{
                                "--accent_color3": colorObj?.accent_color3,
                                "--primary_color": colorObj?.primary_color,
                            }}
                        >
                            {block_source === ARTICLE_DETAILS
                                ? contentsArray.map((tab, index) => (
                                      <TabPanel key={tab.heading} header={tab.heading}>
                                          <div className="col-12" id={tab.heading}>
                                              {selectedIndex === index &&
                                                  iconListDetails.map((v) => (
                                                      <div
                                                          className="Componenthldr fade-in"
                                                          key={v.title}
                                                      >
                                                          <div className="container h-full flex items-center">
                                                              <div className="row">
                                                                  <div className="col-12">
                                                                      <div
                                                                          className="carousel-caption"
                                                                          style={{
                                                                              "--accent_color3":
                                                                                  colorObj?.accent_color3,
                                                                          }}
                                                                      >
                                                                          <h3
                                                                              style={{
                                                                                  color: colorObj?.accent_color4,
                                                                              }}
                                                                          >
                                                                              {v.title}
                                                                          </h3>
                                                                          <p
                                                                              style={{
                                                                                  color: colorObj?.accent_color4,
                                                                              }}
                                                                          >
                                                                              {v.summary &&
                                                                                  parse(v.summary)}
                                                                          </p>
                                                                          <div className="discoverMain cursor-pointer">
                                                                              <CustomLink
                                                                                  to={getLink(
                                                                                      v?.path,
                                                                                      v?.type,
                                                                                      v?.language,
                                                                                  )}
                                                                                  type={v?.type}
                                                                              >
                                                                                  <button
                                                                                      type="button"
                                                                                      className="discover-experiences-Btn"
                                                                                      style={{
                                                                                          background:
                                                                                              colorObj?.primary_color,
                                                                                          color: colorObj?.accent_color4,
                                                                                      }}
                                                                                  >
                                                                                      {t(
                                                                                          "btnText.learnMore",
                                                                                      )}
                                                                                  </button>
                                                                              </CustomLink>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                          <img
                                                              src={
                                                                  v.image?.src
                                                                      ? v.image?.src
                                                                      : PlaceHolder
                                                              }
                                                          />
                                                      </div>
                                                  ))}
                                          </div>
                                      </TabPanel>
                                  ))
                                : contentsArray.map((tab) => (
                                      <TabPanel key={tab.heading} header={tab.heading}>
                                          <div className="col-12" id={tab.heading}>
                                              {tab.contents.map((v) => (
                                                  <div
                                                      className="Componenthldr fade-in container-fluid"
                                                      key={v.title}
                                                  >
                                                      <div className="container h-full flex items-center">
                                                          <div className="row">
                                                              <div className="col-12">
                                                                  <div
                                                                      className="carousel-caption"
                                                                      style={{
                                                                          "--accent_color3":
                                                                              colorObj?.accent_color3,
                                                                      }}
                                                                  >
                                                                      <h3
                                                                          style={{
                                                                              color: colorObj?.accent_color4,
                                                                          }}
                                                                      >
                                                                          {v.heading}
                                                                      </h3>
                                                                      <p
                                                                          style={{
                                                                              color: colorObj?.accent_color4,
                                                                          }}
                                                                      >
                                                                          {v.body && parse(v.body)}
                                                                      </p>
                                                                      {tab?.content_buttons.length >
                                                                          0 &&
                                                                          tab?.content_buttons.map(
                                                                              (v) => (
                                                                                  <CustomLink
                                                                                      to={getLink(
                                                                                          v.action,
                                                                                      )}
                                                                                      key={v.label}
                                                                                  >
                                                                                      <div
                                                                                          className="discoverMain cursor-pointer"
                                                                                          key={
                                                                                              v.label
                                                                                          }
                                                                                      >
                                                                                          <button
                                                                                              type="button"
                                                                                              className="discover-experiences-Btn"
                                                                                              style={{
                                                                                                  background:
                                                                                                      colorObj?.primary_color,
                                                                                                  color: colorObj?.accent_color4,
                                                                                              }}
                                                                                          >
                                                                                              {
                                                                                                  v.label
                                                                                              }
                                                                                          </button>
                                                                                      </div>
                                                                                  </CustomLink>
                                                                              ),
                                                                          )}
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>

                                                      <img
                                                          src={
                                                              tab.content_image?.src
                                                                  ? tab.content_image?.src
                                                                  : PlaceHolder
                                                          }
                                                      />
                                                  </div>
                                              ))}
                                          </div>
                                      </TabPanel>
                                  ))}
                        </TabView>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FeatureTabs;
