import React from "react";
import "./EventHero.css";
import parse from "html-react-parser";

function EventHero({ heading, body, section_image, colors: colorObj }) {
    return (
        <>
            <section
                className="eventHeroMainWrapper"
                style={{ backgroundImage: `url(${section_image?.src})` }}
            >
                <div className="container relative z-[3]">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                            <h2 style={{ color: colorObj?.accent_color4 }}>{heading}</h2>
                            {body !== "" && (
                                <p style={{ color: colorObj?.accent_color4 }}>{parse(body)}</p>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default EventHero;
