import React, { useContext, useEffect, useState } from "react";
import "./Header.css";
import { WindowContext } from "../WindowResize/resize";
import scroll from "./scroll";
import { useGetLink } from "../../utils/useOpenLink";
import { LoginPanel } from "../LoginPanel";
import { Link, useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { FACET_PARAMS, QUERY_PARAMS } from "../../utils/instantSearchConfig";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { Configure, Hits, InstantSearch } from "react-instantsearch";
import InstantSearchModalArticleDetails from "../InstantSearch/InstantSearchModalArticleDetails";
import CustomSearchBoxModal from "../InstantSearch/InstantCustomSearchBoxModal";
import parse from "html-react-parser";
import MegaMenuList from "./MegaMenuList";
import DropDownMenuList from "./DropDownMenuList";
import LabelOnlyMenu from "./LabelOnlyMenu";
import MegaMenuResponsive from "./MegaMenuResponsive";
import DropDownMenuListResponsive from "./DropDownMenuListResponsive";
import LabelOnlyMenuResponsive from "./LabelOnlyMenuResponsive";
import { openSearchModal, setOpenSearchModal } from "../../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import useCustomTranslation from "../../utils/useCustomTranslation";
import CustomLink from "../../utils/customLink";

function HeaderComponent({ standardMenuHeader: standard = {}, subHeader: sub = {} }) {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [arr, setArr] = useState([]);

    const { isMobile, isDesktop } = useContext(WindowContext);

    const isSearchModalOpen = useSelector(openSearchModal);

    const [selectedArticle, setSelectedArticle] = useState(null);

    const [searchKeyword1, setSearchKeyword1] = useState("");

    const [visible, setVisible] = useState(true);

    const scrollTop = scroll();

    const standaradMenuHeader = standard.contents || [];

    const [subMenuHeader, setSubMenuHeader] = useState(sub.contents || []);

    const [searchKeyword, setSearchKeyword] = useState("");

    const [openMenu, setOpenMenu] = useState(false);

    const [activeSubMenu, setActiveSubMenu] = useState(null);

    const [activeDropdown, setActiveDropdown] = useState(null);

    const { getLink } = useGetLink();

    const { t } = useCustomTranslation();

    useEffect(() => {
        if (sub.contents) {
            let arr = [...sub.contents];
            arr = arr.map((v) => ({ ...v, isActive: false }));
            setSubMenuHeader(arr);
        }
    }, [arr, sub.contents]);

    useEffect(() => {
        if (isMobile) {
            setArr(standaradMenuHeader.concat(subMenuHeader));
        }
    }, [isMobile]);

    useEffect(() => {
        if (scrollTop.y > 400 && scrollTop.y - scrollTop.lastY > 0) {
            setVisible(false);
        } else {
            setVisible(true);
        }
    }, [scrollTop.y, scrollTop.lastY, visible]);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const searchParams = queryParams.get("q");

        if (searchParams && searchParams !== "") {
            setSearchKeyword(searchParams);
        }
    }, []);

    useEffect(() => {
        if (searchKeyword && searchKeyword.length >= 3) {
            navigate(`/search?q=${searchKeyword}`);
        }

        if (searchKeyword && searchKeyword.length < 3) {
            navigate("/");
        }
    }, [searchKeyword]);

    useEffect(() => {
        setSelectedArticle(null);
    }, [searchKeyword1]);

    const [typesenseAdapter, setTypesenseAdapter] = useState(
        new TypesenseInstantSearchAdapter({
            additionalSearchParameters: {
                query_by: QUERY_PARAMS.join(","),
                facet_by: FACET_PARAMS.join(","),
            },
        }),
    );

    useEffect(() => {
        const newAdapter = new TypesenseInstantSearchAdapter({
            additionalSearchParameters: {
                query_by: QUERY_PARAMS.join(","),
                facet_by: FACET_PARAMS.join(","),
            },
        });
        setTypesenseAdapter(newAdapter);
    }, []);

    const redirectToInstantSearchPage = () => {
        document.body.classList.remove("p-overflow-hidden");
        dispatch(setOpenSearchModal(false));
        navigate(`/search?q=${searchKeyword1}`);
    };

    return (
        <>
            <header className="header-main-1">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="navbar navbar-expand-md p-0">
                                <Link to={"/"}>
                                    <span className="logoMain cursor-pointer">
                                        <img src={standard?.section_image?.src} />
                                    </span>
                                </Link>
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#topMenu"
                                    aria-controls="topMenu"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span
                                        className="hamburger-menu-icon burger-menu-mobile"
                                        style={{ display: isDesktop ? "none" : "block" }}
                                        onClick={() => setOpenMenu(!openMenu)}
                                    >
                                        <img src="/assets/images/hamburger-menu.svg" />
                                    </span>
                                </button>

                                <ul className="tvMore-link">
                                    {!isMobile ? (
                                        standaradMenuHeader.map((value) => {
                                            return !value.contents ? (
                                                <CustomLink to={getLink(value.content_reference)}>
                                                    <li
                                                        className="nav-item cursor-pointer"
                                                        key={value.id}
                                                    >
                                                        <span
                                                            className="nav-link"
                                                            style={{
                                                                color: standard.colors
                                                                    ?.accent_color1,
                                                            }}
                                                        >
                                                            {value.heading}
                                                        </span>
                                                    </li>
                                                </CustomLink>
                                            ) : (
                                                <CustomLink to={getLink(value.content_reference)}>
                                                    <li
                                                        className="nav-item cursor-pointer"
                                                        key={value.id}
                                                    >
                                                        <span
                                                            className="nav-link dropdown"
                                                            id="more"
                                                            data-bs-toggle="dropdown"
                                                            aria-expanded="false"
                                                            style={{
                                                                color: standard.colors
                                                                    ?.accent_color1,
                                                            }}
                                                        >
                                                            {value.heading}
                                                            <i className="fa fa-angle-down ml-2"></i>
                                                        </span>

                                                        <ul
                                                            className="dropdown-menu"
                                                            aria-labelledby="more"
                                                        >
                                                            {value.contents.map((value) => (
                                                                <li key={value.heading}>
                                                                    <CustomLink
                                                                        to={getLink(
                                                                            value.content_reference,
                                                                        )}
                                                                        type={value.type}
                                                                    >
                                                                        <span
                                                                            className="dropdown-item"
                                                                            style={{
                                                                                color: standard
                                                                                    .colors
                                                                                    ?.accent_color1,
                                                                            }}
                                                                        >
                                                                            {value.heading}
                                                                        </span>
                                                                    </CustomLink>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </li>
                                                </CustomLink>
                                            );
                                        })
                                    ) : (
                                        <>
                                            <div
                                                className={`hide navbar-collapse ${
                                                    openMenu ? "show" : ""
                                                }`}
                                                id="topMenu"
                                            >
                                                {openMenu && (
                                                    <div className="searchMain">
                                                        <div className="userMain">
                                                            <div className="contPopup">
                                                                <div className="titleBox">
                                                                    <div className="flex">
                                                                        <span className="logoMain cursor-pointer">
                                                                            <Link TO={"/"}>
                                                                                <img src="/assets/images/footer_logo.svg" />
                                                                            </Link>
                                                                        </span>
                                                                        <i
                                                                            className="fa fa-times close-icon"
                                                                            style={{
                                                                                display: isDesktop
                                                                                    ? "none"
                                                                                    : "block",
                                                                            }}
                                                                            aria-hidden="true"
                                                                            onClick={() =>
                                                                                setOpenMenu(
                                                                                    !openMenu,
                                                                                )
                                                                            }
                                                                        ></i>
                                                                    </div>
                                                                </div>
                                                                <div className="contMain">
                                                                    <ul className="cursor-pointer">
                                                                        {arr.map((value) => {
                                                                            if (
                                                                                value.type ===
                                                                                    "sub_menu" &&
                                                                                !value.contents
                                                                            ) {
                                                                                return (
                                                                                    <MegaMenuResponsive
                                                                                        key={
                                                                                            value.content_reference
                                                                                        }
                                                                                        value={
                                                                                            value
                                                                                        }
                                                                                        activeSubMenu={
                                                                                            activeSubMenu
                                                                                        }
                                                                                        setActiveSubMenu={(
                                                                                            ref,
                                                                                        ) => {
                                                                                            setActiveSubMenu(
                                                                                                ref,
                                                                                            );
                                                                                            if (
                                                                                                ref
                                                                                            ) {
                                                                                                setActiveDropdown(
                                                                                                    null,
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                        colors={
                                                                                            standard.colors
                                                                                        }
                                                                                    />
                                                                                );
                                                                            } else if (
                                                                                value.contents &&
                                                                                value.type !==
                                                                                    "sub_menu"
                                                                            ) {
                                                                                return (
                                                                                    <DropDownMenuListResponsive
                                                                                        key={
                                                                                            value.heading
                                                                                        }
                                                                                        value={
                                                                                            value
                                                                                        }
                                                                                        openLink={
                                                                                            getLink
                                                                                        }
                                                                                        activeDropdown={
                                                                                            activeDropdown
                                                                                        }
                                                                                        setActiveDropdown={
                                                                                            setActiveDropdown
                                                                                        }
                                                                                        setActiveSubMenu={
                                                                                            setActiveSubMenu
                                                                                        }
                                                                                        colors={
                                                                                            standard.colors
                                                                                        }
                                                                                    />
                                                                                );
                                                                            } else {
                                                                                return (
                                                                                    <LabelOnlyMenuResponsive
                                                                                        key={
                                                                                            value.heading
                                                                                        }
                                                                                        value={
                                                                                            value
                                                                                        }
                                                                                        openLink={
                                                                                            getLink
                                                                                        }
                                                                                        colors={
                                                                                            standard.colors
                                                                                        }
                                                                                    />
                                                                                );
                                                                            }
                                                                        })}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </ul>

                                <div className="searchMain">
                                    <a
                                        className="search-icon cursor-pointer"
                                        onClick={() => dispatch(setOpenSearchModal(true))}
                                    >
                                        <i
                                            className="fa fa-search"
                                            style={{ color: standard.colors?.primary_color }}
                                        ></i>
                                    </a>
                                    <div className="userMain">
                                        {<LoginPanel Isheader2={false} />}
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>

            <section
                className={`MainNavWrapper1 ${visible ? "" : "slide-up"}`}
                style={{ background: sub.colors?.primary_color }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="navbar navbar-expand-sm p-0">
                                <div
                                    className="hide navbar-collapse md:justify-center"
                                    id="MainNav"
                                >
                                    <ul className="navbar-nav">
                                        {subMenuHeader.map((value) => {
                                            if (value.type === "sub_menu" && !value.contents) {
                                                return (
                                                    <MegaMenuList
                                                        key={value.heading}
                                                        value={value}
                                                        activeSubMenu={activeSubMenu}
                                                        setActiveSubMenu={setActiveSubMenu}
                                                        openLink={getLink}
                                                        colors={sub.colors}
                                                    />
                                                );
                                            } else if (
                                                value.contents &&
                                                value.type !== "sub_menu"
                                            ) {
                                                return (
                                                    <DropDownMenuList
                                                        key={value.heading}
                                                        value={value}
                                                        openLink={getLink}
                                                        colors={sub.colors}
                                                    />
                                                );
                                            } else {
                                                return (
                                                    <LabelOnlyMenu
                                                        key={value.heading}
                                                        value={value}
                                                        openLink={getLink}
                                                        colors={sub.colors}
                                                    />
                                                );
                                            }
                                        })}
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <Dialog
                visible={isSearchModalOpen}
                onHide={() => {
                    if (!isSearchModalOpen) return;
                    dispatch(setOpenSearchModal(false));
                }}
                style={{ width: "70vw", height: "auto" }}
                draggable={false}
                className="search-dialog"
            >
                <div className="container mt-12">
                    <InstantSearch
                        indexName="app_ismaili_articles"
                        searchClient={typesenseAdapter.searchClient}
                    >
                        <Configure hitsPerPage={10} />

                        <CustomSearchBoxModal
                            initialQuery={searchKeyword1}
                            onQueryChange={setSearchKeyword1}
                            closeModal={() => dispatch(setOpenSearchModal(false))}
                        />
                        {searchKeyword1 && (
                            <div className="row">
                                <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                                    <Hits
                                        hitComponent={(hitProps) => (
                                            <InstantSearchModalArticleDetails
                                                {...hitProps}
                                                setSelectedArticle={setSelectedArticle}
                                            />
                                        )}
                                    />
                                    <div
                                        className="ml-4 cursor-pointer showAll"
                                        onClick={() => redirectToInstantSearchPage()}
                                    >
                                        {t("btnText.ShowAll")}
                                        <i className="pi pi-arrow-right ml-2"></i>
                                    </div>
                                </div>
                                {selectedArticle && (
                                    <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                                        <div className="newsCard card">
                                            <div className="cardImg">
                                                <img
                                                    className="cursor-pointer"
                                                    src={selectedArticle.image}
                                                    alt="placeholder"
                                                />
                                            </div>
                                            <div className="card-body flex flex-col text-start">
                                                <h3 className="underline">
                                                    {selectedArticle.title}
                                                </h3>
                                                <div className="author mr-1">
                                                    {selectedArticle.tags}
                                                </div>
                                                <div className="summary">
                                                    {parse(selectedArticle.summary)}
                                                </div>
                                                <CustomLink
                                                    to={getLink(
                                                        selectedArticle.path,
                                                        selectedArticle.type,
                                                        selectedArticle.language,
                                                    )}
                                                >
                                                    ,
                                                    <i
                                                        className="pi pi-arrow-right arrow-icon"
                                                        aria-hidden="true"
                                                        onClick={() => {
                                                            document.body.classList.remove(
                                                                "p-overflow-hidden",
                                                            );
                                                        }}
                                                    ></i>
                                                </CustomLink>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </InstantSearch>
                </div>
            </Dialog>
        </>
    );
}

export default HeaderComponent;
