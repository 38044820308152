import moment from "moment";
import React from "react";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import ImageWithObjectFit from "../ImageWrapperComponent";
import PlayIconSvg from "../../assets/PlayIconSvg";

function FeaturedCard2({ content, colorsObj }) {
    const navigate = useNavigate();

    const { image, title, author, date, summary, nid, posterImage, link } = content;

    const handleClick = () => navigate(`/articles/${nid}`);

    return (
        <>
            <div className="newsCard card">
                <div className="dateName">
                    <span className="name">{author}</span>
                    <span className="date">
                        {author && <i className="dot"></i>}
                        {date ? moment(date).format(`MMM DD${","} YYYY`) : ""}
                    </span>
                </div>
                <div className="cardImg">
                    {!!link && (
                        <div
                            className="playIconHldr"
                            style={{
                                "--accent_color4": colorsObj.accent_color4,
                            }}
                        >
                            <PlayIconSvg />
                        </div>
                    )}
                    <ImageWithObjectFit
                        className="cursor-pointer"
                        src={image?.src || posterImage}
                        onClick={handleClick}
                    />
                </div>
                <div className="sub-card-body">
                    <h3>{title}</h3>
                    <div className="textMain">{summary ? parse(summary) : ""}</div>
                </div>
            </div>
        </>
    );
}

export default FeaturedCard2;
