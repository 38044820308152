import React from "react";
import MegaMenuMobile from "../MegaMenu/MegaMenuMobile";

function MegaMenuResponsive({ value, activeSubMenu, setActiveSubMenu, colors }) {
    const handleAccordionClick = () => {
        setActiveSubMenu(
            activeSubMenu === value.content_reference ? null : value.content_reference,
        );
    };

    return (
        <div className="mega-menu w-full">
            <div className="accordion navMainWrapper" id="navigationMain">
                <div className="accordion-item">
                    <h2 className="accordion-header" id={`${value.content_reference}`}>
                        <span
                            className="accordion-button collapsed"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${value.content_reference}`}
                            aria-expanded={
                                activeSubMenu === value.content_reference ? "true" : "false"
                            }
                            aria-controls={`collapse${value.content_reference}`}
                            onClick={handleAccordionClick}
                            style={{
                                color:
                                    activeSubMenu === value.content_reference
                                        ? colors?.accent_color4
                                        : colors.accent_color1,
                                "--primary_color":
                                    activeSubMenu === value.content_reference
                                        ? colors?.accent_color4
                                        : colors.accent_color1,
                                "--up-down-arrow":
                                    activeSubMenu === value.content_reference
                                        ? `"\\f106"`
                                        : `"\\f107"`,
                                background:
                                    activeSubMenu === value.content_reference
                                        ? colors.primary_color
                                        : "none",
                            }}
                        >
                            {value.heading}
                        </span>
                    </h2>
                    <div
                        id={`collapse${value.content_reference}`}
                        className={`accordion-collapse hide ${
                            activeSubMenu === value.content_reference ? "show" : ""
                        }`}
                        aria-labelledby={`collapse${value.content_reference}`}
                        data-bs-parent="#navigationMain"
                    >
                        <div className="accordion-body">
                            {activeSubMenu === value.content_reference && (
                                <MegaMenuMobile content_reference={value.content_reference} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MegaMenuResponsive;
