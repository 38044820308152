import React, { useCallback, useEffect, useState } from "react";
import "./RelatedCardCarousel2.css";
import { useSelector } from "react-redux";
import useCustomTranslation from "../../utils/useCustomTranslation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid, Navigation, Pagination } from "swiper/modules";
import { RelatedCard2 } from "../Card/RelatedCard2";
import ImageWithObjectFit from "../ImageWrapperComponent";
import * as posterImage from "../../utils/usePosterImage";
import parse from "html-react-parser";
import { ARTICLE_DETAILS, CONTENT_DETAILS } from "../../constants/strings";

function RelatedCardCarousel2({
    contents: contentToRender = [],
    heading,
    colors: colorObj,
    block_source,
}) {
    const [contentReferenceData, setContentReferenceData] = useState([]);

    const content = useSelector((state) => state.layout.content);

    const setCurrentContent = useCallback(
        async (contentKey) => {
            const respKeys = content[contentKey];
            let resp = respKeys.map((k) => content[k]);
            resp = JSON.parse(JSON.stringify(resp));
            for await (const element of resp) {
                if (element && element.link) {
                    element.posterImage = await posterImage.usePosterImage(
                        element?.link,
                        element?.image,
                    );
                }
            }
            if (resp.length) {
                setContentReferenceData(resp);
            }
        },
        [content],
    );

    useEffect(() => {
        if (block_source === CONTENT_DETAILS) {
            setContentReferenceData(contentToRender);
        }
        if (block_source === ARTICLE_DETAILS) {
            setCurrentContent(contentToRender[0].contentKey);
        }
    }, []);

    const { direction } = useCustomTranslation();

    const swiperCarousel = {
        slidesPerView: 3,
        navigation: true,
        grid: {
            rows: 1,
            fill: "row",
        },
        spaceBetween: 30,
        pagination: {
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 7,
        },
        modules: [Grid, Pagination, Navigation],
        breakpoints: {
            345: {
                grid: {
                    rows: 3,
                    fill: "row",
                },
                slidesPerView: 1,
                spaceBetween: 20,
            },
            576: {
                grid: {
                    rows: 1,
                    fill: "row",
                },
                slidesPerView: 1.1,
                spaceBetween: 20,
            },
            768: {
                grid: {
                    rows: 1,
                    fill: "row",
                },
                slidesPerView: 2,
            },
            1024: {
                grid: {
                    rows: 1,
                    fill: "row",
                },
                spaceBetween: 20,
                slidesPerView: 3,
            },
            1280: {
                grid: {
                    rows: 1,
                    fill: "row",
                },
                spaceBetween: 20,
                slidesPerView: 3,
            },
        },
    };

    return (
        <div
            className="relaredCard2Wrapper py-50"
            style={{ background: colorObj?.background_color }}
        >
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="titleMain">
                            <h3 style={{ color: colorObj?.primary_color }}>{heading}</h3>
                            <div
                                className="line"
                                style={{ background: colorObj?.primary_color }}
                            ></div>
                        </div>
                        <Swiper
                            dir={direction}
                            key={direction}
                            {...swiperCarousel}
                            style={{ "--primary_color": colorObj?.primary_color }}
                        >
                            {block_source === ARTICLE_DETAILS
                                ? contentReferenceData.map((card) => (
                                      <SwiperSlide key={card.title}>
                                          <RelatedCard2 content={card} colorsObj={colorObj} />
                                      </SwiperSlide>
                                  ))
                                : contentReferenceData.map((card) => (
                                      <SwiperSlide key={card.title}>
                                          <div className="newsCard card">
                                              <div className="cardImg">
                                                  <ImageWithObjectFit
                                                      className="cursor-pointer"
                                                      src={card.content_image?.src}
                                                  />
                                              </div>
                                              <div className="card-body flex flex-col text-start">
                                                  <div
                                                      className="tag-heading mb-2"
                                                      style={{ color: colorObj.primary_color }}
                                                  >
                                                      {card.heading}
                                                  </div>
                                                  <h3
                                                      className="hover:underline cursor-pointer"
                                                      style={{ color: colorObj.accent_color1 }}
                                                  >
                                                      {card.body && parse(card.body)}
                                                  </h3>
                                              </div>
                                          </div>
                                      </SwiperSlide>
                                  ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RelatedCardCarousel2;
