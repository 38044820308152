import useCustomTranslation from "../../utils/useCustomTranslation";

export const FooterDisplay = ({ setShowModal, countryName, languageName, regionName }) => {
    const { t } = useCustomTranslation();

    return (
        <div
            className="cursor-pointer mb-8"
            onClick={() => {
                setShowModal((e) => !e);
            }}
        >
            <a className="flex items-center justify-center text-sm font-normal border border-accent_color3 border-solid rounded-xl py-2 px-3 hover:text-accent_color3">
                <i className="pi pi-globe text-accent_color3"></i>
                <span className="mx-2">
                    {t(`countryHeader.${countryName}`, countryName)}
                    {regionName ? ` (${t(`regionHeader.${regionName}`, regionName)})` : ""}
                    {languageName ? ` (${t(`languageHeader.${languageName}`, languageName)})` : ""}
                </span>
                <i className="pi pi-angle-down"></i>
            </a>
        </div>
    );
};
