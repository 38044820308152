import Loader from "../components/Loader/loader";

export const getLoadingElement = (layoutType) => {
    switch (layoutType) {
        case "image_hero_carousel":
            return <div className="sliderBox skeleton-loader"></div>;
        default:
            return <Loader isLoading={true} />;
    }
};
