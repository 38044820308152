import React, { useEffect } from "react";
import "./ApplicationTileLinks.css";
import { useDispatch, useSelector } from "react-redux";
import { useGetLink } from "../../utils/useOpenLink";
import { fetchApplicationTileLinks } from "../../redux/ImageApplicationsTileLinksSlice";
import CustomLink from "../../utils/customLink";

function ApplicationTileLinks({ contents: contentsArray = [], colors: colorObj }) {
    const dispatch = useDispatch();
    const contentsData = useSelector(
        (state) => state.applicationImageTileLinks.applicationsData || [],
    );
    const { getLink } = useGetLink();

    useEffect(() => {
        if (contentsArray.length > 0) {
            const apiLink = contentsArray[0].contentKey;
            dispatch(fetchApplicationTileLinks(apiLink));
        }
    }, [dispatch, contentsArray]);
    return (
        <>
            <section
                className="application_links py-50"
                style={{ background: colorObj?.background_color }}
            >
                <div className="container">
                    <div className="row row-flex" style={{ justifyContent: "center" }}>
                        {contentsData.map((value, i) => (
                            <div className="col-lg-4 col-md-4 col-sm-6 col-12" key={i}>
                                <div
                                    className="post-card flex flex-col"
                                    style={{
                                        "--accent_color4": colorObj?.accent_color4,
                                        "--primary_color": colorObj?.primary_color,
                                    }}
                                >
                                    <div
                                        className="cardTitle"
                                        style={{ "--primary_color": colorObj?.primary_color }}
                                    >
                                        {value?.title}
                                    </div>
                                    <h3 style={{ "--accent_color1": colorObj?.accent_color1 }}>
                                        {value?.summary}
                                    </h3>
                                    <p style={{ "--accent_color3": colorObj?.accent_color3 }}>
                                        {value?.body}
                                    </p>
                                    <CustomLink
                                        to={getLink(value?.content_reference)}
                                        type={value?.type}
                                    >
                                        <span
                                            className="btn btn-main"
                                            style={{
                                                "--accent_color4": colorObj?.accent_color4,
                                                "--primary_color": colorObj?.primary_color,
                                            }}
                                        >
                                            {value?.link_text}
                                        </span>
                                    </CustomLink>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
}

export default ApplicationTileLinks;
