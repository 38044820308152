import React from "react";
import "./KeyPoints.css";
import parse from "html-react-parser";

function KeyPoints({ contents: contentsArray = [], heading, body, colors: colorObj }) {
    return (
        <>
            <div
                className="communityWrapper my-30"
                style={{
                    background: colorObj?.background_color,
                    "--accent_color2": colorObj?.accent_color2,
                }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1
                                className="text-center"
                                style={{ "--primary_color": colorObj?.primary_color }}
                            >
                                {heading}
                            </h1>
                            <p
                                className="header-body-text"
                                style={{ "--accent_color1": colorObj?.accent_color1 }}
                            >
                                {body && parse(body)}
                            </p>
                            <div
                                className="mt-4 mb-4 line"
                                style={{ "--accent_color3": colorObj?.accent_color3 }}
                            >
                                <hr />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                {contentsArray.map((value, index) => (
                                    <div className="col-md-3 mb-4" key={index}>
                                        <div className="row">
                                            <div className="col-md-2 col-2">
                                                <div
                                                    className="number"
                                                    style={{
                                                        "--accent_color2": colorObj?.accent_color2,
                                                    }}
                                                >
                                                    {value.heading}
                                                </div>
                                            </div>
                                            <div className="col-md-10 col-10">
                                                <div
                                                    className="text"
                                                    style={{
                                                        "--accent_color1": colorObj?.accent_color1,
                                                    }}
                                                >
                                                    {value.body && parse(value.body)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default KeyPoints;
