import React from "react";
import "./FeaturedFocus.css";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

function FeaturedFocus({ contents: contentsArray, heading }) {
    const swiperCarousel = {
        spaceBetween: 10,
        slidesPerView: 1,
        modules: [Pagination, Navigation],
        pagination: { clickable: true, dynamicBullets: true, dynamicMainBullets: 7 },
        navigation: true,
    };

    return (
        <>
            <div className="FeaturedWrapper container pt-5 mt-5">
                <div className="row">
                    <div className="col-12">
                        <div className="titleMain">
                            <h2>{heading}</h2>
                        </div>
                    </div>
                    <div className="col-12">
                        <Swiper {...swiperCarousel}>
                            {contentsArray?.map((v, i) => (
                                <SwiperSlide key={i}>
                                    <div className="FeaturedCarouselWrapper">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <div className="imgMain">
                                                    <img src={v?.content_image?.src}></img>
                                                    <h3>{v.heading}</h3>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12">
                                                <div className="FeaturedServicesWrapper">
                                                    <ul>
                                                        {(v?.contents || []).map((value, i) => (
                                                            <li key={i}>
                                                                <h2>{value.heading}</h2>
                                                                <div className="icon-wrap">
                                                                    <img
                                                                        src={
                                                                            value?.sub_content_image
                                                                                ?.src
                                                                        }
                                                                    ></img>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FeaturedFocus;
