import React from "react";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { shareOnInstagram, shareOnWhatsapp } from "../../utils/articleHelpers";
import ShareboxFacebook from "../../assets/ShareboxFacebook";
import ShareboxInstagram from "../../assets/ShareboxInstagram";
import ShareboxTwitter from "../../assets/ShareboxTwitter";
import ShareboxWhatsApp from "../../assets/ShareboxWhatsApp";

function ShareOnSocialLinksModal() {
    return (
        <>
            <div className="socialMain">
                <span onClick={() => shareOnWhatsapp()}>
                    <ShareboxWhatsApp />
                </span>
                <span>
                    <TwitterShareButton url={window.location.href}>
                        <ShareboxTwitter />
                    </TwitterShareButton>
                </span>
                <span onClick={() => shareOnInstagram()}>
                    <ShareboxInstagram />
                </span>
                <span>
                    <FacebookShareButton url={window.location.href}>
                        <ShareboxFacebook />
                    </FacebookShareButton>
                </span>
            </div>
        </>
    );
}

export default ShareOnSocialLinksModal;
