import useCustomTranslation from "../utils/useCustomTranslation";

export const SITE_NAME = "The Ismaili";
export const APP_STORE_LINK = "https://apps.apple.com/us/app/the-ismaili/id1407605163";
export const PLAY_STORE_LINK = "https://play.google.com/store/apps/details?id=com.iiglobal";

export const usePrivacyLinks = () => {
    const { t } = useCustomTranslation();

    return [
        {
            label: t("footerText.privacyPolicy"),
            action: "privacy-policy",
        },
        {
            label: t("footerText.TermsAndCondition"),
            action: "terms-conditions",
        },
    ];
};

export const GLOBAL_SOCIAL_LINKS = {
    facebook: "https://www.facebook.com/theismaili/",
    x: "https://x.com/theismaili",
    instagram: "https://www.instagram.com/theismaili/",
    youtube: "https://www.youtube.com/theismaili",
    linkedin: "https://www.linkedin.com/company/the-ismaili",
};
