import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
    selectCountriesLanguageArray,
    selectCountryLanguageCodes,
    getCountryLanguageDataFromLocalStorageOrIp,
    setCountryInfo,
    setLanguageInfo,
    getCountriesData,
    setRegionInfo,
    setArticleLanguage,
} from "../redux/authSlice";
import {
    DEFAULT_COUNTRY_CODE,
    getAllVaidCountryLanguageCodes,
    getCountryLanguageInfoLocalStorage,
    getValidCountryLanguageCode,
} from "./countryLanguageHelpers";
import { useLocation } from "react-router-dom";

export const useCountryLanguageLinkExtractor = () => {
    const { pathname } = useLocation();
    const linkFromProps = pathname.slice(1);

    const dispatch = useDispatch();

    const [link, setLink] = useState(null);

    const countriesLanguagesArray = useSelector(
        selectCountriesLanguageArray,
        (prev, next) => prev.length === next.length,
    );
    const { countryCode: countryCodeFromState, languageCode: languageCodeFromState } = useSelector(
        selectCountryLanguageCodes,
        shallowEqual,
    );
    const { regionId: regionIdFromLS } = getCountryLanguageInfoLocalStorage();

    // const isFetchingCountryLanguage = useSelector((state) => state.auth?.isFetchingCountryLanguage);

    useEffect(() => {
        const linkWithoutLeadingSlash = linkFromProps?.startsWith("/")
            ? linkFromProps.slice(1)
            : linkFromProps;
        const [countryCodeFromLinkCase, languageCodeFromLinkCase, ...idFromLinkArray] =
            linkWithoutLeadingSlash.split("/");
        const linkPartA = countryCodeFromLinkCase?.toLowerCase();
        const linkPartB = languageCodeFromLinkCase?.toLowerCase();
        const linkPartC = idFromLinkArray.join("/");

        // Need to handle these cases:
        // - https://devwebsite.theismaili.dyndns.org/global <- your local site and lang
        // - https://devwebsite.theismaili.dyndns.org/global/fr/news/how-talk-your-children-about-time-transition
        //    <- your local site and lang, article in french
        // - ⁠https://devwebsite.theismaili.dyndns.org/fr/ar/news/how-talk-your-children-about-time-transition
        //    <- french site,
        //      site lang chagned to arabic (since arabic is a site lang for france unless your home country is france and you selected another lang)
        //      article in arabic
        // - https://devwebsite.theismaili.dyndns.org/fr/pt/news/how-talk-your-children-about-time-transition
        //    <- french site, site lang in default lang for france (or if your home site is france, then the lang you already selected)
        //      article in portuguese
        // - https://devwebsite.theismaili.dyndns.org/fr/news/how-talk-your-children-about-time-transition
        //    <- french site, site lang and article in default lang for france (or if your home site is france, then the lang you already selected)
        if (!countriesLanguagesArray.length) dispatch(getCountriesData());
        else if (!countryCodeFromState && !languageCodeFromState)
            dispatch(getCountryLanguageDataFromLocalStorageOrIp());
        else if (!countryCodeFromState || !languageCodeFromState) return;
        // Check Case#3, when country and lang in link is the same as in state
        else if (
            linkPartA === countryCodeFromState &&
            linkPartB === languageCodeFromState &&
            linkPartA !== DEFAULT_COUNTRY_CODE.toLowerCase()
        ) {
            setLink(linkPartC);
        } else {
            const countryToUse =
                linkPartA === DEFAULT_COUNTRY_CODE.toLowerCase() ? countryCodeFromState : linkPartA;

            const {
                countryCode: validCountryCode,
                languageCode: validLanguageCode,
                languageDirection: validDirectionCode,
                regionId: validRegionId,
            } = getValidCountryLanguageCode(
                countriesLanguagesArray,
                countryToUse,
                linkPartB,
                regionIdFromLS,
            );
            const allValidLangs = getAllVaidCountryLanguageCodes(countriesLanguagesArray);
            if (countryToUse !== validCountryCode) setLink(linkWithoutLeadingSlash);
            else {
                if (countryToUse !== countryCodeFromState) {
                    dispatch(setCountryInfo({ code: validCountryCode, sessionOnly: true }));
                    dispatch(setRegionInfo({ id: validRegionId, sessionOnly: true }));
                    dispatch(
                        setLanguageInfo({
                            code: validLanguageCode,
                            direction: validDirectionCode,
                            sessionOnly: true,
                        }),
                    );
                }
                if (linkPartB === validLanguageCode || allValidLangs.includes(linkPartB)) {
                    if (countryToUse !== countryCodeFromState) {
                        dispatch(
                            setLanguageInfo({
                                code: validLanguageCode,
                                direction: validDirectionCode,
                                sessionOnly: true,
                            }),
                        );
                    }
                    if (linkPartB !== languageCodeFromState && linkPartC) {
                        dispatch(setArticleLanguage(linkPartB));
                    }
                    setLink(linkPartC);
                } else {
                    if (countryToUse !== countryCodeFromState) {
                        dispatch(
                            setLanguageInfo({
                                code: validLanguageCode,
                                direction: validDirectionCode,
                                sessionOnly: true,
                            }),
                        );
                    }

                    setLink(linkPartB ? `${linkPartB}/${linkPartC || ""}` : "");
                }
            }
        }
    }, [linkFromProps, countriesLanguagesArray, countryCodeFromState, languageCodeFromState]);

    return link;
};
