import React from "react";
import "./IconLinks2.css";
import { Link } from "react-router-dom";
import { useGetLink } from "../../utils/useOpenLink";

function IconLinks2({ contents: contentsArray, colors: colorObj }) {
    const { getLink } = useGetLink();

    return (
        <>
            <div
                className="iconLinks2Wrapper py-50"
                style={{ background: colorObj?.background_color }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="newsCardHldr">
                                {contentsArray.map((value) => (
                                    <div
                                        className="newsCard"
                                        key={value.heading}
                                        style={{
                                            "--background_color": colorObj?.background_color,
                                            "--accent_color2": colorObj?.accent_color2,
                                        }}
                                    >
                                        <div
                                            className={`newslogoImg ${
                                                value.heading === "" ? "mr-0 w-full" : ""
                                            }`}
                                        >
                                            <Link to={getLink(value.contentKey)}>
                                                <img
                                                    className="cursor-pointer"
                                                    src={
                                                        value.content_image.src &&
                                                        value.content_image.src
                                                            ? value.content_image.src
                                                            : ""
                                                    }
                                                    style={{
                                                        "--object-fit":
                                                            value.heading === ""
                                                                ? "cover"
                                                                : "contain",
                                                    }}
                                                />
                                            </Link>
                                        </div>

                                        <Link to={getLink(value.contentKey, value.type)}>
                                            <div
                                                className={`newsTitle cursor-pointer ${
                                                    value.heading === "" ? "hidden" : ""
                                                }`}
                                                style={{
                                                    "--accent_color1": colorObj?.accent_color1,
                                                }}
                                            >
                                                {" "}
                                                {value.heading}
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default IconLinks2;
