import React, { useCallback, useEffect, useState } from "react";
import "./TagCarousel.css";
import { useSelector } from "react-redux";
import TagCard from "../Card/TagCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { swiperCarousel } from "../../utils/carouselFunction";
import useCustomTranslation from "../../utils/useCustomTranslation";
import * as posterImage from "../../utils/usePosterImage";

function TagCarousel({ contents: contentToRender = [], background, heading, colors: colorObj }) {
    const [contentReferenceData, setContentReferenceData] = useState([]);

    const [data, setData] = useState([]);

    const [tagList, setTagList] = useState([{}]);

    const [selectedTagIndex, setSelectedTagIndex] = useState(0);

    const { dir } = useCustomTranslation();

    const content = useSelector((state) => state.layout.content);

    const setCurrentContent = useCallback(
        async (contentKey) => {
            const respKeys = content[contentKey];
            let resp = respKeys.map((k) => content[k]);
            resp = JSON.parse(JSON.stringify(resp));
            for await (const element of resp) {
                if (element && element.link) {
                    element.posterImage = await posterImage.usePosterImage(
                        element?.link,
                        element?.image,
                    );
                }
            }
            if (resp.length) {
                setContentReferenceData(resp);
                setData(resp);
            }
        },
        [content],
    );

    useEffect(() => {
        setCurrentContent(contentToRender[0].contentKey);
    }, []);

    useEffect(() => {
        let tags = [];
        for (const iterator of data) {
            const tagArr = iterator.tags.split(", ");
            tags = tags.concat(tagArr);
        }
        tags = tags.filter((v, i, a) => a.findIndex((v2) => v2 === v) === i);
        let array = [];
        array.push({
            tagName: "All",
            isSelectd: true,
        });
        tags.forEach((tag) => {
            array.push({
                tagName: tag,
                isSelectd: false,
            });
        });
        setTagList(array);
    }, [data]);

    useEffect(() => {
        let arr = [];
        if (tagList[selectedTagIndex].tagName !== "All") {
            const filteredData = data.filter((e) =>
                e.tags.includes(tagList[selectedTagIndex].tagName),
            );

            arr = arr.concat(filteredData);
            arr = Object.values(arr.reduce((acc, obj) => ({ ...acc, [obj.title]: obj }), {}));
            setContentReferenceData(arr);
        } else {
            setContentReferenceData(data);
        }
    }, [data, tagList]);

    const activeTags = (i) => {
        const arr = [...tagList];
        for (let index = 0; index < arr.length; index++) {
            const element = arr[index];
            element.isSelectd =
                i === index ? (element.isSelectd ? true : !element.isSelectd) : false;
        }
        setTagList(arr);
    };

    return (
        <>
            <div className="recentReleaseWrapper" style={{ background: background }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="title">{heading !== "" && <h3>{heading}</h3>}</div>
                        </div>

                        <div className="col-12">
                            <div className="programmesTagWrapper">
                                <ul className="overflow-x-auto scrollbarStyles hidescrollbar">
                                    {tagList.map((value, index) => (
                                        <li
                                            className={`${!value.isSelectd ? "" : "active"}`}
                                            key={value.tagName}
                                            onClick={() => {
                                                activeTags(index);
                                                setSelectedTagIndex(index);
                                            }}
                                        >
                                            {value.tagName}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="col-12">
                            <Swiper dir={dir} key={dir} {...swiperCarousel}>
                                {contentReferenceData.map((card) => (
                                    <SwiperSlide key={card.title}>
                                        <TagCard content={card} colorsObj={colorObj} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TagCarousel;
