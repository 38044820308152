import React, { useEffect, useState } from "react";
import "./ArticlesDetails2.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchArticle } from "../../redux/layoutSlice";
import ImageWithObjectFit from "../../components/ImageWrapperComponent";
import { calculateReadTime, parseWithRemoveBrTagsFromBody } from "../../utils/articleHelpers";
import PrintSvg from "../../assets/PrintSvg";
import PDFSvg from "../../assets/PDFSvg";
import BreadCrumbs from "../../components/BreadCrumbs";
import ShareOnSocialLinks from "../../components/ShareOnSocialLinks";
import ReactPlayer from "react-player";
import useCustomTranslation from "../../utils/useCustomTranslation";
import { Dropdown } from "primereact/dropdown";
import useGetArticleMeta from "../../utils/useGetArticleMeta";
import PhotoGallery from "../../components/PhotoGallery";
import MetaTags from "../../utils/metaTagsFunction";
import { Swiper, SwiperSlide } from "swiper/react";
import parse from "html-react-parser";
import { Navigation, Pagination } from "swiper/modules";
import { updateFontFamily } from "../../redux/authSlice";
import clsx from "clsx";
import { additionalClassNameFromLanguage } from "../../utils/additionalClassNameFromLanguage";
import useUpdateUrlWithLang from "../../utils/useUpdateUrlWithLang";
import { getDateToDisplayInArticlePage } from "../../utils/dateFunctions";
import { languageNameCodeMap } from "../../utils/countryLanguageHelpers";

function Articles2({ contents, colors: colorObj }) {
    const { articleId: articleIdFromParams } = useParams();

    const id = contents?.[0]?.id || articleIdFromParams;

    const dispatch = useDispatch();

    const [selectedLanguage, setSelectedLanguage] = useState(null);

    const [currentLangName, setCurrentLangName] = useState();

    const [currentLangDir, setCurrentLangDir] = useState();

    const [selectedLangCode, setSelectedLangCode] = useState();

    const isLoading = useSelector((state) => state.layout.isLoading);
    const content = useSelector((state) => state.layout.content);

    const fontFamily = useSelector((state) => state.auth.fontFamily);

    const { t } = useCustomTranslation();

    const { updateUrlWithLang } = useUpdateUrlWithLang();

    const {
        title,
        date,
        body,
        summary,
        microsite,
        detailImage,
        region,
        article_pdf,
        breadcrumb,
        link,
        translate_languages,
        language,
        image_series,
        image_carousel,
    } = content[id] || {};

    useEffect(() => {
        if (!isLoading && !title) {
            dispatch(fetchArticle({ id }));
        }
    }, [isLoading, title]);

    useEffect(() => {
        updateUrlWithLang(id, languageNameCodeMap[language]);

        if (translate_languages) {
            const index = translate_languages.findIndex((e) => e.language === language);
            if (index !== -1) {
                setSelectedLanguage(translate_languages[index]);
            }

            const currentLangCode = translate_languages[index]?.lang_code;
            setCurrentLangName(translate_languages[index]?.language);
            setCurrentLangDir(translate_languages[index]?.direction);
            setSelectedLangCode(currentLangCode);
            dispatch(updateFontFamily(currentLangCode));
        }
    }, [language]);

    const handleLanguageChange = (e) => {
        dispatch(fetchArticle({ id, lang: e.value }));
        updateUrlWithLang(id, e.value.lang_code);
    };

    const { getLocation, getBreadcrumbArray, getTheme } = useGetArticleMeta();

    const location = getLocation(microsite);
    const breadcrumbArray = getBreadcrumbArray(breadcrumb);
    const displayTheme = getTheme(breadcrumb);

    const swiperCarousel = {
        spaceBetween: 10,
        slidesPerView: 1,
        modules: [Pagination, Navigation],
        pagination: { clickable: true, dynamicBullets: true, dynamicMainBullets: 7 },
        navigation: true,
    };

    return (
        <>
            <MetaTags
                pageType="article"
                summary={summary}
                image={image_carousel?.length > 0 ? image_carousel[0]?.src : detailImage?.src}
            />

            <section className="news-annou-wrapper" style={{ fontFamily }}>
                <div className="container">
                    <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                        <BreadCrumbs data={breadcrumbArray} colorObj={colorObj} />
                    </div>
                    <div className="row news-annou-hldr">
                        <div className="col-lg-7 col-md-7 col-sm-12 col-12">
                            <div className="news-annou-txtMain">
                                <div
                                    className="tagMain"
                                    style={{ color: colorObj?.primary_color }}
                                    dir={currentLangDir}
                                >
                                    {displayTheme}
                                </div>
                                <h1 style={{ color: colorObj?.accent_color1 }} dir={currentLangDir}>
                                    {title}
                                </h1>
                            </div>
                            <div className="cont-social-hldr">
                                <div className="cont-main capitalize">
                                    <h4 style={{ color: colorObj?.accent_color1 }}>{location}</h4>
                                    <div className="dateTime">
                                        <span style={{ color: colorObj?.accent_color3 }}>
                                            {region ? `${region} • ` : ""}
                                            {calculateReadTime(content[id], selectedLangCode)
                                                ? `${calculateReadTime(
                                                      content[id],
                                                      selectedLangCode,
                                                  )} ${t("constantString.minRead")} • `
                                                : ""}
                                            {getDateToDisplayInArticlePage(date, selectedLangCode)}
                                        </span>
                                    </div>
                                </div>

                                <ShareOnSocialLinks />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-12 col-12 mobile-view">
                            {link ? (
                                <div className="news-annou-img">
                                    <div className="row flex justify-center">
                                        <div className="col-12 mobile-view">
                                            <ReactPlayer
                                                url={link}
                                                controls
                                                className="video-ratio"
                                            />
                                        </div>
                                        <div className="col-12">
                                            <div
                                                className="imgtext"
                                                style={{ color: colorObj?.accent_color1 }}
                                                dir={currentLangDir}
                                            >
                                                {parse(detailImage?.title || "")}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <Swiper
                                    {...swiperCarousel}
                                    style={{
                                        "--primary_color": colorObj?.primary_color,
                                        "--accent_color4": colorObj?.accent_color4,
                                    }}
                                    key={language}
                                >
                                    {(image_carousel?.length
                                        ? image_carousel
                                        : [detailImage || {}]
                                    )?.map((card) => (
                                        <SwiperSlide key={card?.title}>
                                            <div className="card h-full">
                                                <div className="cardImg">
                                                    <ImageWithObjectFit src={card?.src} />
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            )}
                        </div>
                    </div>
                </div>
            </section>

            <section
                className={clsx([
                    "article2-TextSecation",
                    additionalClassNameFromLanguage(currentLangName),
                ])}
                dir={currentLangDir}
                style={{ "--primary_color": colorObj?.primary_color, fontFamily }}
            >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="textHldr">
                                <div className="row">
                                    <div
                                        className={clsx([
                                            "col-lg-9 col-md-9 col-sm-12 col-12 card-order",
                                            additionalClassNameFromLanguage(currentLangName),
                                        ])}
                                    >
                                        <div style={{ color: colorObj?.accent_color1 }}>
                                            {parseWithRemoveBrTagsFromBody(
                                                summary || "",
                                                colorObj?.primary_color,
                                            )}
                                        </div>
                                        <div style={{ color: colorObj?.accent_color1 }}>
                                            {parseWithRemoveBrTagsFromBody(
                                                body || "",
                                                colorObj?.primary_color,
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                        <div
                                            className="iconHldr"
                                            style={{ "--accent_color3": colorObj?.accent_color3 }}
                                        >
                                            {translate_languages?.length > 1 && (
                                                <Dropdown
                                                    options={translate_languages}
                                                    optionLabel="language"
                                                    value={selectedLanguage}
                                                    onChange={handleLanguageChange}
                                                    placeholder="language"
                                                    className="w-full md:w-14rem"
                                                    style={{ "--font_family": fontFamily }}
                                                />
                                            )}
                                            <ul>
                                                <li
                                                    className="cursor-pointer"
                                                    onClick={() => window.print()}
                                                >
                                                    <PrintSvg />
                                                </li>
                                                <li
                                                    className="cursor-pointer"
                                                    onClick={() =>
                                                        window.open(article_pdf, "_blank")
                                                    }
                                                >
                                                    <PDFSvg />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="article2-photoGallery">
                {image_series !== null && image_series?.length > 0 && (
                    <PhotoGallery image_series={image_series} article_type="article2" />
                )}
            </div>
        </>
    );
}

export default Articles2;
