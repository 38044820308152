import React from "react";

const ShareboxFacebook = () => {
    return (
        <svg
            width="27"
            height="29"
            viewBox="0 0 27 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M23 14.5578C23 9.27789 18.748 5 13.5 5C8.25202 5 4 9.27789 4 14.5578C4 19.3367 7.44758 23.3063 12.006 24V17.3327H9.59274V14.5578H12.006V12.4767C12.006 10.0872 13.4234 8.73834 15.5685 8.73834C16.6411 8.73834 17.7137 8.93103 17.7137 8.93103V11.2819H16.5262C15.3387 11.2819 14.9556 12.0142 14.9556 12.785V14.5578H17.5988L17.1774 17.3327H14.9556V24C19.5141 23.3063 23 19.3367 23 14.5578Z"
                fill="#828282"
            />
        </svg>
    );
};

export default ShareboxFacebook;
