import { useNavigate } from "react-router-dom";
import { selectCountryLanguageCodes } from "../redux/authSlice";
import { shallowEqual, useSelector } from "react-redux";

export default function useUpdateUrlWithLang() {
    const navigate = useNavigate();
    const { countryCode, languageCode } = useSelector(selectCountryLanguageCodes, shallowEqual);

    const updateUrlWithLang = (id, lang, country) => {
        navigate(`/${country || countryCode}/${lang || languageCode}/${id || ""}`, {
            replace: true,
        });
    };

    return { updateUrlWithLang };
}
