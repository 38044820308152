import React from "react";
import "./PageNotFound.css";
import { Button } from "../Button";
import { useDispatch } from "react-redux";
import { setOpenSearchModal } from "../../redux/authSlice";
import parse from "html-react-parser";
import useCustomTranslation from "../../utils/useCustomTranslation";
import { Link } from "react-router-dom";

function PageNotFound({ section_image, heading, body }) {
    const dispatch = useDispatch();

    const { t } = useCustomTranslation();

    return (
        <>
            <div className="pageNotFoundSection">
                <div className="container">
                    <div className="row">
                        <div className="mainContent flex">
                            <div className="col-md-2 col-4">
                                <img src={section_image?.src} />
                            </div>
                            <div className="col-md-4 col-8">
                                <div className="text-muted">{t("constantString.404")}</div>
                                <h3 className="heading">{heading}</h3>
                                <div className="body">{body && parse(body)}</div>

                                <div
                                    className="search cursor-pointer"
                                    onClick={() => dispatch(setOpenSearchModal(true))}
                                >
                                    {t("constantString.trySearch")}
                                </div>

                                <div className="mt-4">
                                    <Link to={"/"}>
                                        <Button className="button">
                                            {t("constantString.backtoHome")}
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PageNotFound;
