import React, { useContext, useRef } from "react";
import "./ArticleDetail.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import { fetchArticle } from "../../redux/layoutSlice";
import Breadcrumbs from "../../components/BreadCrumbs";
import ImageWithObjectFit from "../../components/ImageWrapperComponent";
import { Swiper, SwiperSlide } from "swiper/react";
import PrintSvg from "../../assets/PrintSvg";
import PDFSvg from "../../assets/PDFSvg";
import { WindowContext } from "../../components/WindowResize/resize";
import {
    calculateReadTime,
    parseWithRemoveBrTagsFromBody,
    shareOnWhatsapp,
} from "../../utils/articleHelpers";
import useCustomTranslation from "../../utils/useCustomTranslation";
import { Dropdown } from "primereact/dropdown";
import PhotoGallery from "../../components/PhotoGallery";
import { Navigation, Pagination } from "swiper/modules";
import ReactPlayer from "react-player";
import useGetArticleMeta from "../../utils/useGetArticleMeta";
import { updateFontFamily } from "../../redux/authSlice";
import clsx from "clsx";
import { additionalClassNameFromLanguage } from "../../utils/additionalClassNameFromLanguage";
import MetaTags from "../../utils/metaTagsFunction";
import ShareOnSocialLinksModal from "../../components/ShareOnSocialLinksModal/ShareOnSocialLinksModal";
import useUpdateUrlWithLang from "../../utils/useUpdateUrlWithLang";
import WhatsAppSvg from "../../assets/WhatsAppSvg";
import { useClickOutside } from "../../utils/useClickOutside";
import { getDateToDisplayInArticlePage } from "../../utils/dateFunctions";
import { languageNameCodeMap } from "../../utils/countryLanguageHelpers";

function Articles({ contents, colors: colorObj }) {
    const { articleId: articleIdFromParams } = useParams();

    const id = contents?.[0]?.id || articleIdFromParams;

    const dispatch = useDispatch();

    const modalRef = useRef(null);

    const { isMobile } = useContext(WindowContext);

    const [isLoading, setIsLoading] = useState(!!id);

    const [selectedLanguage, setSelectedLanguage] = useState(null);

    const [currentLangName, setCurrentLangName] = useState();

    const [openSocialLinksModal, setOpenSocialLinksModal] = useState(false);

    const [currentLangDir, setCurrentLangDir] = useState();

    const [selectedLangCode, setSelectedLangCode] = useState();

    const content = useSelector((state) => state.layout.content);

    const fontFamily = useSelector((state) => state.auth.fontFamily);

    const { t } = useCustomTranslation();

    const { updateUrlWithLang } = useUpdateUrlWithLang();

    const {
        title,
        date,
        summary,
        body,
        microsite,
        image_carousel,
        detailImage,
        link,
        region,
        article_pdf,
        translate_languages,
        image_series,
        breadcrumb,
        language,
    } = content[id] || {};

    useEffect(() => {
        if (isLoading && !title) {
            dispatch(fetchArticle({ id }));
        }
        if (isLoading && title) {
            setIsLoading(false);
        }
    }, [isLoading, title]);

    useEffect(() => {
        updateUrlWithLang(id, languageNameCodeMap[language]);
        if (translate_languages) {
            const index = translate_languages.findIndex((e) => e.language === language);
            if (index !== -1) {
                setSelectedLanguage(translate_languages[index]);
            }
            const currentLangCode = translate_languages[index]?.lang_code;
            setCurrentLangName(translate_languages[index]?.language);
            setCurrentLangDir(translate_languages[index]?.direction);
            setSelectedLangCode(currentLangCode);
            dispatch(updateFontFamily(currentLangCode));
        }
    }, [language]);

    useClickOutside(modalRef, () => {
        setOpenSocialLinksModal(false);
    });

    const handleLanguageChange = (e) => {
        dispatch(fetchArticle({ id, lang: e.value }));
        setCurrentLangName(e.value);
        dispatch(updateFontFamily(e.value));
        updateUrlWithLang(id, e.value?.lang_code);
    };

    const { getLocation, getBreadcrumbArray, getTheme } = useGetArticleMeta();
    const location = getLocation(microsite);
    const breadcrumbArray = getBreadcrumbArray(breadcrumb);
    const displayTheme = getTheme(breadcrumb);

    const swiperCarousel = {
        spaceBetween: 10,
        slidesPerView: 1,
        modules: [Pagination, Navigation],
        pagination: { clickable: true, dynamicBullets: true, dynamicMainBullets: 7 },
        navigation: true,
    };

    return (
        <>
            <MetaTags
                pageType="article"
                summary={summary}
                image={image_carousel?.length > 0 ? image_carousel[0]?.src : detailImage?.src}
            />

            <div className="articleMainSecation" style={{ fontFamily }}>
                <div className="container">
                    <div className="row flex justify-center">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <Breadcrumbs data={breadcrumbArray} colorObj={colorObj} />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div
                                className="tagMain mt-4"
                                style={{ color: colorObj?.primary_color }}
                                dir={currentLangDir}
                            >
                                {displayTheme}
                            </div>
                            <h1
                                className="mb-12 mt-2"
                                style={{ color: colorObj?.accent_color1 }}
                                dir={currentLangDir}
                            >
                                {title}
                            </h1>
                            <div className="iconHldr mb-12">
                                <div className="dateMain">
                                    <div
                                        className="dateTitleMain"
                                        style={{
                                            "--accent_color1": colorObj?.accent_color1,
                                        }}
                                    >
                                        <div className="flex">
                                            <h4
                                                className="capitalize"
                                                style={{ color: colorObj?.accent_color1 }}
                                            >
                                                {location}
                                            </h4>
                                        </div>
                                        <div className="dateTime">
                                            <span style={{ color: colorObj?.accent_color3 }}>
                                                {region ? `${region} • ` : ""}
                                                {calculateReadTime(content[id], selectedLangCode)
                                                    ? `${calculateReadTime(
                                                          content[id],
                                                          selectedLangCode,
                                                      )} ${t("constantString.minRead")} • `
                                                    : ""}
                                                {getDateToDisplayInArticlePage(
                                                    date,
                                                    selectedLangCode,
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <ul>
                                    <li
                                        className="cursor-pointer shareIcon"
                                        onClick={() =>
                                            setOpenSocialLinksModal(!openSocialLinksModal)
                                        }
                                    >
                                        <i
                                            className={`pi pi-share-alt me-3 relative top-[1px] shareIcon ${
                                                currentLangDir === "rtl" ? "shareIconRtl" : ""
                                            }`}
                                        ></i>
                                        <span>{t("btnText.share")}</span>
                                        {openSocialLinksModal && (
                                            <div ref={modalRef}>
                                                <ShareOnSocialLinksModal />
                                            </div>
                                        )}
                                    </li>
                                    <li className="cursor-pointer" onClick={() => window.print()}>
                                        <PrintSvg />
                                    </li>
                                    <li
                                        className="cursor-pointer mr-3"
                                        onClick={() => window.open(article_pdf, "_blank")}
                                    >
                                        <PDFSvg />
                                    </li>
                                </ul>

                                {!isMobile ? (
                                    <>
                                        {translate_languages?.length > 1 && (
                                            <Dropdown
                                                options={translate_languages}
                                                optionLabel="language"
                                                value={selectedLanguage}
                                                onChange={handleLanguageChange}
                                                placeholder="language"
                                                unstyled
                                                style={{ "--font_family": fontFamily }}
                                                className="rtl:mr-3"
                                            />
                                        )}
                                    </>
                                ) : (
                                    <div
                                        className="cursor-pointer p-2 shareIcon"
                                        onClick={() =>
                                            setOpenSocialLinksModal(!openSocialLinksModal)
                                        }
                                        style={{ background: colorObj?.accent_color2 }}
                                    >
                                        <i
                                            className={`pi pi-share-alt me-3 relative top-[1px] shareIconRtl ${
                                                currentLangDir === "rtl" ? "shareIconRtl" : ""
                                            }`}
                                        ></i>
                                        <span>{t("btnText.share")}</span>
                                        {openSocialLinksModal && (
                                            <div ref={modalRef}>
                                                <ShareOnSocialLinksModal />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="articleImageMain">
                    <div className="container">
                        {link ? (
                            <div className="row flex justify-center">
                                <div className="col-12 mobile-view">
                                    <ReactPlayer url={link} controls className="video-ratio" />
                                </div>
                                <div className="col-12">
                                    <div
                                        className="imgtext"
                                        style={{ color: colorObj?.accent_color1 }}
                                        dir={currentLangDir}
                                    >
                                        {detailImage && parse(detailImage?.title)}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <Swiper
                                {...swiperCarousel}
                                style={{
                                    "--primary_color": colorObj?.primary_color,
                                    "--accent_color4": colorObj?.accent_color4,
                                }}
                                key={language}
                            >
                                {(image_carousel?.length
                                    ? image_carousel
                                    : [detailImage || {}]
                                )?.map((card) => (
                                    <SwiperSlide key={card?.title}>
                                        <div className="card h-full">
                                            <div className="cardImg">
                                                <ImageWithObjectFit src={card?.src} />
                                            </div>
                                            <div className="card-body flex flex-col">
                                                <h3 dir={currentLangDir}>
                                                    {card?.title && parse(card?.title)}
                                                    <span
                                                        className="ms-1 italic"
                                                        style={{
                                                            color: colorObj?.accent_color3,
                                                        }}
                                                    >
                                                        {card?.credit}
                                                    </span>
                                                </h3>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        )}
                    </div>
                </div>

                {isMobile && (
                    <div className="col-12 mt-4 mb-4">
                        <div className="iconHldr-mobile">
                            <ul className="socialHldr flex items-center mr-auto">
                                <li
                                    className="cursor-pointer mr-5"
                                    onClick={() => shareOnWhatsapp()}
                                >
                                    <WhatsAppSvg />
                                </li>
                                <li
                                    className="mr-5"
                                    onClick={() => navigator.share({ title, url: "" })}
                                >
                                    <PrintSvg />
                                </li>
                                <li
                                    className="mr-5"
                                    onClick={() => window.open(article_pdf, "_blank")}
                                >
                                    <PDFSvg />
                                </li>
                            </ul>
                            {translate_languages?.length > 1 && (
                                <Dropdown
                                    options={translate_languages}
                                    optionLabel="language"
                                    value={selectedLanguage}
                                    onChange={handleLanguageChange}
                                    placeholder="language"
                                    unstyled
                                    style={{ "--font_family": fontFamily }}
                                    className="rtl:mr-3"
                                />
                            )}
                        </div>
                    </div>
                )}

                {!!summary && (
                    <div
                        className={clsx(
                            "titleSecation",
                            additionalClassNameFromLanguage(currentLangName),
                        )}
                        dir={currentLangDir}
                    >
                        <div className="container">
                            <div className="row flex justify-center">
                                <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                                    <div
                                        className="summary"
                                        style={{
                                            color: colorObj?.accent_color1,
                                            "--primary_color": colorObj?.primary_color,
                                        }}
                                    >
                                        {parse(summary || "")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {body !== "" && (
                    <div
                        className={clsx(
                            "articleTextSecation",
                            additionalClassNameFromLanguage(currentLangName),
                        )}
                        dir={currentLangDir}
                        style={{ "--primary_color": colorObj?.primary_color }}
                    >
                        <div className="container">
                            <div className="row flex justify-center">
                                <div className="col-lg-10 col-md-12 col-sm-12 col-12">
                                    <div>
                                        {parseWithRemoveBrTagsFromBody(
                                            body || "",
                                            colorObj?.primary_color,
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {image_series !== null && image_series?.length > 0 && (
                    <PhotoGallery image_series={image_series} />
                )}
            </div>
        </>
    );
}

export default Articles;
