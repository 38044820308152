import React, { useEffect, useState } from "react";
import "./MediaArticles.css";
import BreadCrumbs from "../../components/BreadCrumbs";
import PrintSvg from "../../assets/PrintSvg";
import PDFSvg from "../../assets/PDFSvg";
import { Button } from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { fetchArticle } from "../../redux/layoutSlice";
import moment from "moment";
import parse from "html-react-parser";
import ImageWithObjectFit from "../../components/ImageWrapperComponent";
import { useLocation } from "react-router-dom";
import Loader from "../../components/Loader/loader";
import { Dialog } from "primereact/dialog";
import ReactPlayer from "react-player";
import { Accordion, AccordionTab } from "primereact/accordion";
import { getPosterImage } from "../../utils/posterImageFunction";
import useCustomTranslation from "../../utils/useCustomTranslation";

function MediaArticles() {
    const l = useLocation();

    const { t } = useCustomTranslation();

    const { pathname } = useLocation();

    const idFromPath = pathname.slice(pathname.lastIndexOf("/") + 1);

    const id = l.state?.nid ? l.state?.nid : idFromPath;

    const [didDispatch, setDidDispatch] = useState(false);

    const content = useSelector((state) => state.layout.content);

    const [openPhotoDialog, setOpenPhotoDialog] = useState(false);

    const [openVideoDialog, setOpenVideoDialog] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!didDispatch) {
            dispatch(fetchArticle({ id }));
            setDidDispatch(true);
        }
    }, [content, didDispatch]);

    const {
        title,
        date,
        summary,
        article_media,
        body,
        microsite,
        article_media_download_url,
        article_document_download_url,
        article_video_download_url,
        article_photograph_download_url,
    } = content[id] || {};

    const [documentName, setDocumentName] = useState("");

    const [showFullBody, setShowFullBody] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [visiblePhotos, setVisiblePhotos] = useState(3);

    const [visibleVideos, setVisibleVideos] = useState(3);

    const [visibleDocuments, setVisibleDocuments] = useState(3);

    const [visibleTimeCodes, setVisibleTimeCodes] = useState(3);

    const [photographsObj, setPhotographsObj] = useState();

    const [videosObj, setVideosObj] = useState();

    const [posterUrl, setPosterUrl] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            let posterArr = [];
            if (article_media && article_media.video_links) {
                for await (const iterator of article_media.video_links) {
                    const image = await getPosterImage(iterator.src);
                    posterArr.push({
                        ...iterator,
                        posterImage: image,
                    });
                }
            }
            setPosterUrl(posterArr);
        };
        fetchData();
    }, [article_media]);

    useEffect(() => {
        if (article_media) {
            for (const iterator of article_media.documents) {
                const urlParams = new URLSearchParams(iterator.src);
                const myParam = urlParams.get("name");
                setDocumentName(myParam);
            }
        }
    }, [article_media]);

    const downloadMediaZip = () => {
        if (article_media_download_url) {
            setIsLoading(true);
            let link = document.createElement("a");
            link.setAttribute("download", `article_media_${id}.zip`);
            link.href = article_media_download_url;
            document.body.appendChild(link);
            link.click();
            link.remove();
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        }
    };

    const downloadZip = (categoryType) => {
        let downloadUrl;
        let fileName;

        if (categoryType === t("mediaArticleString.documents")) {
            downloadUrl = article_document_download_url;
            fileName = `article_documents_${id}.zip`;
        } else if (categoryType === t("mediaArticleString.photographs")) {
            downloadUrl = article_photograph_download_url;
            fileName = `article_photographs_${id}.zip`;
        } else if (categoryType === t("mediaArticleString.videos")) {
            downloadUrl = article_video_download_url;
            fileName = `article_videos_${id}.zip`;
        }

        if (downloadUrl) {
            setIsLoading(true);
            let link = document.createElement("a");
            link.setAttribute("download", fileName);
            link.href = downloadUrl;
            document.body.appendChild(link);
            link.click();
            link.remove();
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        }
    };

    const downloadSingleAttachment = (url) => {
        if (url) {
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "photo.jpg");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const renderTruncatedBody = () => {
        const truncatedContent = body?.slice(0, 1000);
        const truncatedWithEllipsis = truncatedContent + (body && body.length > 1000 ? "..." : "");
        return parse(truncatedWithEllipsis || "");
    };

    const renderSummary = () => {
        return showFullBody ? parse(body || "") : renderTruncatedBody();
    };

    const toggleShowFullBody = () => {
        setShowFullBody(!showFullBody);
    };

    return (
        <>
            <Loader isLoading={isLoading} />

            <div className="mediaArticleMainSection">
                <div className="container">
                    <div className="row mb-12">
                        <div className="col-md-7">
                            <div className="col-12">
                                <BreadCrumbs lableName="Article" />
                                <h3 className="mb-6 mt-4">{title}</h3>
                                <div className="sub-title mb-6">{parse(summary || "")}</div>
                                <div className="iconHldr">
                                    <div className="dateMain">
                                        <div className="dateTitleMain">
                                            <h4>{microsite}</h4>
                                            <div className="dateTime">
                                                <span className="mr-4">
                                                    {moment(date).format(`MMM DD${","} YYYY`)}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="icon-btn">
                                        <i className="fa fa-share-alt mr-2" aria-hidden="true"></i>
                                        {t("mediaArticleString.share")}
                                    </div>
                                    <ul>
                                        <li>
                                            <PrintSvg />
                                        </li>
                                        <li>
                                            <PDFSvg />
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="summary-section">
                                <div className="col-12 mt-4">
                                    <div className="summary">{renderSummary()}</div>

                                    {body?.length > 1000 && (
                                        <div className="more-text mt-4">
                                            {showFullBody
                                                ? t("mediaArticleString.see_less")
                                                : t("mediaArticleString.see_more")}
                                            <i
                                                className={`fa ${
                                                    !showFullBody
                                                        ? "fa-chevron-down"
                                                        : "fa-chevron-up"
                                                } ml-2 more-icon cursor-pointer`}
                                                onClick={toggleShowFullBody}
                                                aria-hidden="true"
                                            ></i>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <>
                            <div className="col-md-5 sidebySidePanel">
                                <div className="col-12">
                                    <div className="lg:flex">
                                        <div className="titleMain">
                                            <h3>{t("mediaArticleString.attachments")}</h3>
                                        </div>
                                        <div className="ml-auto md:mt-4 lg:mt-0">
                                            <Button
                                                className="download-button max-w-auto px-6"
                                                onClick={() => downloadMediaZip()}
                                            >
                                                <i
                                                    className="fa fa-download mr-2 download-icon"
                                                    aria-hidden="true"
                                                ></i>
                                                {t("mediaArticleString.download_attachments")}
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                                {article_media?.documents.length > 0 && (
                                    <>
                                        <div className="col-12">
                                            <div className="tagSection">
                                                <div className="tagName flex w-full">
                                                    <span>{t("mediaArticleString.Documents")}</span>
                                                    <div
                                                        className="download-Text cursor-pointer"
                                                        onClick={() => downloadZip("documents")}
                                                    >
                                                        <i
                                                            className="fa fa-download ml-6 mr-2 download-icon"
                                                            aria-hidden="true"
                                                        ></i>
                                                        {t("mediaArticleString.downloadAll")}
                                                    </div>
                                                </div>
                                            </div>
                                            {article_media?.documents &&
                                                article_media?.documents
                                                    .slice(0, visibleDocuments)
                                                    .map((value, index) => (
                                                        <div className="flex mb-4 mt-4" key={index}>
                                                            <PDFSvg />
                                                            <div
                                                                className="ml-4 documentName cursor-pointer"
                                                                onClick={() =>
                                                                    downloadSingleAttachment(
                                                                        value.download,
                                                                    )
                                                                }
                                                            >
                                                                {documentName}
                                                            </div>
                                                        </div>
                                                    ))}
                                        </div>
                                        <div className="col-12">
                                            <div className="mt-4 flex">
                                                {article_media?.documents &&
                                                    article_media?.documents.length > 3 &&
                                                    visibleDocuments !==
                                                        article_media?.documents.length && (
                                                        <div className="more-text">
                                                            {t("mediaArticleString.see_more")}
                                                            <i
                                                                className="fa fa-chevron-down ml-2 download-icon cursor-pointer"
                                                                onClick={() =>
                                                                    setVisibleDocuments(
                                                                        article_media?.documents
                                                                            .length,
                                                                    )
                                                                }
                                                                aria-hidden="true"
                                                            ></i>
                                                        </div>
                                                    )}

                                                {article_media?.documents &&
                                                    visibleDocuments > 3 && (
                                                        <div className="more-text">
                                                            {t("mediaArticleString.see_less")}
                                                            <i
                                                                className="fa fa-chevron-down ml-2 download-icon cursor-pointer"
                                                                onClick={() =>
                                                                    setVisibleDocuments(3)
                                                                }
                                                                aria-hidden="true"
                                                            ></i>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </>
                                )}

                                {article_media?.photographs.length > 0 && (
                                    <div className="col-12">
                                        <div className="tagSection mb-4">
                                            <div className="tagName flex w-full">
                                                <span>{t("mediaArticleString.Photographs")}</span>
                                                <div
                                                    className="download-Text cursor-pointer"
                                                    onClick={() => downloadZip("photographs")}
                                                >
                                                    <i
                                                        className="fa fa-download ml-6 mr-2 download-icon"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {t("mediaArticleString.downloadAll")}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            {article_media?.photographs &&
                                                article_media?.photographs
                                                    .slice(0, visiblePhotos)
                                                    .map((value, index) => (
                                                        <div className="col-md-4" key={index}>
                                                            <div className="card h-full">
                                                                <div
                                                                    className="cardImg"
                                                                    onClick={() => {
                                                                        setPhotographsObj(value);
                                                                        setOpenPhotoDialog(true);
                                                                    }}
                                                                >
                                                                    <ImageWithObjectFit
                                                                        src={value.src}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                            <div className="col-12">
                                                <div className="mt-4 flex">
                                                    {article_media?.photographs &&
                                                        article_media?.photographs.length > 3 &&
                                                        visiblePhotos !==
                                                            article_media?.photographs.length && (
                                                            <div className="more-text">
                                                                {t("mediaArticleString.see_more")}
                                                                <i
                                                                    className="fa fa-chevron-down ml-2 download-icon cursor-pointer"
                                                                    onClick={() =>
                                                                        setVisiblePhotos(
                                                                            article_media
                                                                                ?.photographs
                                                                                .length,
                                                                        )
                                                                    }
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </div>
                                                        )}

                                                    {article_media?.photographs &&
                                                        visiblePhotos > 3 && (
                                                            <div className="more-text">
                                                                {t("mediaArticleString.see_less")}
                                                                <i
                                                                    className="fa fa-chevron-down ml-2 download-icon cursor-pointer"
                                                                    onClick={() =>
                                                                        setVisibleVideos(3)
                                                                    }
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {posterUrl.length > 0 && (
                                    <div className="col-12">
                                        <div className="tagSection mb-4">
                                            <div className="tagName flex w-full">
                                                <span>{t("mediaArticleString.VideoLinks")}</span>
                                            </div>
                                        </div>

                                        <div className="row">
                                            {posterUrl &&
                                                posterUrl
                                                    .slice(0, visibleVideos)
                                                    .map((value, index) => (
                                                        <div className="col-md-4" key={index}>
                                                            <div className="card h-full">
                                                                <div
                                                                    className="cardImg"
                                                                    onClick={() => {
                                                                        setVideosObj(value);
                                                                        setOpenVideoDialog(true);
                                                                    }}
                                                                >
                                                                    <ImageWithObjectFit
                                                                        src={value.posterImage}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                            <div className="col-12">
                                                <div className="mt-4 flex">
                                                    {posterUrl &&
                                                        posterUrl.length > 3 &&
                                                        visibleVideos !== posterUrl.length && (
                                                            <div className="more-text">
                                                                {t("mediaArticleString.see_more")}
                                                                <i
                                                                    className="fa fa-chevron-down ml-2 download-icon cursor-pointer"
                                                                    onClick={() =>
                                                                        setVisibleVideos(
                                                                            posterUrl.length,
                                                                        )
                                                                    }
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </div>
                                                        )}

                                                    {posterUrl && visibleVideos > 3 && (
                                                        <div className="more-text">
                                                            {t("mediaArticleString.see_less")}
                                                            <i
                                                                className="fa fa-chevron-down ml-2 download-icon cursor-pointer"
                                                                onClick={() => setVisibleVideos(3)}
                                                                aria-hidden="true"
                                                            ></i>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="col-12 mt-12 GetTouchAccordion">
                                <Accordion>
                                    <AccordionTab header={t("mediaArticleString.attachments")}>
                                        <div className="accordion-body">
                                            <div className="ml-auto">
                                                <Button
                                                    className="download-button max-w-auto px-6"
                                                    onClick={() => downloadMediaZip()}
                                                >
                                                    <i
                                                        className="fa fa-download mr-2 download-icon"
                                                        aria-hidden="true"
                                                    ></i>
                                                    {t("mediaArticleString.download_attachments")}
                                                </Button>
                                            </div>
                                            {article_media?.documents.length > 0 && (
                                                <>
                                                    <div className="col-12">
                                                        <div className="tagSection">
                                                            <div className="tagName">
                                                                {t("mediaArticleString.Documents")}
                                                            </div>
                                                        </div>
                                                        {article_media?.photographs &&
                                                            article_media?.photographs
                                                                .slice(0, visiblePhotos)
                                                                .map((value, index) => (
                                                                    <div
                                                                        className="flex mb-4 mt-4"
                                                                        key={index}
                                                                    >
                                                                        <PDFSvg />
                                                                        <div
                                                                            className="ml-4 documentName cursor-pointer"
                                                                            onClick={() =>
                                                                                downloadSingleAttachment(
                                                                                    value.download,
                                                                                )
                                                                            }
                                                                        >
                                                                            {documentName}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="mt-4 flex">
                                                            {article_media?.documents &&
                                                                article_media?.documents.length >
                                                                    3 &&
                                                                visibleDocuments !==
                                                                    article_media?.documents
                                                                        .length && (
                                                                    <div className="more-text">
                                                                        {t(
                                                                            "mediaArticleString.see_more",
                                                                        )}
                                                                        <i
                                                                            className="fa fa-chevron-down ml-2 download-icon cursor-pointer"
                                                                            onClick={() =>
                                                                                setVisibleDocuments(
                                                                                    article_media
                                                                                        ?.documents
                                                                                        .length,
                                                                                )
                                                                            }
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </div>
                                                                )}

                                                            {article_media?.documents &&
                                                                visibleDocuments > 3 && (
                                                                    <div className="more-text">
                                                                        {t(
                                                                            "mediaArticleString.see_less",
                                                                        )}
                                                                        <i
                                                                            className="fa fa-chevron-up ml-2 download-icon cursor-pointer"
                                                                            onClick={() =>
                                                                                setVisibleDocuments(
                                                                                    3,
                                                                                )
                                                                            }
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            {article_media?.photographs.length > 0 && (
                                                <div className="col-12">
                                                    <div className="tagSection mb-4">
                                                        <div className="tagName flex w-full">
                                                            <span>
                                                                {t(
                                                                    "mediaArticleString.Photographs",
                                                                )}
                                                            </span>
                                                            <div
                                                                className="download-Text cursor-pointer"
                                                                onClick={() =>
                                                                    downloadZip("photographs")
                                                                }
                                                            >
                                                                <i
                                                                    className="fa fa-download ml-6 mr-2 download-icon"
                                                                    aria-hidden="true"
                                                                ></i>
                                                                {t(
                                                                    "mediaArticleString.downloadAll",
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        {article_media?.photographs &&
                                                            article_media?.photographs
                                                                .slice(0, visiblePhotos)
                                                                .map((value, index) => (
                                                                    <div
                                                                        className="col-4"
                                                                        key={index}
                                                                    >
                                                                        <div className="card h-full">
                                                                            <div
                                                                                className="cardImg"
                                                                                onClick={() => {
                                                                                    setPhotographsObj(
                                                                                        value,
                                                                                    );
                                                                                    setOpenPhotoDialog(
                                                                                        true,
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <ImageWithObjectFit
                                                                                    src={value.src}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                        <div className="col-12">
                                                            <div className="mt-4 flex">
                                                                {article_media?.photographs &&
                                                                    article_media?.photographs
                                                                        .length > 3 &&
                                                                    visiblePhotos !==
                                                                        article_media?.photographs
                                                                            .length && (
                                                                        <div className="more-text">
                                                                            {t(
                                                                                "mediaArticleString.see_more",
                                                                            )}
                                                                            <i
                                                                                className="fa fa-chevron-down ml-2 download-icon cursor-pointer"
                                                                                onClick={() =>
                                                                                    setVisiblePhotos(
                                                                                        article_media
                                                                                            ?.photographs
                                                                                            .length,
                                                                                    )
                                                                                }
                                                                                aria-hidden="true"
                                                                            ></i>
                                                                        </div>
                                                                    )}

                                                                {article_media?.photographs &&
                                                                    visiblePhotos > 3 && (
                                                                        <div className="more-text">
                                                                            {t(
                                                                                "mediaArticleString.see_less",
                                                                            )}
                                                                            <i
                                                                                className="fa fa-chevron-up ml-2 download-icon cursor-pointer"
                                                                                onClick={() =>
                                                                                    setVisibleVideos(
                                                                                        3,
                                                                                    )
                                                                                }
                                                                                aria-hidden="true"
                                                                            ></i>
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {posterUrl.length > 0 && (
                                                <div className="col-12">
                                                    <div className="tagSection mb-4">
                                                        <div className="tagName flex w-full">
                                                            <span>
                                                                {t("mediaArticleString.VideoLinks")}
                                                            </span>
                                                            <div
                                                                className="download-Text cursor-pointer"
                                                                onClick={() =>
                                                                    downloadZip("video_links")
                                                                }
                                                            >
                                                                <i
                                                                    className="fa fa-download ml-6 mr-2 download-icon"
                                                                    aria-hidden="true"
                                                                ></i>
                                                                {t(
                                                                    "mediaArticleString.downloadAll",
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        {posterUrl &&
                                                            posterUrl
                                                                .slice(0, visibleVideos)
                                                                .map((value, index) => (
                                                                    <div
                                                                        className="col-4"
                                                                        key={index}
                                                                    >
                                                                        <div className="card h-full">
                                                                            <div
                                                                                className="cardImg"
                                                                                onClick={() => {
                                                                                    setVideosObj(
                                                                                        value,
                                                                                    );
                                                                                    setOpenVideoDialog(
                                                                                        true,
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <ImageWithObjectFit
                                                                                    src={
                                                                                        value.posterImage
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                        <div className="col-12">
                                                            <div className="mt-4 flex">
                                                                {posterUrl &&
                                                                    posterUrl.length > 3 &&
                                                                    visibleVideos !==
                                                                        posterUrl.length && (
                                                                        <div className="more-text">
                                                                            {t(
                                                                                "mediaArticleString.see_more",
                                                                            )}
                                                                            <i
                                                                                className="fa fa-chevron-down ml-2 download-icon cursor-pointer"
                                                                                onClick={() =>
                                                                                    setVisibleVideos(
                                                                                        posterUrl.length,
                                                                                    )
                                                                                }
                                                                                aria-hidden="true"
                                                                            ></i>
                                                                        </div>
                                                                    )}

                                                                {posterUrl && visibleVideos > 3 && (
                                                                    <div className="more-text">
                                                                        {t(
                                                                            "mediaArticleString.see_less",
                                                                        )}
                                                                        <i
                                                                            className="fa fa-chevron-up ml-2 download-icon cursor-pointer"
                                                                            onClick={() =>
                                                                                setVisibleVideos(3)
                                                                            }
                                                                            aria-hidden="true"
                                                                        ></i>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </AccordionTab>
                                </Accordion>
                            </div>
                        </>
                    </div>
                </div>

                <Dialog
                    visible={openPhotoDialog}
                    style={{ width: "50vw" }}
                    onHide={() => {
                        if (!openPhotoDialog) return;
                        setOpenPhotoDialog(false);
                    }}
                    draggable={false}
                    breakpoints={{ "960px": "75vw", "641px": "100vw", "500px": "92vw" }}
                >
                    <div className="media-dialog-main">
                        <ImageWithObjectFit src={photographsObj?.src} />
                        <span
                            className="download-txt cursor-pointer mt-1"
                            onClick={() => downloadSingleAttachment(photographsObj.download)}
                        >
                            <i
                                className="fa fa-download ml-6 mr-2 mt-1 download-modal-icon"
                                aria-hidden="true"
                            ></i>
                            {t("mediaArticleString.download")}
                        </span>
                        <span className="img-caption">{photographsObj?.title}</span>

                        <span className="body">{photographsObj?.caption}</span>
                    </div>
                </Dialog>

                <Dialog
                    visible={openVideoDialog}
                    style={{ width: "50vw" }}
                    onHide={() => {
                        if (!openVideoDialog) return;
                        setOpenVideoDialog(false);
                    }}
                    draggable={false}
                    breakpoints={{ "960px": "75vw", "641px": "92vw", "500px": "92vw" }}
                >
                    <div className="media-dialog-main">
                        <div style={{ maxWidth: "100%" }}>
                            <ReactPlayer
                                url={videosObj?.src}
                                controls
                                width={"100%"}
                                height={"100%"}
                            />
                        </div>
                        {videosObj?.upload_file !== "" && (
                            <span
                                className="download-txt cursor-pointer"
                                onClick={() => downloadSingleAttachment(videosObj?.download)}
                            >
                                <Button
                                    className="download-button max-w-auto px-6"
                                    onClick={() => downloadMediaZip()}
                                >
                                    {t("mediaArticleString.single_download_videos")}
                                    <i
                                        className="fa fa-chevron-down ml-2 download-icon"
                                        aria-hidden="true"
                                    ></i>
                                </Button>
                            </span>
                        )}

                        <span className="img-caption">{videosObj?.title}</span>

                        <span className="body">{videosObj?.caption}</span>

                        <hr />

                        <div className="timecodemain">
                            <span className="timecodes">{t("mediaArticleString.timeCode")}</span>
                            <div className="">
                                {videosObj?.timecode &&
                                    videosObj?.timecode.slice(0, visibleTimeCodes).map((v, i) => (
                                        <div key={i}>
                                            <div>
                                                <span className="dateTime">
                                                    {v.start_time} - {v.end_time}{" "}
                                                </span>
                                                <span className="timecodedesc ml-4">
                                                    {v.description}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                            </div>

                            <div className="col-12">
                                <div className="mt-4 flex">
                                    {videosObj?.timecode &&
                                        videosObj?.timecode.length > 3 &&
                                        visibleTimeCodes !== videosObj?.timecode.length && (
                                            <div className="more-text">
                                                {t("mediaArticleString.see_more")}
                                                <i
                                                    className="fa fa-chevron-down ml-2 see-more-icon cursor-pointer"
                                                    onClick={() =>
                                                        setVisibleTimeCodes(
                                                            videosObj?.timecode.length,
                                                        )
                                                    }
                                                    aria-hidden="true"
                                                ></i>
                                            </div>
                                        )}

                                    {videosObj?.timecode && visibleTimeCodes > 3 && (
                                        <div className="more-text">
                                            {t("mediaArticleString.see_less")}
                                            <i
                                                className="fa fa-chevron-up ml-2 see-more-icon cursor-pointer"
                                                onClick={() => setVisibleTimeCodes(3)}
                                                aria-hidden="true"
                                            ></i>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </div>
        </>
    );
}

export default MediaArticles;
