import React, { useCallback, useEffect, useState } from "react";
import "./TextList.css";
import { shallowEqual, useSelector } from "react-redux";
import { decodeHtmlEntities } from "../../utils/urlUtils";
import { useGetLink } from "../../utils/useOpenLink";
import CustomLink from "../../utils/customLink";
import { useGetDateToDisplay } from "../../utils/dateFunctions";
import { selectCountryLanguageCodes } from "../../redux/authSlice";

function TextList({ heading, contents: contentToRender = [], colors: colorObj }) {
    const [contentReferenceData, setContentReferenceData] = useState([]);

    const content = useSelector((state) => state.layout.content);

    const { getLink } = useGetLink();

    const getDateToDisplay = useGetDateToDisplay();

    const { languageCode } = useSelector(selectCountryLanguageCodes, shallowEqual);

    const setCurrentContent = useCallback(
        (contentKey) => {
            const respKeys = content[contentKey];
            const resp = respKeys.map((k) => content[k]).slice(0, 3);

            if (resp.length) {
                setContentReferenceData(resp);
            }
        },
        [content],
    );

    useEffect(() => {
        setCurrentContent(contentToRender[0].contentKey);
    }, []);

    return (
        <>
            <section className="txtLinkWrapper py-50">
                <div className="container">
                    <div className="row">
                        <div className="col-12 mb-6">
                            <h2 style={{ "--accent_color1": colorObj?.accent_color1 }}>
                                {heading}
                            </h2>
                            <div
                                className="line"
                                style={{ "--primary_color": colorObj?.primary_color }}
                            ></div>
                        </div>
                        <div className="col-12">
                            {contentReferenceData.map((card) => (
                                <div
                                    className="txtLinkHldr"
                                    key={card.title}
                                    style={{
                                        "--accent_color2": colorObj?.accent_color2,
                                    }}
                                >
                                    <h3 style={{ "--primary_color": colorObj?.primary_color }}>
                                        {card.microsite !== "" ? (
                                            decodeHtmlEntities(card.microsite)
                                        ) : (
                                            <>&nbsp;</>
                                        )}
                                    </h3>
                                    <CustomLink to={getLink(card.path, card.type, card.language)}>
                                        <h2
                                            className="underline"
                                            style={{ "--accent_color1": colorObj?.accent_color1 }}
                                        >
                                            {card.title}
                                        </h2>
                                    </CustomLink>
                                    <div
                                        className="minut"
                                        style={{
                                            "--accent_color3": colorObj?.accent_color3,
                                            "--accent_color1": colorObj?.accent_color1,
                                        }}
                                    >
                                        {card.tags?.split(",")[0]}{" "}
                                        <span>| {getDateToDisplay(card.date, languageCode)}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TextList;
