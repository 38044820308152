import React, { useEffect, useState } from "react";
import "./CountDownWithImage.css";
import parse from "html-react-parser";
import useCustomTranslation from "../../utils/useCustomTranslation";

function CountDownWithImage({
    heading,
    body,
    section_image,
    contents: contentsArray,
    colors: colorObj,
}) {
    const { t } = useCustomTranslation();

    const [timeLeft, setTimeLeft] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    useEffect(() => {
        const targetDate = new Date(contentsArray[0].heading);
        const updateTimer = () => {
            const now = new Date();
            const difference = targetDate - now;

            if (difference > 0) {
                const days = Math.floor(difference / (1000 * 60 * 60 * 24));
                const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((difference % (1000 * 60)) / 1000);

                setTimeLeft({ days, hours, minutes, seconds });
            } else {
                setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
            }
        };

        const timerId = setInterval(updateTimer, 1000);

        return () => clearInterval(timerId);
    }, []);

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="CountdownWrapper py-50">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12 col-12 flex items-center justify-center">
                                    <div className="CountlogoMain">
                                        <img src={section_image?.src} alt=" "></img>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                                    <div className="countDecHldr">
                                        <h2 style={{ "--accent_color1": colorObj?.accent_color1 }}>
                                            {heading}
                                        </h2>
                                        <div
                                            className="line"
                                            style={{ "--primary_color": colorObj?.primary_color }}
                                        ></div>
                                        <p style={{ "--accent_color1": colorObj?.accent_color1 }}>
                                            {body && parse(body)}
                                        </p>
                                    </div>
                                    <div
                                        className="counterBoxHldr"
                                        style={{
                                            "--primary_color": colorObj?.primary_color,
                                            "--accent_color4": colorObj?.accent_color4,
                                        }}
                                    >
                                        <div className="countBoxMain">
                                            <div className="countMain">{timeLeft.days}</div>
                                            <div className="disMain">
                                                {t("constantString.days")}
                                            </div>
                                        </div>
                                        <div className="countBoxMain">
                                            <div className="countMain">{timeLeft.hours}</div>
                                            <div className="disMain">
                                                {t("constantString.hours")}
                                            </div>
                                        </div>
                                        <div className="countBoxMain">
                                            <div className="countMain">{timeLeft.minutes}</div>
                                            <div className="disMain">
                                                {t("constantString.minutes")}
                                            </div>
                                        </div>
                                        <div className="countBoxMain">
                                            <div className="countMain">{timeLeft.seconds}</div>
                                            <div className="disMain">
                                                {t("constantString.seconds")}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CountDownWithImage;
