import ImageWithObjectFit from "../ImageWrapperComponent";
import { decodeHtmlEntities } from "../../utils/urlUtils";
import { calculateReadTime } from "../../utils/articleHelpers";
import { useGetLink } from "../../utils/useOpenLink";
import useCustomTranslation from "../../utils/useCustomTranslation";
import CustomLink from "../../utils/customLink";
import useGetArticleMeta from "../../utils/useGetArticleMeta";
import PlayIconSvg from "../../assets/PlayIconSvg";

export const RelatedCard2 = ({ content, colorsObj }) => {
    const {
        image,
        title: Title,
        path,
        microsite,
        posterImage,
        type,
        link,
        breadcrumb,
        language,
    } = content;

    const { getLink } = useGetLink();

    const { t } = useCustomTranslation();

    const { getTheme, getSection } = useGetArticleMeta();
    const theme = getTheme(breadcrumb);
    const section = getSection(breadcrumb);

    return (
        <div className="newsCard card">
            <div className="cardImg">
                {!!link && (
                    <div
                        className="playIconHldr"
                        style={{
                            "--accent_color4": colorsObj.accent_color4,
                        }}
                    >
                        <PlayIconSvg />
                    </div>
                )}
                <CustomLink to={getLink(path, type, language)} type={type}>
                    <ImageWithObjectFit
                        className="cursor-pointer"
                        src={image?.src || posterImage}
                    />
                </CustomLink>
            </div>
            <div className="card-body flex flex-col text-start">
                <div className="tag-heading mb-2" style={{ color: colorsObj.primary_color }}>
                    {microsite !== "" ? decodeHtmlEntities(microsite) : <>&nbsp;</>}
                </div>
                <CustomLink to={getLink(path, type, language)} type={type}>
                    <div
                        className="title hover:underline cursor-pointer"
                        style={{ color: colorsObj.accent_color1 }}
                    >
                        {Title}
                    </div>
                </CustomLink>
                <div className="flex mt-auto">
                    {theme && (
                        <div
                            className="author ltr:mr-1 rtl:ml-1 capitalize"
                            style={{ color: colorsObj.accent_color1 }}
                        >
                            {section}
                        </div>
                    )}
                    <div className="date" style={{ color: colorsObj.accent_color3 }}>
                        {section && calculateReadTime(content) ? " | " : ""}
                        {calculateReadTime(content)
                            ? `${calculateReadTime(content)} ${t("constantString.minRead")}`
                            : ""}
                    </div>
                </div>
            </div>
        </div>
    );
};
