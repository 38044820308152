import { generatePkceCodePair } from "./oauthHelpers";
import { getBaseUrl, handleOauth } from "./oauthHandler";

export const performLogin = async (oauthDetails) => {
    if (oauthDetails?.authEndpoint) {
        const codeChallenge = await generatePkceCodePair();
        localStorage.setItem("oauthServersDetails", JSON.stringify(oauthDetails));
        handleOauth({
            authEndpoint: oauthDetails.authEndpoint,
            clientId: oauthDetails.clientId,
            state: { link: getBaseUrl().path },
            codeChallenge,
        });
    }
};
