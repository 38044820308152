import React from "react";
import { removeAllTagsFromBody } from "./articleHelpers";

const formatSubjectWithLink = (subject) => {
    if (!subject) return null;

    const [beforeLink, afterLink] = subject.split(/https?:\/\/\S+/);
    const linkMatch = subject.match(/https?:\/\/\S+/);
    const link = linkMatch ? linkMatch[0] : null;

    // Handle preventing event propagation when link is clicked
    const handleLinkClick = (event) => {
        event.stopPropagation(); // Prevents the click event from triggering the parent (details page)
    };

    return (
        <span>
            {beforeLink && removeAllTagsFromBody(beforeLink)}
            {link && (
                <>
                    {" "}
                    <a
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500"
                        onClick={handleLinkClick}
                    >
                        {link}
                    </a>{" "}
                </>
            )}
            {afterLink && removeAllTagsFromBody(afterLink)}
        </span>
    );
};

export default formatSubjectWithLink;
