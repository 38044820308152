import { shallowEqual, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectCountriesLanguageArray, selectCountryLanguageCodes } from "../redux/authSlice";
import {
    DEFAULT_COUNTRY_CODE,
    getValidCountryLanguageCode,
    languageNameCodeMap,
} from "./countryLanguageHelpers";

export const useOpenLink = () => {
    const navigate = useNavigate();

    const openLink = (link, type, nid) => {
        const finalUrl =
            (type === "link_new" || type === "link") && link.startsWith("http")
                ? link
                : type === "link_new" || type === "link"
                ? `https://${link}`
                : link;
        const destinationPath = link || `/${nid}`;
        switch (type?.toLowerCase()) {
            case "article":
                navigate(`${destinationPath}`);
                break;
            case "speech":
                navigate(`/speech`, { state: { id: link } });
                break;
            default:
                finalUrl.startsWith("http")
                    ? window.open(finalUrl, type === "link_new" ? "_blank" : "_self")
                    : navigate(finalUrl);
        }
    };

    return openLink;
};

export const useGetLink = () => {
    const countriesLanguagesArray = useSelector(
        selectCountriesLanguageArray,
        (prev, next) => prev.length === next.length,
    );
    const { countryCode, languageCode } = useSelector(selectCountryLanguageCodes, shallowEqual);

    const getLink = (linkFromProps = "", type, languageFromProps) => {
        // 0. check if external link, return
        // 1. check if link already has a country and/or language
        // 1a. If yes, return link as is
        // 1b. If no, continue
        // 2. Check if type is article or speech, if so, check language.
        // 2a. If language, then return with country code and langauge from article or speech
        // 3. Add country code and language from redux
        if (linkFromProps?.startsWith("http") || !linkFromProps) {
            // link is an absolute link, no need to do anything
            return linkFromProps;
        } else if (linkFromProps?.startsWith("www") || type === "link_new" || type === "link") {
            return `https://${linkFromProps}`;
        }
        const link = linkFromProps.startsWith("/") ? linkFromProps.slice(1) : linkFromProps;
        const [countryCodeFromLink] = link.split("/");

        const { countryCode: validCountryCode } = getValidCountryLanguageCode(
            countriesLanguagesArray,
            countryCodeFromLink,
        );

        if (
            countryCodeFromLink?.toLowerCase() === validCountryCode?.toLowerCase() ||
            countryCode === DEFAULT_COUNTRY_CODE
        ) {
            // link is "fully qualified" with country and possibly language
            return link;
        }

        const langSection =
            languageFromProps && languageNameCodeMap[languageFromProps]
                ? `/${languageNameCodeMap[languageFromProps]}`
                : `/${languageCode?.toLowerCase()}`;

        const linkSection = link && link !== "/" ? `/${link}` : "";
        return `${countryCode?.toLowerCase()}${langSection}${linkSection}`;
    };

    return { getLink };
};

export const useReloadWithCountryLanguage = () => {
    const reloadPage = ({ countryCode, languageCode, id }) => {
        window.location.href = `/${countryCode}/${languageCode}/${id || ""}`;
    };

    return { reloadPage };
};
