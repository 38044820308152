export const ARTICLE_DETAILS = "article_details";
export const BLOCK_DETAILS = "block_details";
export const CONTENT_DETAILS = "content_details";

export const LTR_LANGUAGE_DIRECTION = "ltr";
export const RTL_LANGUAGE_DIRECTION = "rtl";

export const STATUS_READ = 1;
export const STATUS_UNREAD = 0;

export const SORT_ORDER = ["desc", "asc"];
