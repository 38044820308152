import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { oauthData, selectShowModal } from "../../redux/authSlice";
import { Button } from "../Button";
import "./AuthModal.css";
import { Dialog } from "primereact/dialog";
import useCustomTranslation from "../../utils/useCustomTranslation";
import { performLogin } from "../../utils/performLogin";

function AuthModal() {
    const [shouldShowModal, setShouldShowModal] = useState(false);

    const showModal = useSelector(selectShowModal);

    const { t } = useCustomTranslation();

    const oauthDetails = useSelector(oauthData);

    useEffect(() => {
        if (showModal) {
            setTimeout(() => {
                setShouldShowModal(true);
            }, 200);
        } else {
            setShouldShowModal(false);
        }
    }, [showModal]);

    return (
        <>
            {shouldShowModal && (
                <Dialog
                    visible={shouldShowModal}
                    onHide={() => {
                        if (!shouldShowModal) return;
                        setShouldShowModal(false);
                    }}
                    breakpoints={{ "960px": "75vw", "641px": "100vw", "500px": "92vw" }}
                    draggable={false}
                >
                    <div className="loginModal mt-2">
                        <div className="modal-header">
                            <img
                                src="assets/images/not-loggedIn-user.png"
                                alt="Not Logged In"
                            ></img>
                        </div>
                        <div className="modal-body">
                            <h3>{t("unAuthorizedUserString.unAuthorizedText")}</h3>
                            <div className="mt-4 mb-4">
                                <Button
                                    className="button"
                                    onClick={() => performLogin(oauthDetails)}
                                >
                                    {t("unAuthorizedUserString.login")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Dialog>
            )}
        </>
    );
}

export default AuthModal;
