import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./CardCarousel.css";
import { Card } from "../Card/Card";
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid, Navigation, Pagination } from "swiper/modules";
import { Button } from "../Button";
import { useGetLink } from "../../utils/useOpenLink";
import parse from "html-react-parser";
import * as posterImage from "../../utils/usePosterImage";
import CustomLink from "../../utils/customLink";
import useCustomTranslation from "../../utils/useCustomTranslation";
import { WindowContext } from "../WindowResize/resize";

export const CardCarousel = ({
    contents: contentToRender = [],
    heading,
    body,
    colors: colorObj,
    block_buttons,
    number_of_rows,
}) => {
    const [contentReferenceData, setContentReferenceData] = useState([]);

    const content = useSelector((state) => state.layout.content);

    const { getLink } = useGetLink();

    const { isMobile } = useContext(WindowContext);

    const setCurrentContent = useCallback(
        async (contentKey) => {
            const respKeys = content[contentKey];
            let resp = respKeys?.map((k) => content[k]) || [];
            resp = JSON.parse(JSON.stringify(resp));
            for await (const element of resp) {
                if (element && element.link) {
                    element.posterImage = await posterImage.usePosterImage(
                        element?.link,
                        element?.image,
                    );
                }
            }
            if (resp.length) {
                setContentReferenceData(resp);
            }
        },
        [content],
    );

    useEffect(() => {
        setCurrentContent(contentToRender?.[0]?.contentKey || "");
    }, [number_of_rows]);

    const { direction } = useCustomTranslation();

    const swiperCarousel = {
        slidesPerView: 3,
        slidesPerColumn: 3,
        slidesPerGroup: 3,
        navigation: true,
        grid: {
            rows: number_of_rows,
            fill: "row",
        },
        spaceBetween: 30,
        pagination: {
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 7,
        },
        modules: [Grid, Pagination, Navigation],
        breakpoints: {
            345: {
                grid: {
                    rows: 1,
                    fill: "row",
                },
                slidesPerView: 1,
                slidesPerColumn: 1,
                slidesPerGroup: 1,
                spaceBetween: 20,
            },
            576: {
                grid: {
                    rows: 1,
                    fill: "row",
                },
                slidesPerView: 1,
                slidesPerColumn: 1,
                slidesPerGroup: 1,
                spaceBetween: 20,
            },
            676: {
                grid: {
                    rows: 1,
                    fill: "row",
                },
                slidesPerView: 1.5,
                slidesPerColumn: 1,
                slidesPerGroup: 1,
                spaceBetween: 20,
            },
            768: {
                grid: {
                    rows: number_of_rows,
                    fill: "row",
                },
                slidesPerView: 2,
                slidesPerColumn: 2,
                slidesPerGroup: 2,
            },
            1024: {
                grid: {
                    rows: number_of_rows,
                    fill: "row",
                },
                spaceBetween: 20,
                slidesPerView: 3,
                slidesPerColumn: 3,
                slidesPerGroup: 3,
            },
            1280: {
                grid: {
                    rows: number_of_rows,
                    fill: "row",
                },
                spaceBetween: 20,
                slidesPerView: 3,
                slidesPerColumn: 3,
                slidesPerGroup: 3,
            },
        },
    };

    return (
        <div
            className="latestWrapper py-50 text-start"
            style={{ background: colorObj?.background_color }}
        >
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="card-titleMain">
                            <h3
                                style={{
                                    color: colorObj?.accent_color1,
                                    "--primary_color": colorObj?.primary_color,
                                }}
                            >
                                {heading}
                            </h3>
                            <div className="sub-title" style={{ color: colorObj?.accent_color1 }}>
                                {body && parse(body)}
                            </div>
                        </div>

                        <Swiper
                            dir={direction}
                            key={direction}
                            {...swiperCarousel}
                            style={{ "--primary_color": colorObj?.primary_color }}
                        >
                            {isMobile
                                ? Array.from(
                                      {
                                          length: Math.ceil(
                                              contentReferenceData.length / number_of_rows,
                                          ),
                                      },
                                      (_, slideIndex) => (
                                          <SwiperSlide key={slideIndex}>
                                              <div className="grid-container">
                                                  {contentReferenceData
                                                      .slice(
                                                          slideIndex * number_of_rows,
                                                          (slideIndex + 1) * number_of_rows,
                                                      )
                                                      .map((card, index) => (
                                                          <div key={index} className="grid-item">
                                                              <Card
                                                                  content={card}
                                                                  colorsObj={colorObj}
                                                              />
                                                          </div>
                                                      ))}
                                              </div>
                                          </SwiperSlide>
                                      ),
                                  )
                                : contentReferenceData.map((card, index) => (
                                      <SwiperSlide key={index}>
                                          <Card content={card} colorsObj={colorObj} />
                                      </SwiperSlide>
                                  ))}
                        </Swiper>
                    </div>

                    {block_buttons?.length > 0 && (
                        <div className="col-12 flex justify-center">
                            {block_buttons.map((v) => (
                                <CustomLink to={getLink(v.action)} key={v.label}>
                                    <Button
                                        className="button mr-4"
                                        style={{ color: colorObj?.accent_color3 }}
                                    >
                                        {v.label}
                                        <i className="pi pi-arrow-right" aria-hidden="true"></i>
                                    </Button>
                                </CustomLink>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
