import React, { useContext, useEffect, useState } from "react";
import "./GallerySection.css";
import { MultiSelect } from "primereact/multiselect";
import { useDispatch, useSelector } from "react-redux";
import { fetchGalleryData } from "../../redux/gallerySlice";
import { Paginator } from "primereact/paginator";
import parse from "html-react-parser";
import Loader from "../Loader/loader";
import { WindowContext } from "../WindowResize/resize";
import { useLocation, useNavigate } from "react-router-dom";
import { ScrollTop } from "../../utils/scrollTopFunction";
import ImageWithObjectFit from "../ImageWrapperComponent";
import useCustomTranslation from "../../utils/useCustomTranslation";
import { useGetLink } from "../../utils/useOpenLink";
import * as posterImage from "../../utils/usePosterImage";
import CustomLink from "../../utils/customLink";
import PlayIconSvg from "../../assets/PlayIconSvg";
import { InputText } from "primereact/inputtext";

function GallerySection({ contents: contentsArray, colors: colorObj }) {
    const { pathname, search } = useLocation();

    const { t } = useCustomTranslation();

    const { getLink } = useGetLink();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { isMobile } = useContext(WindowContext);

    const content = useSelector((state) => state.gallery.contents);

    const isLoading = useSelector((state) => state.gallery.isLoading);

    const dynamicFilters = useSelector((state) => state.gallery.dynamicFilters);

    const [articleDetails, setArticleDetails] = useState([]);

    const [totalPageCount, setTotalPageCount] = useState(0);

    const [searchKeyword, setSearchKeyword] = useState("");

    const [selectedFilters, setSelectedFilters] = useState({});

    const [paramsReq, setParamsReq] = useState({
        page: 0,
        page_size: 10,
    });

    useEffect(() => {
        const queryParams = new URLSearchParams(search);

        const req = { ...paramsReq };
        const selectedFilters = {};

        queryParams.forEach((value, key) => {
            if (key.startsWith("search_") && !key.startsWith("search_text")) {
                const filterKey = key.slice(7); // Remove 'search_' from key
                const filterValues = value ? value.split(",") : [];

                if (filterValues.length > 0) {
                    let saveItems = [];
                    filterValues.forEach((item) => {
                        if (item !== "") {
                            saveItems.push({ itemName: decodeURIComponent(item) });
                        }
                    });

                    selectedFilters[filterKey] = saveItems;
                    req[`search_${filterKey}`] = filterValues;
                }
            } else if (key === "search_text") {
                setSearchKeyword(value);
                req.search_text = value;
            } else {
                req[key] = value;
            }
        });

        setSelectedFilters(selectedFilters);
        setParamsReq(req);
    }, [search]);

    useEffect(() => {
        if (Object.keys(paramsReq).length > 0) {
            let reqObj = { ...paramsReq };
            reqObj.page = reqObj.page + 1;
            const apiLink = contentsArray[0].contentKey.slice(0, -1);
            dispatch(fetchGalleryData({ reqObj, apiLink }));
        }
    }, [paramsReq, dispatch, contentsArray]);

    useEffect(() => {
        const queryParams = [];

        for (const key in paramsReq) {
            if (
                paramsReq[key] &&
                key.startsWith("search_") &&
                !key.startsWith("search_text") &&
                paramsReq[key].length > 0
            ) {
                queryParams.push(`${key}=${paramsReq[key].map(encodeURIComponent).join(",")}`);
            }
        }

        if (searchKeyword !== "") {
            queryParams.push(`search_text=${encodeURIComponent(searchKeyword)}`);
        }

        navigate(`/${pathname.slice(1)}?${queryParams.join("&")}`);
    }, [paramsReq, navigate, searchKeyword, pathname]);

    const fetchGalleryDetails = async () => {
        setArticleDetails([]);
        setTotalPageCount(0);

        if (content) {
            let articleArr = [];
            articleArr = articleArr.concat(content.articles);
            articleArr = JSON.parse(JSON.stringify(articleArr));
            for await (const element of articleArr) {
                if (element && element.link) {
                    element.posterImage = await posterImage.usePosterImage(element?.link);
                }
            }
            setArticleDetails(articleArr);
            setTotalPageCount(content.total);
        }
    };

    useEffect(() => {
        fetchGalleryDetails();
    }, [content, isLoading]);

    const onPageChange = (event) => {
        setParamsReq({ ...paramsReq, page_size: event.rows, page: event.page });
        ScrollTop();
    };

    const onChangeSelectFilter = (value, filterName) => {
        let arr = [];
        let arrWithItemName = [];

        for (const iterator of value) {
            let str = encodeURIComponent(iterator.itemName);
            arr.push(str);
            arrWithItemName.push({ itemName: iterator.itemName });
        }

        const key = `search_${filterName.toLowerCase()}`;

        setParamsReq((prevState) => ({
            ...prevState,
            [key]: arr,
            page: 0,
        }));
        setSelectedFilters((prev) => ({
            ...prev,
            [filterName]: arrWithItemName,
        }));
    };

    const clearSearch = () => {
        setParamsReq({
            ...paramsReq,
            search_text: "",
        });
        setSearchKeyword("");
    };

    return (
        <>
            <Loader isLoading={isLoading} />

            <section className="gallerySection liteGreenBg padTop padBottom">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="boxWrapper marBottom">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="search-hldr">
                                            <i className="pi pi-search"></i>
                                            {searchKeyword !== "" && (
                                                <span
                                                    className="cursor-pointer"
                                                    onClick={clearSearch}
                                                >
                                                    <i className="search1 pi pi-times"></i>
                                                </span>
                                            )}
                                            <InputText
                                                type="text"
                                                placeholder={t("placeHolder.search")}
                                                onChange={(e) => {
                                                    setSearchKeyword(e.target.value);
                                                }}
                                                value={searchKeyword}
                                                className="ml-2"
                                            />
                                        </div>
                                    </div>

                                    {Object.keys(dynamicFilters).map(
                                        (filterKey) =>
                                            dynamicFilters[filterKey].Items &&
                                            dynamicFilters[filterKey].Items.length > 0 && (
                                                <div className="col-md-3" key={filterKey}>
                                                    <div className="card flex justify-center">
                                                        <MultiSelect
                                                            placeholder={
                                                                dynamicFilters[filterKey].Label
                                                            }
                                                            value={selectedFilters[filterKey]}
                                                            onChange={(e) =>
                                                                onChangeSelectFilter(
                                                                    e.value,
                                                                    filterKey,
                                                                )
                                                            }
                                                            options={
                                                                dynamicFilters[filterKey].Items ||
                                                                []
                                                            }
                                                            optionLabel="itemName"
                                                            maxSelectedLabels={
                                                                dynamicFilters[filterKey].Items
                                                                    ? dynamicFilters[filterKey]
                                                                          .Items.length
                                                                    : 3
                                                            }
                                                            className="w-full md:w-20rem"
                                                            filter
                                                        />
                                                    </div>
                                                </div>
                                            ),
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mb-4">
                            <div className="row">
                                {articleDetails.length > 0 && (
                                    <>
                                        {articleDetails.map((value, index) => (
                                            <div className="col-md-4 padding" key={index}>
                                                {value && (
                                                    <div className="newsCard">
                                                        <div className="dateName">
                                                            <span className="date">
                                                                {value && value.date
                                                                    ? value.date
                                                                    : ""}
                                                            </span>
                                                        </div>
                                                        <CustomLink
                                                            to={getLink(
                                                                value.path,
                                                                value.type,
                                                                value.language,
                                                            )}
                                                        >
                                                            <div className="cardImg">
                                                                {!!value.link && (
                                                                    <div
                                                                        className="playIconHldr"
                                                                        style={{
                                                                            "--accent_color4":
                                                                                colorObj.accent_color4,
                                                                        }}
                                                                    >
                                                                        <PlayIconSvg />
                                                                    </div>
                                                                )}
                                                                <ImageWithObjectFit
                                                                    className="cursor-pointer"
                                                                    src={
                                                                        value?.image?.src ||
                                                                        value?.posterImage
                                                                    }
                                                                />
                                                            </div>
                                                        </CustomLink>
                                                        <div className="sub-card-body">
                                                            <CustomLink
                                                                to={getLink(
                                                                    value.path,
                                                                    value.type,
                                                                    value.language,
                                                                )}
                                                            >
                                                                <h3 className="underline">
                                                                    {value && value.title
                                                                        ? value.title
                                                                        : ""}
                                                                </h3>
                                                            </CustomLink>
                                                            <div className="textMain">
                                                                {value && value.summary
                                                                    ? parse(value.summary)
                                                                    : ""}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>

                        {articleDetails.length > 0 && (
                            <div>
                                <Paginator
                                    first={paramsReq.page * paramsReq.page_size}
                                    rows={paramsReq.page_size}
                                    totalRecords={totalPageCount}
                                    onPageChange={onPageChange}
                                    pageLinkSize={isMobile ? 1 : 5}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
}

export default GallerySection;
