import clsx from "clsx";
import React from "react";
import CustomLink from "../../utils/customLink";

function MobileLinkGroup({ index, value, openLink, handleToggleCollapse, expanded }) {
    return (
        <div className="mb-4" key={index}>
            <div
                className="flex flex-row justify-between text-primary_color text-xs py-2 border-solid border-b border-primary_color cursor-pointer"
                onClick={() => handleToggleCollapse(index)}
            >
                <h2
                    id={`${index}`}
                    className="text-xs uppercase "
                    aria-expanded="true"
                    aria-controls={`collapse${index}`}
                >
                    {value.heading}
                </h2>
                {expanded ? (
                    <i className="pi pi-angle-up"></i>
                ) : (
                    <i className="pi pi-angle-down"></i>
                )}
            </div>
            <div
                id={`collapse${index}`}
                className={clsx("mt-2 select-none text-start", { hidden: !expanded })}
                aria-labelledby={`collapse${index}`}
            >
                <ul>
                    {value.contents &&
                        value.contents.map((v) => (
                            <CustomLink
                                to={openLink(v.content_reference)}
                                type={v.type}
                                key={v.heading}
                            >
                                <li
                                    key={v.heading}
                                    className="cursor-pointer text-primary_color text-xs py-2 uppercase"
                                >
                                    {v.heading}
                                </li>
                            </CustomLink>
                        ))}
                </ul>
            </div>
        </div>
    );
}

export default MobileLinkGroup;
