import React, { useRef, useState } from "react";
import "./ProgrammeStdMenu.css";
import { useGetLink } from "../../utils/useOpenLink";
import CustomLink from "../../utils/customLink";
import clsx from "clsx";
import { useOpenMenu } from "../../utils/useOpenMenu";

function ProgrammeStdMenu({ contents: contentsArray = [], colors: colorObj }) {
    const { getLink } = useGetLink();

    const [collapsedIndexes, setCollapsedIndexes] = useState([]);

    const { menuRef, handleClick, handleMouseEnter, handleMouseLeave, activeIndex } = useOpenMenu();

    const menuWrapperRef = useRef(null);

    const handleToggleCollapse = (index) => {
        setCollapsedIndexes((prevState) =>
            prevState.includes(index)
                ? prevState.filter((i) => i !== index)
                : [...prevState, index],
        );
    };

    const MenuWrapper = ({ link, children }) => {
        return link ? (
            <CustomLink to={getLink(link)}>{children}</CustomLink>
        ) : (
            <div>{children}</div>
        );
    };

    return (
        <section
            className="prog-link-wrapper"
            style={{
                borderTop: `1px solid ${colorObj?.accent_color2}`,
                borderBottom: `1px solid ${colorObj?.accent_color2}`,
            }}
        >
            <div className="container">
                <div className="row">
                    <div className="col-12 p-0">
                        <div ref={menuRef} className="desktop-std-menu1">
                            {contentsArray &&
                                contentsArray.map((value, index) => {
                                    return (
                                        <div
                                            key={value.heading}
                                            className="nav-item cursor-pointer"
                                            onClick={() => {
                                                if (value.contents?.length > 0) handleClick(index);
                                            }}
                                            onMouseEnter={() => {
                                                if (value.contents?.length > 0)
                                                    handleMouseEnter(index);
                                            }}
                                            onMouseLeave={() => {
                                                if (value.contents?.length > 0) handleMouseLeave();
                                            }}
                                        >
                                            <MenuWrapper
                                                link={
                                                    !value.contents?.length
                                                        ? value.content_reference
                                                        : null
                                                }
                                            >
                                                <span
                                                    className={
                                                        activeIndex === index ? "active" : ""
                                                    }
                                                    style={{
                                                        color: colorObj?.primary_color,
                                                        "--link-hover-color":
                                                            colorObj?.primary_color,
                                                        "--primary_color": colorObj?.primary_color,
                                                    }}
                                                >
                                                    {value.heading}
                                                    {value.contents?.length > 0 && (
                                                        <i
                                                            className={`${
                                                                !value.isActive
                                                                    ? "pi pi-angle-down ltr:ml-2 rtl:mr-2"
                                                                    : "pi pi-angle-up ltr:ml-2 rtl:mr-2"
                                                            }`}
                                                        ></i>
                                                    )}
                                                </span>
                                            </MenuWrapper>

                                            {value.contents?.length > 0 && (
                                                <ul
                                                    className={clsx(
                                                        "dropdown-menu",
                                                        activeIndex === index ? "block" : "hidden",
                                                    )}
                                                    aria-labelledby="MainNav1"
                                                    style={{
                                                        "--primary_color": colorObj?.primary_color,
                                                        "--accent_color2": colorObj?.accent_color2,
                                                    }}
                                                >
                                                    {value.contents.map((subValue, i) => (
                                                        <li key={i}>
                                                            <CustomLink
                                                                to={getLink(
                                                                    subValue.content_reference,
                                                                )}
                                                                type={subValue.type}
                                                                key={i}
                                                            >
                                                                <div className="dropdown-item">
                                                                    {subValue.heading}
                                                                </div>
                                                            </CustomLink>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    );
                                })}
                        </div>

                        <div ref={menuWrapperRef} className="mobile-std-menu">
                            <div className="navMainWrapper">
                                {contentsArray.map((value, index) => (
                                    <span
                                        key={value.heading}
                                        className="nav-item cursor-pointer"
                                        onClick={() => handleToggleCollapse(index)}
                                    >
                                        <MenuWrapper
                                            link={
                                                !value.contents?.length
                                                    ? value.content_reference
                                                    : null
                                            }
                                        >
                                            <span
                                                className={
                                                    collapsedIndexes?.includes(index)
                                                        ? "active"
                                                        : ""
                                                }
                                                style={{
                                                    color: colorObj?.primary_color,
                                                    "--link-hover-color": colorObj?.primary_color,
                                                    "--primary_color": colorObj?.primary_color,
                                                }}
                                            >
                                                {value.heading}
                                                {value.contents?.length > 0 && (
                                                    <i
                                                        className={`${
                                                            collapsedIndexes?.includes(index)
                                                                ? "pi pi-angle-up ltr:ml-2 rtl:mr-2"
                                                                : "pi pi-angle-down ltr:ml-2 rtl:mr-2"
                                                        }`}
                                                    ></i>
                                                )}
                                            </span>
                                        </MenuWrapper>
                                        {value.contents?.length > 0 && (
                                            <ul
                                                className={clsx(
                                                    "dropdown-menu",
                                                    collapsedIndexes?.includes(index)
                                                        ? "block"
                                                        : "hidden",
                                                )}
                                                style={{
                                                    "--primary_color": colorObj?.primary_color,
                                                    "--accent_color2": colorObj?.accent_color2,
                                                }}
                                            >
                                                {value.contents.map((subValue, i) => (
                                                    <li key={i}>
                                                        <CustomLink
                                                            to={getLink(subValue.content_reference)}
                                                            type={subValue.type}
                                                            key={i}
                                                        >
                                                            <div className="dropdown-item">
                                                                {subValue.heading}
                                                            </div>
                                                        </CustomLink>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProgrammeStdMenu;
