import moment from "moment";
import React from "react";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import ImageWithObjectFit from "../ImageWrapperComponent";
import PlayIconSvg from "../../assets/PlayIconSvg";

function TagCard({ content, colorsObj }) {
    const navigate = useNavigate();

    const { image, title, author, date, summary, nid, posterImage, link } = content;

    const handleClick = () => navigate(`/articles/${nid}`);

    return (
        <div className="newsCard card">
            <div className="cardImg">
                {!!link && (
                    <div
                        className="playIconHldr"
                        style={{
                            "--accent_color4": colorsObj.accent_color4,
                        }}
                    >
                        <PlayIconSvg />
                    </div>
                )}
                <ImageWithObjectFit
                    className="cursor-pointer"
                    src={image?.src || posterImage}
                    onClick={handleClick}
                />
            </div>
            <div className="card-body flex flex-col text-start">
                <h3 className="underline" onClick={handleClick}>
                    {title}
                </h3>
                <div className="date">
                    {author ? author : ""}
                    {author && date ? " | " : ""}
                    {date ? moment(date).format(`MMM DD${","} YYYY`) : ""}
                </div>
                <div className="textMain">{summary ? parse(summary) : ""}</div>
            </div>
        </div>
    );
}

export default TagCard;
