import useCustomTranslation from "./useCustomTranslation";

const localeMap = new Map([
    ["ar", "ar-EG"],
    ["en", "en-GB"],
    ["ur", "ur-PK"],
]);

const getLocaleDateCode = (languageCode) => localeMap.get(languageCode) || "en-GB";

export const useGetDateToDisplay = () => {
    const { t } = useCustomTranslation();

    const getDateToDisplay = (date, languageCode = "en") => {
        if (!date) return date;

        const targetDate = new Date(date);
        const now = new Date();
        const daysDifference = Math.floor((now - targetDate) / (1000 * 60 * 60 * 24));

        if (daysDifference > 45 || targetDate > now) {
            return targetDate
                .toLocaleDateString(getLocaleDateCode(languageCode), {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                })
                .replace(",", "");
        }

        return `${daysDifference} ${
            daysDifference === 1 ? t("constantString.day_ago") : t("constantString.days_ago")
        }`;
    };

    return getDateToDisplay;
};

export const getDateToDisplayInArticlePage = (date, languageCode = "en") => {
    if (!date) return date;

    const targetDate = new Date(date);

    return targetDate
        .toLocaleDateString(getLocaleDateCode(languageCode), {
            day: "2-digit",
            month: "short",
            year: "numeric",
        })
        .replace(",", "");
};
