import React, { useContext, useEffect, useState } from "react";
import "./JobSpotListing.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { jobSpotListing } from "../../redux/jobSpotSlice";
import { MultiSelect } from "primereact/multiselect";
import { Paginator } from "primereact/paginator";
import { ScrollTop } from "../../utils/scrollTopFunction";
import { WindowContext } from "../WindowResize/resize";
import moment from "moment/moment";
import useCustomTranslation from "../../utils/useCustomTranslation";
import MoneySvg from "../../assets/MoneySvg";
import { InputText } from "primereact/inputtext";

function JobSpotListing({ colors: colorObj }) {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { t } = useCustomTranslation();

    const jobList = useSelector((state) => state.jobSpot.jobList);

    const dynamicFilters = useSelector((state) => state.jobSpot.dynamicFilters);

    const isLoading = useSelector((state) => state.gallery.isLoading);

    const { isMobile } = useContext(WindowContext);

    const [selectedFilters, setSelectedFilters] = useState({});

    const [jobDetails, setJobDetails] = useState([]);

    const [totalPageCount, setTotalPageCount] = useState(0);

    const [searchKeyword, setSearchKeyword] = useState("");

    const [prevPage, setPrevPage] = useState(0);

    const [paramsReq, setParamsReq] = useState({
        page: 0,
        page_size: 8,
    });

    useEffect(() => {
        if (paramsReq.page !== prevPage) {
            ScrollTop();
            setPrevPage(paramsReq.page);
        }
    }, [paramsReq.page]);

    useEffect(() => {
        setJobDetails([]);
        setTotalPageCount(0);
        if (jobList) {
            let jobArr = [];
            jobArr = jobArr.concat(jobList.jobs);
            setJobDetails(jobArr);
            setTotalPageCount(jobList.total);
        }
    }, [jobList, isLoading, searchKeyword]);

    useEffect(() => {
        if (Object.keys(paramsReq).length > 0) {
            let reqObj = { ...paramsReq };
            reqObj.page = reqObj.page + 1;
            dispatch(jobSpotListing(reqObj));
        }
    }, [paramsReq, dispatch]);

    const onChangeSelectFilter = (value, filterName) => {
        let arr = [];
        let arrWithItemName = [];

        for (const iterator of value) {
            let str = encodeURIComponent(iterator.itemName);
            arr.push(str);
            arrWithItemName.push({ itemName: iterator.itemName });
        }

        const key = `search_${filterName.toLowerCase()}`;

        setParamsReq((prevState) => ({
            ...prevState,
            [key]: arr,
            page: 0,
        }));
        setSelectedFilters((prev) => ({
            ...prev,
            [filterName]: arrWithItemName,
        }));
    };

    const handleSearchChange = (e) => {
        setSearchKeyword(e.target.value);
        setParamsReq((prevState) => ({
            ...prevState,
            search_text: e.target.value,
            page: 0,
        }));
    };

    const onPageChange = (event) => {
        setParamsReq({ ...paramsReq, page_size: event.rows, page: event.page });
    };

    const clearSearch = () => {
        setParamsReq({
            ...paramsReq,
            search_text: "",
        });
        setSearchKeyword("");
    };

    return (
        <>
            <div className="jobMainWrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="jobSearchFilter">
                                <div
                                    className="col-lg-6 col-md-6 col-sm-12 col-12"
                                    style={{
                                        "--accent_color3": colorObj?.accent_color3,
                                        "--accent_color2": colorObj?.accent_color2,
                                        "--accent_color1": colorObj?.accent_color1,
                                    }}
                                >
                                    <div className="form-group">
                                        <div className="search-hldr">
                                            {searchKeyword !== "" && (
                                                <span
                                                    className="cursor-pointer"
                                                    onClick={clearSearch}
                                                >
                                                    <i className="search1 pi pi-times"></i>
                                                </span>
                                            )}
                                            <i className="pi pi-search absolute top-[16px] left-[15px] z-[10]"></i>
                                            <InputText
                                                type="text"
                                                value={searchKeyword}
                                                onChange={(e) => {
                                                    handleSearchChange(e);
                                                }}
                                                placeholder={t("placeHolder.findJob")}
                                                className="w-full"
                                                style={{
                                                    "--accent_color2": colorObj?.accent_color2,
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {Object.keys(dynamicFilters).map(
                                    (filterKey) =>
                                        dynamicFilters[filterKey].Items &&
                                        dynamicFilters[filterKey].Items.length > 0 && (
                                            <div
                                                className="col-lg-3 col-md-3 col-sm-12 col-12"
                                                key={filterKey}
                                            >
                                                <MultiSelect
                                                    placeholder={dynamicFilters[filterKey].Label}
                                                    value={selectedFilters[filterKey]}
                                                    onChange={(e) =>
                                                        onChangeSelectFilter(e.value, filterKey)
                                                    }
                                                    options={dynamicFilters[filterKey].Items || []}
                                                    optionLabel="itemName"
                                                    maxSelectedLabels={
                                                        dynamicFilters[filterKey].Items
                                                            ? dynamicFilters[filterKey].Items.length
                                                            : 3
                                                    }
                                                    className="w-full md:w-20rem"
                                                    filter
                                                    style={{
                                                        "--accent_color2": colorObj?.accent_color2,
                                                    }}
                                                />
                                            </div>
                                        ),
                                )}
                            </div>
                        </div>
                        <div className="col-12 jobSearchBoxWrapper">
                            <div className="row">
                                {jobDetails?.length > 0 && (
                                    <>
                                        {jobDetails?.map((v, i) => (
                                            <div
                                                className="col-lg-6 col-md-6 col-sm-12 col-12 mb-6"
                                                key={i}
                                            >
                                                <div className="jobBoxHldr">
                                                    <h5
                                                        style={{
                                                            "--primary_color":
                                                                colorObj?.primary_color,
                                                        }}
                                                    >
                                                        {v?.title}
                                                    </h5>
                                                    <div className="txt-main-hldr">
                                                        <div
                                                            className="jobDag"
                                                            style={{
                                                                "--accent_color1":
                                                                    colorObj?.accent_color1,
                                                            }}
                                                        >
                                                            {v?.sector}
                                                        </div>
                                                        <div className="timeLocHldr">
                                                            {v?.contract_type && (
                                                                <span
                                                                    className="tagMain"
                                                                    style={{
                                                                        "--accent_color1":
                                                                            colorObj?.accent_color1,
                                                                        "--accent_color2":
                                                                            colorObj?.accent_color2,
                                                                    }}
                                                                >
                                                                    <i className="pi pi-clock"></i>
                                                                    {v?.contract_type}
                                                                </span>
                                                            )}
                                                            {v?.salary && (
                                                                <span
                                                                    className="tagMain"
                                                                    style={{
                                                                        "--accent_color1":
                                                                            colorObj?.accent_color1,
                                                                        "--accent_color2":
                                                                            colorObj?.accent_color2,
                                                                    }}
                                                                >
                                                                    <span className="mr-1">
                                                                        <MoneySvg />
                                                                    </span>
                                                                    {v?.salary}
                                                                </span>
                                                            )}
                                                            {v?.location && (
                                                                <span
                                                                    className="tagMain"
                                                                    style={{
                                                                        "--accent_color1":
                                                                            colorObj?.accent_color1,
                                                                        "--accent_color2":
                                                                            colorObj?.accent_color2,
                                                                    }}
                                                                >
                                                                    <i className="pi pi-map-marker"></i>
                                                                    {v?.location}
                                                                </span>
                                                            )}
                                                        </div>

                                                        {v?.expiry_date && (
                                                            <div
                                                                className="applicationDate"
                                                                style={{
                                                                    "--accent_color1":
                                                                        colorObj?.accent_color1,
                                                                }}
                                                            >
                                                                <span className="mr-1">
                                                                    {t(
                                                                        "constantString.applicationDeadline",
                                                                    )}
                                                                </span>
                                                                {moment(v?.expiry_date).format(
                                                                    "DD/MM/YYYY",
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="btnMain">
                                                        <button
                                                            type="button"
                                                            className="applyBtn"
                                                            style={{
                                                                "--primary_color":
                                                                    colorObj?.primary_color,
                                                                "--accent_color4":
                                                                    colorObj?.accent_color4,
                                                            }}
                                                            onClick={() =>
                                                                navigate(`/jobspot-details`, {
                                                                    state: { jobDetails: v },
                                                                })
                                                            }
                                                        >
                                                            {t("instantSearchString.apply")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>

                        {jobDetails?.length > 0 && (
                            <div
                                style={{
                                    "--primary_color": colorObj?.primary_color,
                                    "--accent_color4": colorObj?.accent_color4,
                                }}
                            >
                                <Paginator
                                    first={paramsReq.page * paramsReq.page_size}
                                    rows={paramsReq.page_size}
                                    totalRecords={totalPageCount}
                                    onPageChange={onPageChange}
                                    pageLinkSize={isMobile ? 1 : 5}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
export default JobSpotListing;
