import parse from "html-react-parser";

const localeMap = new Map([
    ["ar", "ar-EG"],
    ["en", "en-GB"],
    ["ur", "ur-PK"],
]);

const getLocaleNumberCode = (languageCode) => localeMap.get(languageCode) || "en-GB";

export const calculateReadTime = (content, lang = "en") => {
    let minutes = "";
    const { title = "", summary = "", body = "" } = content || {};
    const text = [title, summary, body].join(" ");
    const words = text ? text.split(/\s+/).length : 0;
    minutes = Math.ceil(words / 200);

    return new Intl.NumberFormat(getLocaleNumberCode(lang)).format(minutes);
};

export const shareOnWhatsapp = () => {
    window.open(`https://api.whatsapp.com/send?text=${window.location.href}`, "_blank");
};

export const shareOnInstagram = () => {
    window.open(`https://www.instagram.com?text=${window.location.href}`, "_blank");
};

export const articleType = "article";

export const speechType = "speech";

export const parseWithRemoveBrTagsFromClass = (bodyContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(bodyContent, "text/html");

    const elements = doc.querySelectorAll(".media.media-element-container.media-media_full_width");

    elements.forEach((element) => {
        const brTags = element.querySelectorAll("br");
        brTags.forEach((br) => br.remove());
    });

    return parse(doc.body.innerHTML);
};

export const parseWithRemoveBrTagsFromBody = (bodyContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(bodyContent, "text/html");

    const brTags = doc.querySelectorAll("br");

    brTags.forEach((br) => {
        if (br.nextElementSibling && br.nextElementSibling.tagName === "br") {
            br.remove();
        }
    });

    const aTags = doc.querySelectorAll("a");
    aTags.forEach((a) => {
        // a.style.color = primary_color; // Set color
        a.style.color = "#007041";
        a.style.textDecoration = "underline"; // Add underline
    });

    return parse(doc.body.innerHTML);
};

export const removeAllTagsFromBody = (bodyContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(bodyContent, "text/html");

    // Extract the text content to remove all HTML tags
    const plainText = doc.body.textContent || "";

    return plainText.trim();
};
