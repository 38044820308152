import React from "react";

const ShareboxWhatsApp = () => {
    return (
        <svg
            width="24"
            height="29"
            viewBox="0 0 24 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 4.5C6.477 4.5 2 8.977 2 14.5C2 16.39 2.525 18.16 3.438 19.668L2.546 22.7C2.49478 22.8741 2.49141 23.0587 2.53624 23.2346C2.58107 23.4104 2.67245 23.5709 2.80076 23.6992C2.92907 23.8276 3.08958 23.9189 3.26542 23.9638C3.44125 24.0086 3.62592 24.0052 3.8 23.954L6.832 23.062C8.39068 24.0051 10.1782 24.5025 12 24.5C17.523 24.5 22 20.023 22 14.5C22 8.977 17.523 4.5 12 4.5ZM9.738 16.763C11.761 18.785 13.692 19.052 14.374 19.077C15.411 19.115 16.421 18.323 16.814 17.404C16.8636 17.2897 16.8816 17.1641 16.8661 17.0405C16.8507 16.9168 16.8023 16.7996 16.726 16.701C16.178 16.001 15.437 15.498 14.713 14.998C14.5618 14.8935 14.3761 14.8516 14.1947 14.881C14.0133 14.9105 13.8503 15.009 13.74 15.156L13.14 16.071C13.1085 16.1202 13.0593 16.1555 13.0026 16.1696C12.9459 16.1837 12.8859 16.1756 12.835 16.147C12.428 15.914 11.835 15.518 11.409 15.092C10.983 14.666 10.611 14.1 10.402 13.719C10.3761 13.6706 10.3686 13.6144 10.3809 13.5609C10.3932 13.5074 10.4245 13.4602 10.469 13.428L11.393 12.742C11.5249 12.6273 11.61 12.4682 11.6321 12.2949C11.6542 12.1215 11.6118 11.9461 11.513 11.802C11.065 11.146 10.543 10.312 9.786 9.759C9.68831 9.6882 9.57386 9.64406 9.45393 9.63091C9.334 9.61776 9.21271 9.63606 9.102 9.684C8.182 10.078 7.386 11.088 7.424 12.127C7.449 12.809 7.716 14.74 9.738 16.763Z"
                fill="#828282"
            />
        </svg>
    );
};

export default ShareboxWhatsApp;
