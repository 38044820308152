export const additionalClassNameFromLanguage = (language) => {
    switch (language) {
        case "Tajik":
            return "tajik-language-body";
        case "Urdu":
            return "urdu-language-body-line-height";
        default:
            return "";
    }
};
