import React from "react";
import "./LocalFailover.css";

function LocalFailover() {
    return (
        <>
            <div className="flex flex-col md:flex-row mt-24 md:mt-48 w-full justify-center items-center">
                <div style={{ maxWidth: 100 }} className="p-2">
                    <img src="logo512.png" />
                </div>
                <div className="notFound-txt mt-auto mb-auto p-2">
                    <h2 className="pb-4 text-center md:text-start ">
                        Page Temporarily Unavailable
                    </h2>
                    <p className="">Please try going back to the previous page</p>
                </div>
            </div>
        </>
    );
}

export default LocalFailover;
