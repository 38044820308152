import React from "react";
import { shareOnInstagram, shareOnWhatsapp } from "../../utils/articleHelpers";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import ShareboxWhatsApp from "../../assets/ShareboxWhatsApp";
import ShareboxTwitter from "../../assets/ShareboxTwitter";
import ShareboxInstagram from "../../assets/ShareboxInstagram";
import ShareboxFacebook from "../../assets/ShareboxFacebook";

function ShareOnSocialLinks() {
    return (
        <ul className="socialHldr">
            <li className="cursor-pointer" onClick={() => shareOnWhatsapp()}>
                <ShareboxWhatsApp />
            </li>
            <li>
                <TwitterShareButton url={window.location.href}>
                    <ShareboxTwitter />
                </TwitterShareButton>
            </li>
            <li className="cursor-pointer" onClick={() => shareOnInstagram()}>
                <ShareboxInstagram />
            </li>
            <li>
                <FacebookShareButton url={window.location.href}>
                    <ShareboxFacebook />
                </FacebookShareButton>
            </li>
        </ul>
    );
}

export default ShareOnSocialLinks;
