import React, { useContext, useEffect, useRef, useState } from "react";
import "./IconTextLinks.css";
import { WindowContext } from "../WindowResize/resize";
import { Dropdown } from "primereact/dropdown";
import { useGetLink } from "../../utils/useOpenLink";
import useCustomTranslation from "../../utils/useCustomTranslation";
import parse from "html-react-parser";
import CustomLink from "../../utils/customLink";

function IconTextLinks({ contents: contentsArray = [], heading, colors: colorObj, body }) {
    const [iconListDetails, setIconListDetails] = useState([]);

    const [selectedIndex, setSelectedIndex] = useState(0);

    const [selectedHeading, setSelectedHeading] = useState(contentsArray[0]?.heading || "");

    const { isDesktop } = useContext(WindowContext);

    const { t } = useCustomTranslation();

    const { getLink } = useGetLink();

    const tabsRef = useRef(null);

    const [showLeftArrow, setShowLeftArrow] = useState(false);

    const [showRightArrow, setShowRightArrow] = useState(false);

    useEffect(() => {
        if (contentsArray.length > 0) {
            setSelectedHeading(contentsArray[0].heading);
            setIconListDetails(contentsArray[0].contents || []);
        }
    }, [contentsArray]);

    const updateScrollButtons = () => {
        if (tabsRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = tabsRef.current;
            setShowLeftArrow(scrollLeft > 0);
            setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
        }
    };

    useEffect(() => {
        updateScrollButtons();
        window.addEventListener("resize", updateScrollButtons);
        return () => window.removeEventListener("resize", updateScrollButtons);
    }, [contentsArray]);

    const scrollTabs = (direction) => {
        if (tabsRef.current) {
            tabsRef.current.scrollBy({ left: direction * 150, behavior: "smooth" });
        }
    };

    const handleTabChange = (index) => {
        setSelectedIndex(index);
        setSelectedHeading(contentsArray[index].heading);
        setIconListDetails(contentsArray[index].contents || []);
    };

    return (
        <div
            className="iconTextLinksWrapper py-50"
            style={{ background: colorObj?.background_color }}
        >
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="titleMain">
                            <div
                                className="icon-line"
                                style={{ background: colorObj?.primary_color }}
                            ></div>
                            <h3>{heading}</h3>
                        </div>
                        {body && (
                            <div className="sub-title" style={{ color: colorObj?.accent_color1 }}>
                                {parse(body)}
                            </div>
                        )}
                        <div className="line" style={{ color: colorObj?.accent_color2 }}>
                            <hr />
                        </div>
                    </div>

                    {isDesktop ? (
                        <div className="col-12">
                            <div className="tabs-container">
                                {showLeftArrow && (
                                    <i
                                        className="pi pi-angle-left cursor-pointer arrowMain arrowLeft"
                                        onClick={() => scrollTabs(-1)}
                                        style={{ "--primary_color": colorObj?.primary_color }}
                                    ></i>
                                )}

                                <div
                                    className="tabs-wrapper"
                                    ref={tabsRef}
                                    onScroll={updateScrollButtons}
                                >
                                    {contentsArray.length > 1 && (
                                        <>
                                            {contentsArray.map((tab, index) => (
                                                <div
                                                    key={index}
                                                    className={`tab ${
                                                        index === selectedIndex ? "active" : ""
                                                    }`}
                                                    onClick={() => handleTabChange(index)}
                                                    style={{
                                                        "--primary_color": colorObj?.primary_color,
                                                    }}
                                                >
                                                    {tab.heading}
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>

                                {showRightArrow && (
                                    <i
                                        className="pi pi-angle-right cursor-pointer arrowMain arrowRight"
                                        onClick={() => scrollTabs(1)}
                                        style={{ "--primary_color": colorObj?.primary_color }}
                                    ></i>
                                )}
                            </div>

                            <div className="col-12">
                                {iconListDetails.length > 0 ? (
                                    <ul
                                        className="linkListHldr"
                                        style={{
                                            "--accent_color1": colorObj?.accent_color1,
                                            "--accent_color3": colorObj?.accent_color3,
                                            "--primary_color": colorObj?.primary_color,
                                            "--accent_color4": colorObj?.accent_color4,
                                            "--background_color": colorObj?.background_color,
                                        }}
                                    >
                                        {iconListDetails.map((v) => (
                                            <li className="cursor-pointer" key={v.heading}>
                                                <CustomLink
                                                    to={getLink(v.content_reference)}
                                                    type={v.type}
                                                >
                                                    <i>
                                                        <img
                                                            src={v?.sub_content_image?.src}
                                                            alt={v.heading}
                                                        />
                                                    </i>
                                                    <p
                                                        style={{
                                                            "--accent_color1":
                                                                colorObj?.accent_color1,
                                                        }}
                                                    >
                                                        {v.heading}
                                                    </p>
                                                </CustomLink>
                                            </li>
                                        ))}
                                        {contentsArray?.map((tab) => (
                                            <>
                                                {tab?.content_buttons?.length > 0 && (
                                                    <>
                                                        {tab?.content_buttons.map((btn) => (
                                                            <li
                                                                key={btn.label}
                                                                className="cursor-pointer"
                                                            >
                                                                <CustomLink
                                                                    to={getLink(btn.action)}
                                                                >
                                                                    <i className="pi pi-arrow-right more-icon"></i>
                                                                    <p>{btn.label}</p>
                                                                </CustomLink>
                                                            </li>
                                                        ))}
                                                    </>
                                                )}
                                            </>
                                        ))}
                                    </ul>
                                ) : (
                                    <h3 className="text">{t("constantString.noRecords")}</h3>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className="col-12">
                            {contentsArray.length > 1 && (
                                <Dropdown
                                    value={selectedHeading}
                                    options={contentsArray.map((content) => content.heading)}
                                    onChange={(e) =>
                                        handleTabChange(
                                            contentsArray.findIndex((c) => c.heading === e.value),
                                        )
                                    }
                                    placeholder={t("placeHolder.selectTag")}
                                    className="customDropdown"
                                    panelStyle={{
                                        "--accent_color2": colorObj?.accent_color2,
                                        "--primary_color": colorObj?.primary_color,
                                    }}
                                    style={{
                                        border: `1px solid ${colorObj?.primary_color}`,
                                        "--accent_color1": colorObj?.accent_color1,
                                    }}
                                />
                            )}

                            {iconListDetails.length > 0 ? (
                                <ul className="linkListHldr">
                                    {iconListDetails.map((v) => (
                                        <li key={v.heading}>
                                            <CustomLink
                                                to={getLink(v.content_reference)}
                                                type={v.type}
                                            >
                                                <i>
                                                    <img
                                                        src={v?.sub_content_image?.src}
                                                        alt={v.heading}
                                                    />
                                                </i>
                                                <p
                                                    style={{
                                                        "--accent_color1": colorObj?.accent_color1,
                                                    }}
                                                >
                                                    {v.heading}
                                                </p>
                                            </CustomLink>
                                        </li>
                                    ))}
                                    {contentsArray.map((tab) => (
                                        <>
                                            {tab?.content_buttons?.length > 0 && (
                                                <>
                                                    {tab?.content_buttons.map((btn) => (
                                                        <li
                                                            key={btn.label}
                                                            className="cursor-pointer"
                                                        >
                                                            <CustomLink to={getLink(btn.action)}>
                                                                <i className="pi pi-arrow-right more-icon"></i>
                                                                <p>{btn.label}</p>
                                                            </CustomLink>
                                                        </li>
                                                    ))}
                                                </>
                                            )}
                                        </>
                                    ))}
                                </ul>
                            ) : (
                                <h3 className="text">{t("constantString.noRecords")}</h3>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default IconTextLinks;
