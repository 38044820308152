import React, { useEffect, useState } from "react";
import ImageWithObjectFit from "../ImageWrapperComponent";
import { Paginator } from "primereact/paginator";
import "./SearchResult.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchSearchResultData } from "../../redux/searchSlice";
import parse from "html-react-parser";
import Loader from "../Loader/loader";
import useCustomTranslation from "../../utils/useCustomTranslation";

function SearchResult() {
    const dispatch = useDispatch();

    const [articleDetails, setArticleDetails] = useState([]);

    const [bindArticleDetails, setBindArticleDetails] = useState([]);

    const articles = useSelector((state) => state.search.articles);

    const isLoading = useSelector((state) => state.search.isLoading);

    const [totalPageCount, setTotalPageCount] = useState(0);

    const { t } = useCustomTranslation();

    const queryParams = new URLSearchParams(window.location.search);
    const searchParams = queryParams.get("q");

    const [paginationReq, setPaginationReq] = useState({
        pageSize: 6,
        pageNumber: 0,
    });

    useEffect(() => {
        if (searchParams !== "" && searchParams.length >= 3) {
            dispatch(fetchSearchResultData({ search: searchParams }));
        }
    }, [searchParams]);

    useEffect(() => {
        fetchArticles();
    }, [articles]);

    useEffect(() => {
        if (articleDetails.length > 0) {
            pagination();
        }
    }, [articleDetails]);

    useEffect(() => {
        pagination();
    }, [paginationReq]);

    const fetchArticles = () => {
        setArticleDetails(articles);
        setBindArticleDetails(articles);
        setTotalPageCount(articles.length);
    };

    const onPageChange = (event) => {
        setPaginationReq({ ...paginationReq, pageSize: event.rows, pageNumber: event.page });
    };

    const pagination = () => {
        const pageSize = paginationReq.pageSize;
        const page = paginationReq.pageNumber + 1;
        const data = articleDetails.slice(pageSize * (page - 1), pageSize * page);
        setBindArticleDetails(data);
    };

    return (
        <>
            <Loader isLoading={isLoading} />

            <section className="searchResultSection">
                <div className="container">
                    <div className="row">
                        {bindArticleDetails.map((v) => (
                            <div className="col-lg-6 col-md-6 col-12 mb-4" key={v.title}>
                                <div className="boxMain card">
                                    <div className="row">
                                        <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                            <div className="ImgMain">
                                                <ImageWithObjectFit
                                                    className="cursor-pointer"
                                                    src={v.image.src}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-7 col-md-7 col-sm-5">
                                            <div className="BodyTxt">
                                                <div className="TitleMain underline">{v.title}</div>
                                                <p className="mb-2">{parse(v.summary)}</p>
                                            </div>
                                            <div className="date">{v.date}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {bindArticleDetails.length == 0 && (
                            <h3 className="text">{t("constantString.noRecords")}</h3>
                        )}
                    </div>
                    {bindArticleDetails.length > 0 && (
                        <div>
                            <Paginator
                                first={paginationReq.pageNumber * paginationReq.pageSize}
                                rows={paginationReq.pageSize}
                                totalRecords={totalPageCount}
                                onPageChange={onPageChange}
                            />
                        </div>
                    )}
                </div>
            </section>
        </>
    );
}

export default SearchResult;
