import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { decodeString } from "../utils/objEncoder";
import {
    getAuthToken,
    getBaseUrl,
    removeAuthToken,
    removeIdToken,
    storeAuthToken,
    storeIdToken,
} from "../utils/oauthHandler";
import { getCodeVerifier, removeCodeVerifier } from "../utils/oauthHelpers";
import {
    getValidCountryLanguageCode,
    getCountryLanguageInfoLocalStorage,
    setCountryInfoLocalStorage,
    setLanguageInfoLocalStorage,
    setRegionInfoLocalStorage,
} from "../utils/countryLanguageHelpers";
import { CONFIGS_LINK } from "../constants/appLinks";
import fonts from "../utils/fonts";
import { fetchStaticTranslateData } from "./layoutSlice";
import { navigateToLocalErrorPage } from "../utils/networkHelpers";

const SLICE_NAME = "auth";

export const getConfigs = createAsyncThunk(`${SLICE_NAME}/getConfigs`, async () => {
    try {
        const res = await fetch(CONFIGS_LINK);
        const data = await res.json();
        return data;
    } catch (error) {
        return false;
    }
});

export const getCountriesData = createAsyncThunk(
    `${SLICE_NAME}/getCountriesData`,
    async (_, { getState }) => {
        try {
            const state = getState();
            const apiLink = state.auth?.configs.COUNTRY_API_LINK;
            const res = await fetch(apiLink);
            const data = await res.json();
            return data;
        } catch (error) {
            navigateToLocalErrorPage();
            console.error(error);
        }
    },
);

export const checkToken = createAsyncThunk(`${SLICE_NAME}/checkToken`, async (oauthDetails) => {
    const token = getAuthToken();
    const axiosInstance1 = axios.create({
        headers: {
            "content-type": "text/plain",
        },
    });

    axiosInstance1.interceptors.request.use(
        (config) => {
            const getToken = getAuthToken();

            if (getToken && getToken !== "null") {
                config.headers["authorization"] = `Bearer ${getToken}`;
            }
            return config;
        },

        (error) => {
            return Promise.reject(error);
        },
    );

    axiosInstance1.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error && error.response) {
                const errResp = error.response;

                if (errResp) {
                    setTimeout(() => {
                        if (error.response && error.response.status === 511) {
                            if (error.response.data === "") {
                                removeAuthToken();
                                removeIdToken();
                            }
                        }
                    }, 200);
                }
            }
            return Promise.reject(error);
        },
    );

    const responseMe = await axiosInstance1.post(oauthDetails?.dataUri, {
        token: token,
        type: "oauth",
    });

    if (responseMe.data?.token) storeAuthToken(responseMe.data.token);
    return responseMe.data;
});

export const checkCode = createAsyncThunk(
    `${SLICE_NAME}/checkCode`,
    async ({ code, state, oauthDetails }) => {
        try {
            const { link = null } = decodeString(state);
            const { tokenEndpoint, clientId } = oauthDetails;
            const codeVerifier = getCodeVerifier();

            const dataToSend = {
                code,
                grant_type: "authorization_code",
                client_id: clientId,
                redirect_uri: getBaseUrl().base,
                code_verifier: codeVerifier,
            };
            const formData = new FormData();
            Object.keys(dataToSend).forEach((k) => formData.append(k, dataToSend[k]));
            const responseToken = await axios.post(tokenEndpoint, formData);
            const { access_token, id_token } = responseToken.data;
            removeCodeVerifier();

            storeAuthToken(access_token);
            storeIdToken(id_token);

            const responseMe = await axios.post(oauthDetails?.dataUri, {
                token: access_token,
                type: "oauth",
            });
            return { ...responseMe.data, idToken: id_token, link };
        } catch (error) {
            console.error(error);
        }
    },
);

export const setLanguageInfo = createAsyncThunk(
    `${SLICE_NAME}/setLanguageInfo`,
    async ({ code, direction, sessionOnly }, { dispatch }) => {
        try {
            document.documentElement.lang = code;
            document.documentElement.dir = direction;
            if (sessionOnly) dispatch(fetchStaticTranslateData(code));

            return { code, direction, sessionOnly };
        } catch (err) {
            console.log("Error setting langauge info", err);
        }
    },
);

export const getCountryLanguageDataFromLocalStorageOrIp = createAsyncThunk(
    `${SLICE_NAME}/getCountryLanguageDataFromLocalStorageOrIp`,
    async (_, { getState, dispatch }) => {
        try {
            // check if country and language data is set in local storage
            // if so, return
            // if not, fetch from ip and set session data and local storage data
            const state = getState();
            const countriesLanguageData = state[SLICE_NAME].countriesLanguageData;
            const countryFromIpLink = state.auth.configs?.COUNTRY_FROM_IP_LOCATOR_LINK;

            const {
                countryCode: countryCodeFromLS,
                languageCode: languageCodeFromLS,
                regionId: regionIdFromLS,
            } = getCountryLanguageInfoLocalStorage();

            if (!!countryCodeFromLS && !!languageCodeFromLS) {
                const { countryCode, languageCode, languageDirection, regionId } =
                    getValidCountryLanguageCode(
                        countriesLanguageData,
                        countryCodeFromLS,
                        languageCodeFromLS,
                        regionIdFromLS,
                    );

                await dispatch(setCountryInfo({ code: countryCode }));
                await dispatch(
                    setLanguageInfo({
                        code: languageCode,
                        direction: languageDirection,
                    }),
                );
                await dispatch(setRegionInfo({ id: regionId }));
                dispatch(fetchStaticTranslateData(languageCode));

                return {
                    countryCode,
                    languageCode,
                    languageDirection,
                    regionId,
                    countriesLanguageData,
                };
            }

            const res = await fetch(countryFromIpLink);
            const data = await res.json();

            const { countryCode, languageCode, languageDirection } = getValidCountryLanguageCode(
                countriesLanguageData,
                data.success ? data.country_code : null,
            );

            await dispatch(
                setCountryInfo({
                    code: countryCode,
                }),
            );
            await dispatch(
                setLanguageInfo({
                    code: languageCode,
                    direction: languageDirection,
                }),
            );
            dispatch(fetchStaticTranslateData(languageCode));
            return {
                countryCode,
                languageCode,
                languageDirection,
                regionId: null,
                countriesLanguageData,
            };
        } catch (err) {
            console.log("Error getting country data", err);
        }
    },
);

const initialState = {
    configs: {},
    jurisdiction: [],
    userInfo: {},
    isLoading: false,
    hasError: false,
    redirectLink: null,
    showModal: false,
    openDropdown: false,
    countryCode: null,
    languageCode: null,
    languageDirection: null,
    regionId: null,
    openSearchModal: false,
    countriesLanguageData: [],
    allCountries: [],
    allLanguages: [],
    jurisdictionName: null,
    oauthData: {},
    messageAPIFromAPI: "",
    fontFamily: fonts["en"],
    isFetchingCountryLanguage: false,
    articleLanguageCode: null,
    isLogin: false,
};

export const slice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        clearAuthState: (state) => {
            removeAuthToken();
            removeIdToken();
            window.localStorage.removeItem("oauthServersDetails");
            state.userInfo = {};
        },
        clearRedirectLink: (state) => {
            state.redirectLink = null;
        },
        setShowModal: (state, action) => {
            state.showModal = action.payload;
        },
        setOpenDropdown: (state, action) => {
            state.openDropdown = action.payload;
        },
        setOpenSearchModal: (state, action) => {
            state.openSearchModal = action.payload;
        },
        setMessagesAPI(state, action) {
            state.messageAPIFromAPI = action.payload;
        },
        setCountryInfo(state, action) {
            // if sessionOnly is true, then update the redux store
            // otherwise, update the local storage. In this case, you MUST reload the page manually
            if (!action.payload.sessionOnly) {
                setCountryInfoLocalStorage({
                    code: action.payload.code,
                });
                setLanguageInfoLocalStorage({
                    code: null,
                    direction: null,
                });
                setRegionInfoLocalStorage({ id: null });
            } else {
                state.countryCode = action.payload.code;
                state.languageCode = null;
                state.languageDirection = null;
                state.regionId = null;
            }
        },
        setRegionInfo(state, action) {
            // if sessionOnly is true, then update the redux store
            // otherwise, update the local storage. In this case, you MUST reload the page manually

            if (!action.payload.sessionOnly) {
                setRegionInfoLocalStorage({ id: action.payload.id });
            } else {
                state.regionId = action.payload.id;
            }
        },
        updateFontFamily(state, action) {
            state.fontFamily = fonts[action.payload] || fonts["en"];
        },
        setArticleLanguage(state, action) {
            state.articleLanguageCode = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getConfigs.fulfilled, (state, action) => {
                state.configs = action.payload;
            })
            .addCase(getCountriesData.fulfilled, (state, action) => {
                // adds countries to state from axios api call
                state.countriesLanguageData = action.payload;
            })
            .addCase(checkCode.fulfilled, (state, action) => {
                const { link, ...rest } = action.payload;
                state.redirectLink = link || "/"; // default redirect is to /
                state.userInfo = rest;

                state.isLoading = false;
                state.hasError = false;
            })
            .addCase(checkToken.fulfilled, (state, action) => {
                state.userInfo = action.payload;
            })
            .addCase(setLanguageInfo.fulfilled, (state, action) => {
                // if sessionOnly is true, then update the redux store
                // otherwise, update the local storage. In this case, you MUST reload the page manually

                if (!action.payload.sessionOnly) {
                    setLanguageInfoLocalStorage({
                        code: action.payload.code,
                        direction: action.payload.direction,
                    });
                } else {
                    state.languageCode = action.payload.code;
                    state.languageDirection = action.payload.direction;
                }
            })
            .addCase(getCountryLanguageDataFromLocalStorageOrIp.pending, (state) => {
                state.isFetchingCountryLanguage = true;
            })
            .addCase(getCountryLanguageDataFromLocalStorageOrIp.fulfilled, (state, action) => {
                const {
                    countryCode,
                    languageCode,
                    languageDirection,
                    regionId,
                    countriesLanguageData,
                } = action.payload;

                const selCountry =
                    countriesLanguageData.find(
                        (d) => d.country_code?.toLowerCase() === countryCode,
                    ) || null;

                state.countryCode = countryCode;
                state.languageCode = languageCode;
                state.languageDirection = languageDirection;
                state.regionId = regionId;
                state.isFetchingCountryLanguage = false;
                state.isLogin = selCountry?.login === 1;
                state.oauthData = selCountry.oauthServers;
                state.jurisdictionName = selCountry.jurisdiction;
            })
            .addCase(getCountryLanguageDataFromLocalStorageOrIp.rejected, (state) => {
                state.isFetchingCountryLanguage = false;
            })
            .addMatcher(
                (action) => action.type.startsWith(SLICE_NAME) && action.type.endsWith("fulfilled"),
                (state) => {
                    state.isLoading = false;
                    state.hasError = false;
                },
            )
            .addMatcher(
                (action) => action.type.startsWith(SLICE_NAME) && action.type.endsWith("pending"),
                (state) => {
                    state.isLoading = true;
                    state.hasError = false;
                },
            )
            .addMatcher(
                (action) => action.type.startsWith(SLICE_NAME) && action.type.endsWith("rejected"),
                (state) => {
                    state.isLoading = false;
                    state.hasError = true;
                },
            );
    },
});

// countries from state
export const selectCountriesLanguageArray = (state) =>
    state[SLICE_NAME].countriesLanguageData || [];

export const jurisdictionList = (state) => state[SLICE_NAME].jurisdiction;

export const selectUserInfo = (state) => state[SLICE_NAME].userInfo;
export const selectIsAuthed = (state) => !!state[SLICE_NAME].userInfo?.token;
export const selectRedirectLink = (state) => state[SLICE_NAME].redirectLink;

export const selectLoadingState = (state) => state[SLICE_NAME].isLoading;
export const selectErrorState = (state) => state[SLICE_NAME].hasError;
export const selectShowModal = (state) => state[SLICE_NAME].showModal;
export const openLoginDropdown = (state) => state[SLICE_NAME].openDropdown;
export const openSearchModal = (state) => state[SLICE_NAME].openSearchModal;
export const oauthData = (state) => state[SLICE_NAME].oauthData;
export const jurisdictionName = (state) => state[SLICE_NAME].jurisdictionName;
export const messageAPIFromAPI = (state) => state[SLICE_NAME].messageAPIFromAPI;
export const selectCountryLanguageCodes = (state) => ({
    countryCode: (state[SLICE_NAME].countryCode || "").toLowerCase(),
    languageCode: (state[SLICE_NAME].languageCode || "").toLowerCase(),
    regionId: (state[SLICE_NAME] || "").regionId,
});
export const selectLanguageNameFromCode = (state, languageCode) => {
    const code = languageCode || state[SLICE_NAME].languageCode;
    const countryData = (state[SLICE_NAME].countriesLanguageData || []).find(
        (d) =>
            d.country_code?.toLowerCase() === (state[SLICE_NAME].countryCode || "").toLowerCase(),
    );
    if (!countryData) return null;
    return (countryData?.language || []).find((l) => l.code === code)?.label || "";
};
export const selectIsLogin = (state) => state[SLICE_NAME].isLogin;

export const {
    clearAuthState,
    clearRedirectLink,
    setShowModal,
    setOpenDropdown,
    setOpenSearchModal,
    setMessagesAPI,
    setCountryInfo,
    setRegionInfo,
    updateFontFamily,
    setArticleLanguage,
} = slice.actions;
export default slice.reducer;
