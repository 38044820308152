import React, { useCallback, useEffect, useState } from "react";
import "./FullWidthCarousel.css";
import { useSelector } from "react-redux";
import FullWidthCard from "../Card/FullWidthCard";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import useCustomTranslation from "../../utils/useCustomTranslation";
import * as posterImage from "../../utils/usePosterImage";

function FullWidthCarousel({ contents: contentToRender = [], colors: colorObj }) {
    const [contentReferenceData, setContentReferenceData] = useState([]);

    const content = useSelector((state) => state.layout.content);

    const { direction } = useCustomTranslation();

    const setCurrentContent = useCallback(
        async (contentKey) => {
            if (contentKey && content[contentKey]) {
                const respKeys = content[contentKey];
                let resp = respKeys.map((k) => content[k]);
                resp = JSON.parse(JSON.stringify(resp));
                for await (const element of resp) {
                    if (element && element.link) {
                        element.posterImage = await posterImage.usePosterImage(
                            element?.link,
                            element?.image,
                        );
                    }
                }
                if (resp.length) {
                    setContentReferenceData(resp);
                }
            }
        },
        [content],
    );

    useEffect(() => {
        if (contentToRender.length > 0) {
            setCurrentContent(contentToRender[0].contentKey);
        }
    }, [contentToRender]);

    const settingsSwiper = {
        spaceBetween: 0,
        slidesPerView: 1,
        modules: [Pagination, Navigation, Autoplay],
        dir: direction,
        key: direction,
        lazy: {
            enabled: true,
            preloaderClass: "swiper-lazy-preloader",
        },
        pagination: {
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 7,
            renderBullet: function (index, className) {
                return (
                    '<span class="' + className + '">' + "</em>" + "<i></i>" + "<b></b>" + "</span>"
                );
            },
        },
        autoplay: {
            delay: 5000,
        },
        breakpoints: {
            345: {
                pagination: {
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 7,
                    renderBullet: function (index, className) {
                        return (
                            '<span class="' +
                            className +
                            '">' +
                            "</em>" +
                            "<i></i>" +
                            "<b></b>" +
                            "</span>"
                        );
                    },
                },
            },
            576: {
                pagination: {
                    clickable: true,
                    dynamicBullets: true,
                    dynamicMainBullets: 7,
                    renderBullet: function (index, className) {
                        return (
                            '<span class="' +
                            className +
                            '">' +
                            "</em>" +
                            "<i></i>" +
                            "<b></b>" +
                            "</span>"
                        );
                    },
                },
            },
        },
    };

    return (
        <>
            <div className="TopSliderMain">
                <Swiper
                    {...settingsSwiper}
                    style={{
                        "--accent_color4": colorObj?.accent_color4,
                        "--accent_color3": colorObj?.accent_color3,
                    }}
                >
                    {contentReferenceData.map((card, i) => {
                        return (
                            <SwiperSlide key={i}>
                                <FullWidthCard content={card} colorsObj={colorObj} />
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
        </>
    );
}

export default FullWidthCarousel;
