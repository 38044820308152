import React, { useCallback, useEffect, useState } from "react";
import "./TagCarousel2.css";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { swiperCarousel } from "../../utils/carouselFunction";
import useCustomTranslation from "../../utils/useCustomTranslation";
import * as posterImage from "../../utils/usePosterImage";
import TagCard2 from "../Card/TagCard2";
import { useGetLink } from "../../utils/useOpenLink";
import CustomLink from "../../utils/customLink";

function TagCarousel2({
    contents: contentToRender = [],
    background,
    heading,
    colors: colorObj,
    block_buttons,
}) {
    const [contentReferenceData, setContentReferenceData] = useState([]);
    const [data, setData] = useState([]);
    const [tagList, setTagList] = useState([]);
    const content = useSelector((state) => state.layout.content);
    const [selectedTagIndex, setSelectedTagIndex] = useState(0);
    const { dir } = useCustomTranslation();
    const { getLink } = useGetLink();

    const setCurrentContent = useCallback(
        async (contentKey) => {
            const respKeys = content[contentKey];
            let resp = respKeys.map((k) => content[k]);
            resp = JSON.parse(JSON.stringify(resp));

            const uniqueResp = Array.from(new Map(resp.map((item) => [item.nid, item])).values());

            for (const element of uniqueResp) {
                if (element && element.link) {
                    element.posterImage = await posterImage.usePosterImage(
                        element?.link,
                        element?.image,
                    );
                }
            }
            if (uniqueResp.length) {
                setData(uniqueResp);
            }
        },
        [content],
    );

    useEffect(() => {
        if (contentToRender[0]?.contentKey) {
            setCurrentContent(contentToRender[0].contentKey);
        }
    }, [contentToRender, setCurrentContent]);

    useEffect(() => {
        const tags = new Set();
        data.forEach((item) => {
            if (item.tags) {
                item.tags.split(",").forEach((tag) => tags.add(tag.trim()));
            }
        });

        const tagsArray = Array.from(tags);
        setTagList([
            { tagName: "All", isSelected: true },
            ...tagsArray.map((tag) => ({ tagName: tag, isSelected: false })),
        ]);
    }, [data]);

    useEffect(() => {
        if (tagList[selectedTagIndex]?.tagName === "All") {
            setContentReferenceData(data);
        } else {
            const filteredData = data.filter(
                (item) =>
                    item.tags &&
                    item.tags
                        .split(",")
                        .map((tag) => tag.trim())
                        .includes(tagList[selectedTagIndex]?.tagName),
            );
            setContentReferenceData(filteredData);
        }
    }, [selectedTagIndex, data, tagList]);

    const activeTags = (i) => {
        setTagList((prevTags) =>
            prevTags.map((tag, index) => ({
                ...tag,
                isSelected: index === i,
            })),
        );
        setSelectedTagIndex(i);
    };

    return (
        <div className="programmesWrapper py-50" style={{ background: background }}>
            <div className="container">
                <div className="row">
                    <div className="col-12 mb-4 flex">
                        {heading && (
                            <div
                                className="titleMain"
                                style={{ "--primary_color": colorObj?.primary_color }}
                            >
                                <h3>{heading}</h3>
                            </div>
                        )}
                        {block_buttons?.length > 0 &&
                            block_buttons.map((v) => (
                                <CustomLink to={getLink(v.action)} key={v.label}>
                                    <div
                                        className="viewAllBtn cursor-pointer"
                                        style={{ "--primary_color": colorObj?.primary_color }}
                                        key={v.label}
                                    >
                                        {v.label}
                                    </div>
                                </CustomLink>
                            ))}
                    </div>
                    <div className="col-12">
                        <div
                            className="programmesTagWrapper"
                            style={{
                                "--primary_color": colorObj?.primary_color,
                                "--accent_color4": colorObj?.accent_color4,
                                "--accent_color1": colorObj?.accent_color1,
                            }}
                        >
                            <ul className="overflow-x-auto scrollbarStyles hidescrollbar">
                                {tagList.map((value, index) => (
                                    <li
                                        key={value.tagName}
                                        className={value.isSelected ? "active" : ""}
                                        onClick={() => activeTags(index)}
                                    >
                                        {value.tagName}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 sm:p-0 lg:p-2 md:p-2">
                        <Swiper
                            dir={dir}
                            key={dir}
                            {...swiperCarousel}
                            style={{ "--primary_color": colorObj?.primary_color }}
                        >
                            {contentReferenceData.map((card, index) => (
                                <SwiperSlide key={index}>
                                    <TagCard2 content={card} colorObj={colorObj} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TagCarousel2;
