import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { openSearchModal, setOpenSearchModal } from "../../redux/authSlice";
import { Dialog } from "primereact/dialog";
import { Configure, Hits, InstantSearch } from "react-instantsearch";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { FACET_PARAMS, QUERY_PARAMS, useGetServerConfig } from "../../utils/instantSearchConfig";
import CustomSearchBoxModal from "./InstantCustomSearchBoxModal";
import InstantSearchModalArticleDetails from "./InstantSearchModalArticleDetails";
import { useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGetLink } from "../../utils/useOpenLink";
import useCustomTranslation from "../../utils/useCustomTranslation";

const SearchDialog = ({ colors }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const serverConfig = useGetServerConfig();

    const typesenseAdapter = serverConfig?.apiKey
        ? useMemo(
              () =>
                  new TypesenseInstantSearchAdapter({
                      server: serverConfig,
                      additionalSearchParameters: {
                          query_by: QUERY_PARAMS.join(","),
                          facet_by: FACET_PARAMS.join(","),
                      },
                  }),
              [],
          )
        : null;

    const { getLink } = useGetLink();

    const { t } = useCustomTranslation();

    const isSearchModalOpen = useSelector(openSearchModal);

    const [query, setQuery] = useState("");

    const [selectedArticle, setSelectedArticle] = useState(null);

    const redirectToInstantSearchPage = () => {
        document.body.classList.remove("p-overflow-hidden");
        dispatch(setOpenSearchModal(false));
        navigate(getLink(`search?keyword=${query}`));
    };

    const handleNavigation = () => {
        document.body.classList.remove("p-overflow-hidden");
        dispatch(setOpenSearchModal(false));
        window.scrollTo(0, 0);
    };

    if (!serverConfig.apiKey) return null;

    return (
        <Dialog
            visible={isSearchModalOpen}
            draggable={false}
            className="search-dialog"
            maximized={true}
            showHeader={false}
            onHide={() => dispatch(setOpenSearchModal(false))}
        >
            <div
                className="modal-header cursor-pointer mt-4 header2-close-icon"
                onClick={() => {
                    if (!isSearchModalOpen) return;
                    dispatch(setOpenSearchModal(false));
                }}
                style={{ "--primary_color": colors?.primary_color }}
            >
                <div
                    className="closeIconMain ml-auto"
                    onClick={() => dispatch(setOpenSearchModal(false))}
                ></div>
            </div>
            <div className="container mt-12" style={{ "--primary_color": colors?.primary_color }}>
                <InstantSearch
                    indexName="app_ismaili_articles"
                    searchClient={typesenseAdapter.searchClient}
                >
                    <Configure hitsPerPage={10} />

                    <CustomSearchBoxModal
                        initialQuery={query}
                        onQueryChange={setQuery}
                        onMoreResults={redirectToInstantSearchPage}
                        closeModal={() => dispatch(setOpenSearchModal(false))}
                        colorObj={colors}
                        isSearchModalOpen={isSearchModalOpen}
                    />
                    {query && (
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-12 col-12">
                                <Hits
                                    hitComponent={(hitProps) => (
                                        <InstantSearchModalArticleDetails
                                            {...hitProps}
                                            setSelectedArticle={setSelectedArticle}
                                            onClickLink={handleNavigation}
                                            colorObj={colors}
                                        />
                                    )}
                                />
                                <div
                                    className="mt-4 md:mt-0 cursor-pointer showAll"
                                    onClick={() => redirectToInstantSearchPage()}
                                >
                                    {t("btnText.ShowAll")}
                                    <i className="pi pi-arrow-right ml-2"></i>
                                </div>
                            </div>
                            {selectedArticle && (
                                <div className="hidden md:block col-lg-4 col-md-4 col-sm-12 col-12">
                                    <div className="newsCard card">
                                        <div className="cardImg">
                                            <img
                                                className="cursor-pointer"
                                                src={selectedArticle.image?.src}
                                                alt="placeholder"
                                            />
                                        </div>
                                        <div className="card-body flex flex-col text-start">
                                            <Link
                                                to={getLink(
                                                    selectedArticle.path,
                                                    selectedArticle.type,
                                                    selectedArticle.language,
                                                )}
                                            >
                                                <h3
                                                    className="hover:underline cursor-pointer"
                                                    onClick={() => handleNavigation()}
                                                >
                                                    {selectedArticle.title}
                                                </h3>
                                            </Link>
                                            <div className="author mr-1">
                                                {selectedArticle.tags}
                                            </div>
                                            <div className="summary">
                                                {parse(selectedArticle.summary)}
                                            </div>
                                            <Link
                                                to={getLink(
                                                    selectedArticle.path,
                                                    selectedArticle.type,
                                                    selectedArticle.language,
                                                )}
                                            >
                                                <i
                                                    className="pi pi-arrow-right arrow-icon"
                                                    aria-hidden="true"
                                                    onClick={() => handleNavigation()}
                                                ></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </InstantSearch>
            </div>
        </Dialog>
    );
};

export default SearchDialog;
