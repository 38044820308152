import React, { useContext } from "react";
import { useGetLink } from "../../utils/useOpenLink";
import { Link } from "react-router-dom";
import { WindowContext } from "../WindowResize/resize";

function FullWidthCard({ content, colorObj }) {
    const { image, title, path, posterImage, type, language } = content;

    const { getLink } = useGetLink();

    const { isMobile } = useContext(WindowContext);

    return (
        <>
            {!isMobile ? (
                <div
                    className="sliderBox"
                    style={{
                        background: `url(${image?.src || posterImage})`,
                    }}
                >
                    <div className="container h-screen relative">
                        <Link to={getLink(path, type, language)}>
                            <div className="textMain">
                                <h3
                                    className="hover:underline cursor-pointer"
                                    style={{ color: colorObj?.accent_color4 }}
                                >
                                    {title}
                                </h3>
                            </div>
                        </Link>
                    </div>
                </div>
            ) : (
                <>
                    <Link to={getLink(path)}>
                        <div
                            className="sliderBox"
                            style={{
                                background: `url(${image?.src || posterImage})`,
                            }}
                        />
                    </Link>
                    <div className="container relative titleHldr">
                        <div className="textMain">
                            <h3
                                className="hover:underline cursor-pointer"
                                style={{ color: colorObj?.accent_color4 }}
                            >
                                {title}
                            </h3>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default FullWidthCard;
