import React from "react";

const GlobalIconSvg = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M8 1.75C6.76387 1.75 5.5555 2.11656 4.52769 2.80331C3.49988 3.49007 2.6988 4.46619 2.22576 5.60823C1.75271 6.75027 1.62894 8.00693 1.87009 9.21931C2.11125 10.4317 2.70651 11.5453 3.58059 12.4194C4.45466 13.2935 5.56831 13.8887 6.78069 14.1299C7.99307 14.3711 9.24974 14.2473 10.3918 13.7742C11.5338 13.3012 12.5099 12.5001 13.1967 11.4723C13.8834 10.4445 14.25 9.23613 14.25 8C14.2482 6.34296 13.5891 4.7543 12.4174 3.58259C11.2457 2.41088 9.65705 1.75182 8 1.75ZM8 13.6631C7.60438 13.2825 6.5625 12.1375 6.04563 10.25H9.95438C9.4375 12.1375 8.39563 13.2825 8 13.6631ZM5.92625 9.75C5.69122 8.59517 5.69122 7.40483 5.92625 6.25H10.0738C10.3088 7.40483 10.3088 8.59517 10.0738 9.75H5.92625ZM8 2.33687C8.39563 2.7175 9.4375 3.8625 9.95438 5.75H6.04563C6.5625 3.8625 7.60438 2.7175 8 2.33687ZM10.5881 6.25H13.4775C13.8408 7.38839 13.8408 8.61161 13.4775 9.75H10.5881C10.8039 8.59331 10.8039 7.40669 10.5881 6.25ZM13.2913 5.75H10.4788C10.1633 4.46388 9.53681 3.2749 8.65438 2.2875C9.66386 2.40478 10.6244 2.78681 11.4387 3.39486C12.253 4.00292 12.8921 4.8154 13.2913 5.75ZM7.34625 2.2875C6.46382 3.2749 5.83736 4.46388 5.52188 5.75H2.70938C3.10854 4.8154 3.74765 4.00292 4.56194 3.39486C5.37623 2.78681 6.33677 2.40478 7.34625 2.2875ZM2.5225 6.25H5.41188C5.19612 7.40669 5.19612 8.59331 5.41188 9.75H2.5225C2.15922 8.61161 2.15922 7.38839 2.5225 6.25ZM2.71 10.25H5.5225C5.83798 11.5361 6.46445 12.7251 7.34688 13.7125C6.33718 13.5954 5.37637 13.2135 4.56185 12.6054C3.74732 11.9974 3.10802 11.1848 2.70875 10.25H2.71ZM8.655 13.7125C9.53698 12.725 10.163 11.536 10.4781 10.25H13.2906C12.8915 11.1846 12.2524 11.9971 11.4381 12.6051C10.6238 13.2132 9.66323 13.5952 8.65375 13.7125H8.655Z"
                fill="#757575"
            />
        </svg>
    );
};

export default GlobalIconSvg;
